
<button
  data-interactive="true"
  :type="type"
  ref="buttonRef"
  class="button-wrapper"
  :class="{
    'size-xs': size === 'xs',
    'size-s': size === 's',
    'size-m': size === 'm',
    'variant-primary': variant === 'primary',
    'variant-secondary': variant === 'secondary',
    'variant-tertiary': variant === 'tertiary',
    'only-icon': icon && !label && !iconRight,
    'left-icon': icon,
    'right-icon': iconRight,
    'focus-enabled': !whiteLabel,
    'no-padding': noPadding,
    variableWidth: variableWidth,
    disabled: disabled,
    active: active,
    block: block
  }"
  @click.prevent="e => handleClick(e)"
  :disabled="disabled"
>
  <div v-if="loading" class="loading-container">
    <n-loading :dark="variant !== 'primary'" />
  </div>
  <div :class="['button-body', { loading }]">
    <n-icon v-if="icon" :size="iconSize" :icon="icon" :class="{ 'margin-right': !!label }" />
    <slot name="icon"></slot>
    <span v-if="label" class="span-wrapper" :class="{ 'margin-right': !!iconRight, 'icon-synergy': icon }">
      {{ label }}
    </span>
    <n-icon v-if="iconRight" :size="iconSize" :icon="iconRight" />
  </div>
</button>
