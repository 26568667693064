
<div
  v-if="isSubMenu"
  class="menu-wrapper"
  :class="{
    'auto-width': autoWidth,
    'size-l': size === 'l',
    'size-xl': size === 'xl',
    'size-xxl': size === 'xxl',
    'size-3xl': size === '3xl'
  }"
  ref="menu"
  v-on="$listeners"
  @click="onClick"
>
  <slot />
</div>
<div
  v-else
  class="menu-wrapper"
  :class="{
    'auto-width': autoWidth,
    'size-l': size === 'l',
    'size-xl': size === 'xl',
    'size-xxl': size === 'xxl',
    'size-3xl': size === '3xl'
  }"
  ref="menu"
  v-on="$listeners"
  @click="onClick"
  v-non-render
>
  <slot />
</div>
