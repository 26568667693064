import { mergeAttributes } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'

export const CustomMention = Mention.extend({
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
      `@${node.attrs.name}`
    ]
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => {
          return {
            tag: `span[data-type="${this.name}"]`,
            id: element.getAttribute('data-mention')
          }
        },
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-mention': attributes.id
          }
        }
      },
      name: {
        default: null
      }
    }
  }
})
