
<div
  class="select-container"
  @keydown.esc="closeMenu"
  @keydown.down="activeElement = Math.min(activeElement + 1, options.length - 1)"
  @keydown.up="activeElement = Math.max(activeElement - 1, 0)"
  @keydown.enter="onSelect(activeElement)"
  tabindex="0"
>
  <n-input
    class="select-input"
    v-bind="inputProps"
    :size="size"
    @click="toggleDropdown(true)"
    iconRight="chevron-down"
    nonInteractive
    ref="input"
  />
  <n-menu class="dropdown" ref="dropdown" :tippyProps="menuProps">
    <n-list-item
      v-for="(option, index) in options"
      :key="index"
      :active="activeElement === index"
      @mousedown="onSelect(index)"
      @mouseenter="activeElement = index"
    >
      {{ toLabel(option) }}
    </n-list-item>
  </n-menu>
</div>
