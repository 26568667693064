
<footer class="controls">
  <div class="left-actions">
    <slot name="left"></slot>
  </div>
  <n-page-indicator :currentPage="currentPage" :numPages="numPages" />
  <div class="right-actions">
    <slot name="right"></slot>
  </div>
</footer>
