import Element, { RootEntityType } from '@/modules/elements/models/Element'
import Resource from '@/modules/elements/models/Resource'
import { useAppStore } from '@/modules/global/stores/AppStore'
import Comment from '@/modules/messages/models/Comment'
import Space, { SpaceVerificationMode } from '@/modules/space/models/Space'
import { useSpacePageTemplateStore } from '@/modules/space/stores/SpacePageTemplateStore'
import { useSpaceStore } from '@/modules/space/stores/SpaceStore'
import Task from '@/modules/task/models/Task'
import { useTaskStore } from '@/modules/task/stores/TaskStore'
import { useTaskTemplateStore } from '@/modules/task/stores/TaskTemplateStore'
import User, { TeamRole, UserState } from '@/modules/user/models/User'
import { useUserStore } from '@/modules/user/stores/UserStore'
import { defineStore } from 'pinia'
import { Permission } from '../models/Permission'

export const usePermissionStore = defineStore('permission', {
  state: (): {} => ({}),
  actions: {
    getCurrentUser(): User {
      return useUserStore().currentUser!
    },
    getUsers(): User[] {
      return useUserStore().users
    },
    isInternal(user: User | null = null): boolean {
      if (!user) user = this.getCurrentUser()
      return Boolean(user) && user?.role !== TeamRole.EXTERNAL && user?.role !== TeamRole.ANONYMOUS
    },
    isAnonymous(user: User | null = null): boolean {
      if (!user) {
        user = this.getCurrentUser()
      }
      return user?.role === TeamRole.ANONYMOUS
    },
    isInvited(space: Space, userId: string): boolean {
      return space.recipients.map(r => r.userId).includes(userId)
    },
    isExternalUser(user: User | null = null): boolean {
      if (!user) {
        user = this.getCurrentUser()
      }
      return !this.isInternal(user)
    },
    isPreviewMode(): boolean {
      return useAppStore().isInPreviewMode
    },
    isNotInternalOrPreviewMode(): boolean {
      return !this.isInternal() || this.isPreviewMode()
    },
    checkTeamPermission(permission: Permission): boolean {
      const user = this.getCurrentUser()
      if (!user) {
        return false
      }

      switch (permission) {
        case Permission.MANAGE_GLOBAL_SPACE_PAGE_TEMPLATES:
          return user.isGlobalTemplateManger
        case Permission.UPDATE_TEAM_SETTINGS:
        case Permission.MANAGE_SUBSCRIPTIONS:
        case Permission.VIEW_SPACE_LIST_DIRECTORY_SETTINGS:
          return user.role === TeamRole.ADMIN
        case Permission.MANAGE_SPACE_LIST:
        case Permission.MANAGE_TASK_TEMPLATES:
        case Permission.MANAGE_SPACE_PAGE_TEMPLATES:
        case Permission.MANAGE_ACTION_PLANS:
        case Permission.INVITE_TEAM_MEMBERS:
        case Permission.MANAGE_TASK:
        case Permission.UPDATE_TASK_STATUS:
        case Permission.VIEW_SPACE_LIST:
        case Permission.VIEW_UPDATES:
        case Permission.VIEW_TASKS:
        case Permission.VIEW_CONTENT_LIBRARY:
        case Permission.VIEW_NAV_BAR:
          return this.isInternal(user) && !this.isPreviewMode()
        case Permission.VIEW_GLOBAL_NAV_BAR:
          return this.isInternal(user)
        case Permission.VIEW_SPACE_ACTIONS:
          return !this.isAnonymous(user)
        default:
          return false
      }
    },
    checkUserPermission(permission: Permission, user: User): boolean {
      const currentUser = this.getCurrentUser()
      const isSameUser = user.id === currentUser?.id
      const currentUserIsInternal = this.isInternal(currentUser)
      const currentUserIsAdmin = currentUser?.role === TeamRole.ADMIN
      const userIsExternal = this.isExternalUser(user)
      switch (permission) {
        case Permission.UPDATE_USERS:
          return currentUserIsAdmin || isSameUser || (currentUserIsInternal && userIsExternal)
        default:
          return false
      }
    },
    hasAtMostReadOnlyAccessToSpace(space: Space) {
      const user = this.getCurrentUser()
      if (!user || !space) {
        return true
      }
      const recipient = space.recipients.map(r => r.userId).find(m => m === user.id)
      if (!recipient && (user.role === TeamRole.EXTERNAL || user.role === TeamRole.ANONYMOUS)) {
        return true
      }
    },
    // TODO: _isInPreviewMode used to force reactivity refactor later
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkSpacePermission(permission: Permission, givenSpace?: Space | null, _isInPreviewMode?: boolean): boolean {
      const spaceStore = useSpaceStore()
      const space: Space = givenSpace || spaceStore.currentSpace!
      const user = this.getCurrentUser()

      if (!user || !space) {
        return false
      }

      const isRecipient = space?.recipients.map(r => r.userId).some(m => m === user.id)
      const publicSpace = space?.verificationMode === SpaceVerificationMode.PUBLIC
      const publicSpacePreview = publicSpace && this.isPreviewMode()

      switch (permission) {
        case Permission.SEE_SPACE:
          return isRecipient || this.isInternal() || publicSpace
        case Permission.ADD_CLIENT_RESOURCE:
        case Permission.UPDATE_TASK_STATUS:
          return isRecipient || this.isInternal() || publicSpace
        case Permission.CREATE_COMMENT:
          return isRecipient || this.isInternal()
        case Permission.MANAGE_SPACE:
        case Permission.MANAGE_ELEMENTS:
        case Permission.MANAGE_TASK:
        case Permission.MANAGE_SPACE_SETTINGS:
          return this.isInternal() && !this.isPreviewMode()
        case Permission.VIEW_SPACE_ACTIONS:
          return !this.isAnonymous(user) && !publicSpacePreview
        default:
          return false
      }
    },
    // TODO: _isInPreviewMode used to force reactivity refactor later
    checkTaskPermission(
      permission: Permission,
      task?: Task | null,
      space?: Space | null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _isInPreviewMode?: boolean
    ): boolean {
      const user = this.getCurrentUser()

      if (!task) {
        return false
      } else if (task?.createdBy === user?.id && !this.isPreviewMode()) {
        return true
      }

      switch (permission) {
        case Permission.MANAGE_TASK:
          return space
            ? this.checkSpacePermission(Permission.MANAGE_TASK, space)
            : this.checkTeamPermission(Permission.MANAGE_TASK)
        case Permission.UPDATE_TASK_STATUS:
          return space
            ? this.checkSpacePermission(Permission.UPDATE_TASK_STATUS, space)
            : this.checkTeamPermission(Permission.UPDATE_TASK_STATUS)
        case Permission.MANAGE_ELEMENTS:
          return space
            ? this.checkSpacePermission(Permission.MANAGE_ELEMENTS, space)
            : !this.isNotInternalOrPreviewMode()
        case Permission.MANAGE_TASK_SHARED_STATUS:
          return !this.isNotInternalOrPreviewMode()
        case Permission.ADD_CLIENT_RESOURCE:
          return space
            ? this.checkSpacePermission(Permission.ADD_CLIENT_RESOURCE, space)
            : this.checkTeamPermission(Permission.ADD_CLIENT_RESOURCE)
        case Permission.VIEW_SPACE_ACTIONS:
          return space
            ? this.checkSpacePermission(Permission.VIEW_SPACE_ACTIONS, space)
            : this.checkTeamPermission(Permission.VIEW_SPACE_ACTIONS)
        default:
          return false
      }
    },
    checkCommentPermission(permission: Permission, comment?: Comment, givenSpace?: Space): boolean {
      const user = this.getCurrentUser()
      const space: Space = givenSpace || useSpaceStore().currentSpace!
      if (!user) {
        return false
      }

      const canCreateComments = this.checkSpacePermission(Permission.CREATE_COMMENT, space!!)

      switch (permission) {
        case Permission.CREATE_COMMENT:
          return canCreateComments
        case Permission.UPDATE_COMMENT:
          return canCreateComments && comment?.createdBy === user.id
        case Permission.DELETE_COMMENT:
          return canCreateComments && (comment?.createdBy === user.id || !this.isNotInternalOrPreviewMode())
        case Permission.RESOLVE_COMMENT:
          return canCreateComments && !this.isNotInternalOrPreviewMode()
        default:
          return false
      }
    },
    // TODO: _isInPreviewMode and elements used to force reactivity refactor later
    checkPermissionForElement(
      element: Element,
      allElements: Element[],
      permission: Permission,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _isInPreviewMode?: boolean
    ): boolean {
      const spaceStore = useSpaceStore()

      switch (element.rootEntityType) {
        case RootEntityType.SPACE_PAGE: {
          const elementsByPageId = allElements.filter(e => e.rootEntityId === element.rootEntityId)
          const signatureList = elementsByPageId.find(e => e.type === 'signature')
          const wasSigned = signatureList?.signatureList?.signatures?.some(s => s.signedAt) || false
          if (wasSigned) {
            return false
          }
          const page = spaceStore.spacePageByPageId(element.rootEntityId!!)
          const space = spaceStore.spaces.find(s => s.id === page?.spaceId)
          return this.checkSpacePermission(permission, space)
        }
        case RootEntityType.TASK: {
          const task = useTaskStore().tasks.find(t => t.id === element.rootEntityId!!)
          const space = task?.spaceId ? spaceStore.activeSpaces.find((w: Space) => w.id === task?.spaceId) : undefined
          return this.checkTaskPermission(permission, task, space)
        }
        case RootEntityType.SPACE_PAGE_TEMPLATE: {
          const spacePageTemplate = useSpacePageTemplateStore().spacePageTemplatesById(element.rootEntityId!!)
          if (spacePageTemplate?.isGlobal) {
            return this.checkTeamPermission(Permission.MANAGE_GLOBAL_SPACE_PAGE_TEMPLATES)
          } else {
            return this.checkTeamPermission(Permission.MANAGE_SPACE_PAGE_TEMPLATES)
          }
        }
        case RootEntityType.TASK_TEMPLATE: {
          const taskTemplate = useTaskTemplateStore().taskTemplates.find(t => t.id === element.rootEntityId!!)
          const taskListElement = allElements.find(e => e.id === taskTemplate!!.taskListId)
          if (taskListElement) {
            const spacePageTemplate = useSpacePageTemplateStore().spacePageTemplatesById(
              taskListElement!!.rootEntityId!!
            )
            if (spacePageTemplate?.isGlobal) {
              return this.checkTeamPermission(Permission.MANAGE_GLOBAL_SPACE_PAGE_TEMPLATES)
            } else {
              return this.checkTeamPermission(Permission.MANAGE_TASK_TEMPLATES)
            }
          } else {
            return false
          }
        }
        default:
          return false
      }
    },
    checkResourcePermission(
      permission: Permission,
      element: Element,
      allElements: Element[],
      resource?: Resource
    ): boolean {
      switch (permission) {
        case Permission.MANAGE_ELEMENTS:
          return this.checkPermissionForElement(element, allElements, Permission.MANAGE_ELEMENTS)
        case Permission.ADD_CLIENT_RESOURCE:
          return this.checkPermissionForElement(element, allElements, Permission.ADD_CLIENT_RESOURCE)
        case Permission.DELETE_CLIENT_RESOURCE:
          return (
            resource?.createdBy === useUserStore().currentUser!.id ||
            this.checkPermissionForElement(element, allElements, Permission.MANAGE_ELEMENTS)
          )
        default:
          return false
      }
    },
    canChangeRole(user: User): boolean {
      if (!this.checkTeamPermission(Permission.UPDATE_TEAM_SETTINGS)) return false

      const isLastActiveAdmin =
        user.role === TeamRole.ADMIN &&
        !this.getUsers().find(u => u.role === TeamRole.ADMIN && u.state === UserState.ACTIVE && u.id !== user.id)

      return !isLastActiveAdmin
    },
    canRemoveUser(user: User): boolean {
      if (!this.checkTeamPermission(Permission.UPDATE_TEAM_SETTINGS)) return false

      return user.id !== this.getCurrentUser()!.id || user.state === UserState.PENDING_INVITATION
    }
  }
})

export type PermissionStore = ReturnType<typeof usePermissionStore>
