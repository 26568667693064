import NAutocomplete from '@/modules/global/components/core/NAutocomplete.vue'
import NBanner from '@/modules/global/components/core/NBanner.vue'
import NButton from '@/modules/global/components/core/NButton.vue'
import NCheckbox from '@/modules/global/components/core/NCheckbox.vue'
import NChip from '@/modules/global/components/core/NChip.vue'
import NDivider from '@/modules/global/components/core/NDivider.vue'
import NEditorButton from '@/modules/global/components/core/NEditorButton.vue'
import NFilterbar from '@/modules/global/components/core/NFilterbar.vue'
import NIcon from '@/modules/global/components/core/NIcon.vue'
import NInput from '@/modules/global/components/core/NInput.vue'
import NLoading from '@/modules/global/components/core/NLoading.vue'
import NListItem from '@/modules/global/components/core/NMenu/NListItem.vue'
import NMenu from '@/modules/global/components/core/NMenu/NMenu.vue'
import NMenuGroup from '@/modules/global/components/core/NMenu/NMenuGroup.vue'
import NModalBackground from '@/modules/global/components/core/NModal/NModalBackground.vue'
import NModalBody from '@/modules/global/components/core/NModal/NModalBody.vue'
import NModalFooter from '@/modules/global/components/core/NModal/NModalFooter.vue'
import NSimpleModalBody from '@/modules/global/components/core/NModal/NSimpleModalBody.vue'
import NMultilineInput from '@/modules/global/components/core/NMultilineInput.vue'
import NMultipleInput from '@/modules/global/components/core/NMultipleInput.vue'
import NNotificationBubble from '@/modules/global/components/core/NNotificationBubble.vue'
import NPageIndicator from '@/modules/global/components/core/NPageIndicator.vue'
import NProgressBar from '@/modules/global/components/core/NProgressBar.vue'
import NRadio from '@/modules/global/components/core/NRadio.vue'
import NScroller from '@/modules/global/components/core/NScroller.vue'
import NSelect from '@/modules/global/components/core/NSelect.vue'
import NSelectableCard from '@/modules/global/components/core/NSelectableCard.vue'
import NSidebar from '@/modules/global/components/core/NSidebar/NSidebar.vue'
import NSkeleton from '@/modules/global/components/core/NSkeleton.vue'
import NSlider from '@/modules/global/components/core/NSlider.vue'
import NTab from '@/modules/global/components/core/NTab.vue'
import NTextArea from '@/modules/global/components/core/NTextarea.vue'
import NToggle from '@/modules/global/components/core/NToggle.vue'
import NTooltip from '@/modules/global/components/core/NTooltip.vue'
import NViewbar from '@/modules/global/components/core/NViewbar.vue'
import NEmoji from '@/modules/global/components/core/NEmoji.vue'
import NText from '@/modules/global/components/core/NText.vue'
import initIconify from '@/util/iconify-init'
import { PiniaVuePlugin } from 'pinia'
import PortalVue from 'portal-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import checkView from 'vue-check-view'
import VueCropper from 'vue-cropperjs'

export function initComponents() {
  initIconify()

  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)

  Vue.component('n-button', NButton)
  Vue.component('n-loading', NLoading)
  Vue.component('n-input', NInput)
  Vue.component('n-multiple-input', NMultipleInput)
  Vue.component('n-multiline-input', NMultilineInput)
  Vue.component('n-autocomplete', NAutocomplete)
  Vue.component('n-chip', NChip)
  Vue.component('n-modal-body', NModalBody)
  Vue.component('n-modal-background', NModalBackground)
  Vue.component('n-simple-modal-body', NSimpleModalBody)
  Vue.component('n-modal-footer', NModalFooter)
  Vue.component('n-scroller', NScroller)
  Vue.component('n-sidebar', NSidebar)
  Vue.component('n-checkbox', NCheckbox)
  Vue.component('n-banner', NBanner)
  Vue.component('n-tooltip', NTooltip)
  Vue.component('n-toggle', NToggle)
  Vue.component('n-icon', NIcon)
  Vue.component('n-slider', NSlider)
  Vue.component('n-progress-bar', NProgressBar)
  Vue.component('n-skeleton', NSkeleton)
  Vue.component('n-select', NSelect)
  Vue.component('n-selectable-card', NSelectableCard)
  Vue.component('n-notification-bubble', NNotificationBubble)
  Vue.component('n-divider', NDivider)
  Vue.component('n-page-indicator', NPageIndicator)
  Vue.component('n-viewbar', NViewbar)
  Vue.component('n-filterbar', NFilterbar)
  Vue.component('n-editor-button', NEditorButton)
  Vue.component('n-radio', NRadio)
  Vue.component('n-menu', NMenu)
  Vue.component('n-menu-group', NMenuGroup)
  Vue.component('n-list-item', NListItem)
  Vue.component('n-text-area', NTextArea)
  Vue.component('n-tab', NTab)
  Vue.component('n-emoji', NEmoji)
  Vue.component('n-text', NText)

  Vue.use(VueCropper)
  Vue.use(checkView)
  Vue.use(PortalVue)
  Vue.use(PiniaVuePlugin)

  Vue.directive('non-render', {
    inserted: (el: HTMLElement) => {
      el.parentElement?.removeChild(el)
    }
  })
}
