import CommandsList from '@/modules/global/components/CommandLine/CommandsList.vue'
import MentionList from '@/modules/global/components/MentionList.vue'
import User from '@/modules/user/models/User'
import { CustomMention } from '@/util/tiptap-mention'
import { Range } from '@tiptap/core'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import { CustomListItem } from './tiptap-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import TaskList from '@tiptap/extension-task-list'
import Text from '@tiptap/extension-text'
import { TextAlign } from '@tiptap/extension-text-align'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import Blockquote from '@tiptap/extension-blockquote'
import Strike from '@tiptap/extension-strike'
import { SuggestionKeyDownProps } from '@tiptap/suggestion'
import { Editor, VueRenderer } from '@tiptap/vue-2'
import { Node as ProseMirrorNode, Slice } from 'prosemirror-model'
import tippy, { Instance, Props } from 'tippy.js'
import Commands, { AddElementData, availableSlashCommands } from './tiptap-commands'
import Iframe from './tiptap-iframe'
import { History } from './tiptap-history'
import { SmilieReplacer } from './tiptap-smilie-replacer'
import { CustomTaskItem } from './tiptap-task-item'
import ListKeymap from '@tiptap/extension-list-keymap'
import TiptapPasteHandler from './tiptap-paste-handler'
import { EditorView } from 'prosemirror-view'

type CreateEditorParams = {
  content?: string
  getUsers?: Function
  parent: Vue
  enableSlashCommands?: boolean
  handleKeyDown?: Function
  addElementHandler?: (...data: AddElementData[]) => void
  replaceElementHandler?: (data: AddElementData) => void
  enableIframe?: boolean
  readOnlyTaskItemChecked?: (node: ProseMirrorNode, checked: boolean) => boolean
  handlePaste?: (_view: EditorView, event: ClipboardEvent, slice: Slice) => boolean
}

export function createEditor({
  content,
  getUsers,
  parent,
  enableSlashCommands = true,
  handleKeyDown,
  addElementHandler,
  replaceElementHandler,
  enableIframe = true,
  readOnlyTaskItemChecked = () => false,
  handlePaste = undefined
}: CreateEditorParams): Editor {
  const extensions = [
    BulletList,
    OrderedList,
    CustomListItem,
    Underline,
    HardBreak,
    Text,
    Document,
    Italic,
    Paragraph.extend({
      addAttributes() {
        return {
          class: {
            default: null,
            renderHTML: attributes => {
              return {
                class: `${attributes.class}`
              }
            }
          }
        }
      }
    }),
    SmilieReplacer,
    Typography,
    Link,
    Bold,
    Heading,
    Strike,
    Blockquote,
    TaskList,
    CustomTaskItem.configure({
      onReadOnlyChecked: (node: ProseMirrorNode, checked: boolean) => {
        return readOnlyTaskItemChecked(node, checked)
      },
      nested: true
    }),

    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'right', 'center', 'justify']
    }),
    History,
    ListKeymap,
    TiptapPasteHandler.configure({
      handlePaste: handlePaste
    })
  ]

  if (getUsers) {
    const mentionExtension = CustomMention.configure({
      HTMLAttributes: {
        class: 'mention'
      },
      suggestion: {
        items: query => {
          const users = getUsers() as User[]
          return users.filter(user =>
            (user.firstName + user.lastName + '').toLowerCase().includes(query.query.toLowerCase())
          )
        },
        render: () => {
          let component: VueRenderer
          let popup: Instance[]

          return {
            onStart: props => {
              component = new VueRenderer(MentionList, {
                parent: parent,
                propsData: props
              })

              popup = tippy('body', {
                getReferenceClientRect: fallbackRect(props.clientRect as any),
                appendTo: 'parent',
                aria: {
                  content: 'auto',
                  expanded: 'auto'
                },
                showOnCreate: true,
                interactive: true,
                theme: 'none',
                trigger: 'manual',
                placement: 'bottom-start'
              })

              popup[0].setContent(component.element)
            },
            onUpdate(props) {
              component.updateProps(props)

              popup[0].setProps({
                getReferenceClientRect: fallbackRect(props.clientRect as any)
              })
            },
            onKeyDown(props) {
              return (component.ref as any).onKeyDown(props.event)
            },
            onExit() {
              popup[0].destroy()
              component.destroy()
            }
          }
        }
      }
    })

    extensions.push(mentionExtension)
  }

  if (enableSlashCommands) {
    extensions.push(
      Commands.configure({
        suggestion: {
          items: (query: { query: string; editor: Editor }) => availableSlashCommands(query, parent),
          render: () => {
            let component: VueRenderer
            let popup: Instance<Props>[]

            let range: Range
            let lastPressedKey = ''
            let lastActionUndoRedo = false

            return {
              onStart: (props: any) => {
                range = props.range
                component = new VueRenderer(CommandsList, {
                  parent: parent,
                  propsData: {
                    ...props,
                    ...{
                      exit: () => {
                        if (popup[0] && component) {
                          popup[0].destroy()
                          component.destroy()
                        }
                      },
                      selected: () => {
                        lastPressedKey = 'Enter'
                      }
                    }
                  }
                })

                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  theme: 'none',
                  trigger: 'manual',
                  placement: 'bottom-start',
                  onHide: () => {
                    if (lastPressedKey !== 'Enter' && !lastActionUndoRedo) {
                      props.editor.chain().focus().deleteRange(range).run()
                    }
                  }
                })
              },
              onUpdate(props: any) {
                range = props.range
                component.updateProps(props)

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                })
              },
              onKeyDown(props: SuggestionKeyDownProps) {
                range = props.range
                lastPressedKey = props.event.key
                if (
                  (props.event.ctrlKey || props.event.metaKey) &&
                  (props.event.key === 'z' || props.event.key === 'y')
                ) {
                  lastActionUndoRedo = true
                } else {
                  lastActionUndoRedo = false
                }
                return (component.ref as any).onKeyDown(props)
              },
              onExit() {
                popup[0].destroy()
                component.destroy()
              }
            }
          },
          char: '/',
          startOfLine: false,
          allowedPrefixes: [' '],
          command: ({ editor, range, props }: { editor: any; range: any; props: any }) => {
            props.command({
              editor,
              range,
              parent,
              addElement: addElementHandler,
              replaceElement: replaceElementHandler,
              boundsFunction: () => props.element.getBoundingClientRect()
            })
          }
        }
      })
    )
  }

  if (enableIframe) {
    extensions.push(
      Iframe.configure({
        parent: parent,
        replaceElementHandler: replaceElementHandler
      })
    )
  }

  return new Editor({
    extensions,
    editorProps: {
      handleKeyDown: (view, event) => (handleKeyDown ? handleKeyDown(view, event, extensions) : null)
    },
    content: content
  })
}

function fallbackRect(clientRect: () => DOMRect | null) {
  const dummyRect = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0
  } as ClientRect

  const newRectFunction = () => {
    const clientRectVal = clientRect()
    if (!clientRectVal) {
      return dummyRect
    } else {
      return clientRectVal
    }
  }

  return newRectFunction
}
