import Element, { RootEntityType } from '@/modules/elements/models/Element'
import { useElementStore } from '@/modules/elements/stores/ElementStore'
import { GeneralApiResponse } from '@/modules/global/models/GeneralApiResponse'
import CreateSpaceRequest from '@/modules/space/models/CreateSpaceRequest'
import DuplicateSpaceRequest from '@/modules/space/models/DuplicateSpaceRequest'
import { RemoveRecipientsRequest } from '@/modules/space/models/InviteRecipientsRequest'
import Space, { SpaceVerificationMode, UpdateSpaceRequest } from '@/modules/space/models/Space'
import SpacePage from '@/modules/space/models/SpacePage'
import Task from '@/modules/task/models/Task'
import { useTaskStore } from '@/modules/task/stores/TaskStore'
import User from '@/modules/user/models/User'
import { useUserStore } from '@/modules/user/stores/UserStore'
import ApiClient, { FILE_TIMEOUT, PDF_GENERATION_TIMEOUT, SPACE_DUPLICATION_TIMEOUT } from '@/util/ApiClient'
import { defineStore } from 'pinia'
import Vue from 'vue'
import SignaturePositionsResponse from '../models/SignaturePositionsResponse'
import UpdateSpaceBannerRequest from '../models/UpdateSpaceBannerRequest'
import { useTeamStore } from '@/modules/team/stores/TeamStore'
import { DomainValidationStatus } from '@/modules/team/models/Domain'
import { SpaceListColumnCategory } from '@/modules/spacelist/models/SpaceListColumnCategory'
import SpaceListDirectoryColumn from '@/modules/spacelist/models/SpaceListDirectoryColumn'
import EmailRecipientRequest from '@/modules/space/models/EmailRecipientRequest'
import AddRecipientRequest from '../models/AddRecipientRequest'
import VerificationScreenData from '@/modules/space/models/VerificationScreenData'
import { trackCreateSpace, trackOnboardingFlow3 } from '@/util/segment-tracking'
import i18n from '@/util/config/i18n'
import { useAuthStore } from '@/modules/auth/stores/AuthStore'

export type SpaceState = {
  spaces: Space[]
  spacePages: Record<string, SpacePage[]>
  currentSpaceId: string | null
  currentPageId: string | null
  spaceListDirectoryColumns: SpaceListDirectoryColumn[]
  signaturePositions: SignaturePositionsResponse[]
  triggerPageReload: boolean
}

export const useSpaceStore = defineStore('space', {
  state: (): SpaceState => ({
    spaces: [],
    spacePages: {},
    currentSpaceId: null,
    currentPageId: null,
    spaceListDirectoryColumns: [],
    signaturePositions: [],
    triggerPageReload: false
  }),
  getters: {
    activeSpaces(): Space[] {
      return this.spaces.filter(s => !s.isArchived)
    },
    currentSpace(): Space | undefined {
      return this.spaces.find(s => s.id === this.currentSpaceId)
    },
    spacePageByPageId() {
      return (pageId: string) =>
        Object.values(this.spacePages)
          .flatMap(p => p)
          .find(p => p.id === pageId)
    },
    spacePagesBySpaceId() {
      return (spaceId: string) => this.spacePages[spaceId] || []
    },
    spacesCreatedByMe(): Space[] {
      const userStore = useUserStore()
      const currentUserId = userStore.currentUser?.id
      return this.spaces.filter(s => s.createdBy === currentUserId)
    },
    spacesRecentlyViewed(): Space[] {
      return this.spaces.filter(s => s.spaceStatistics.lastViewedByUser)
    },
    archivedSpaces(): Space[] {
      return this.spaces.filter(s => s.isArchived)
    },
    spacesBySpaceListId() {
      return (listId: string) => this.spaces.filter(s => !s.isArchived && s.spaceListId === listId)
    },
    usersToVerifyForSignature(): SignaturePositionsResponse[] {
      const uniqueUserIds = new Set<string>()
      return this.signaturePositions
        .filter(s => !s.signed)
        .filter(s => {
          if (uniqueUserIds.has(s.userId)) {
            return false
          }
          uniqueUserIds.add(s.userId)
          return true
        })
    }
  },
  actions: {
    async getSpaceLink(
      space: Space,
      internal: boolean,
      lang: string = 'en_default',
      useCustomDomain: boolean = true
    ): Promise<string> {
      const teamStore = useTeamStore()
      let baseLink = `${window.location.origin}/${this.getSpaceSlug(space)}` + '?lang=' + lang
      if (useCustomDomain) {
        const customDomain = await teamStore.getCurrentCustomDomain()
        if (customDomain && customDomain.domainStatus === DomainValidationStatus.DOMAIN_ACTIVE) {
          baseLink = `${customDomain.domainName}/${this.getSpaceSlug(space)}` + '?lang=' + lang
        }
      }
      return internal ? `${baseLink}&internal=true` : baseLink
    },
    getSpaceSlug(space: Space) {
      const sluggifiedName = space.name.replaceAll(' ', '-').replace(/[^a-zA-Z0-9-]/g, '')
      return `${sluggifiedName}-${space.id}`
    },
    getIdFromSpaceSlug(spaceSlug: string): string {
      const paramLength = spaceSlug.length
      return spaceSlug.substring(paramLength - 36, paramLength)
    },
    setCurrentSpaceId(spaceId: string | null) {
      Vue.set(this, 'currentSpaceId', spaceId)
    },
    setCurrentPageId(pageId: string | null) {
      Vue.set(this, 'currentPageId', pageId)
    },
    setTriggerPageReload(value: boolean) {
      this.triggerPageReload = value
    },
    setSpace(space: Space) {
      let spaces = this.spaces as Space[]
      let oldSpace = spaces.find(p => p.id === space.id)
      const indexToUpdate = this.spaces.findIndex(space => space.id === space.id)
      if (indexToUpdate === -1) {
        Vue.set(this, 'spaces', [...spaces, space])
      } else {
        spaces = spaces.filter(p => p.id !== space.id)
        oldSpace = { ...oldSpace, ...space }

        Vue.set(this, 'spaces', (this.spaces = [...spaces, oldSpace].sort((a, b) => a.name.localeCompare(b.name))))
      }
    },
    setSpacePages(spaceId: string, pages: SpacePage[]) {
      const sortedPages = pages.length > 1 ? pages.sort((a, b) => a.position - b.position) : pages
      Vue.set(this.spacePages, spaceId, sortedPages)
    },
    setSpacePage(spaceId: string, page: SpacePage) {
      let spacePages = this.spacePages[spaceId] as SpacePage[]
      let oldSpacePage = spacePages.find(p => p.id === page.id)
      if (!oldSpacePage) {
        const sortedPages = [...spacePages, page].sort((a, b) => a.position - b.position)
        Vue.set(this.spacePages, spaceId, sortedPages)
      } else {
        spacePages = spacePages.filter(p => p.id !== page.id)
        oldSpacePage = { ...oldSpacePage, ...page }
        const sortedPages = [...spacePages, oldSpacePage].sort((a, b) => a.position - b.position)
        Vue.set(this.spacePages, spaceId, sortedPages)
      }
    },
    deleteSpacePage(spaceId: string, pageId: string) {
      let spacePages = this.spacePages[spaceId] as SpacePage[]
      spacePages = spacePages.filter(p => p.id !== pageId)
      Vue.set(this.spacePages, spaceId, spacePages)
    },
    async fetchSpaces(url: string, params: Record<string, any> = {}): Promise<void> {
      const response = await ApiClient({
        method: 'get',
        url,
        params
      })

      const spaces = response.data as Space[]
      spaces.forEach(space => {
        this.setSpace(space)
        if (this.spacePages[space.id] == undefined) {
          this.setSpacePages(space.id, [])
        }
      })
    },
    async fetchSpacesCreatedByMe(): Promise<void> {
      await this.fetchSpaces('/spaces/created-by-me')
    },
    async fetchRecentlyViewedSpaces(): Promise<void> {
      await this.fetchSpaces('/spaces/recently-viewed')
    },
    async fetchArchivedSpaces(): Promise<void> {
      await this.fetchSpaces('/spaces/archived')
    },
    async fetchSpacesBySpaceListId(spaceListId: string): Promise<void> {
      await this.fetchSpaces('/spaces', { spaceListId })
    },
    async fetchSpace(spaceId: string): Promise<void> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}`
      })
      const space = response.data as Space
      this.setSpace(space)
    },
    async createSpace(payload: CreateSpaceRequest): Promise<Space> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces`,
        data: payload
      })
      const newSpace = response.data as Space
      this.setSpace(newSpace)
      const userStore = useUserStore()
      const currentUser = userStore.currentUser
      if (currentUser) {
        trackCreateSpace(
          newSpace.id,
          !!payload.spacePageTemplateName,
          payload.spacePageTemplateName!!,
          payload.templateGallery!!,
          !!payload.companyDomain!!
        )
        const router = (await import('@/router')).default
        if (router.currentRoute.query.fromRegisterFlow) {
          trackOnboardingFlow3()
        }
      }
      return newSpace
    },
    async duplicateSpaceAndRoute(duplicateSpace: Space): Promise<Space> {
      const duplicateSpaceRequest = new DuplicateSpaceRequest()
      duplicateSpaceRequest.spaceId = duplicateSpace.id
      duplicateSpaceRequest.name = `${i18n.t('newspace.copyOf') as string}${duplicateSpace.name}`
      const newSpace = await this.duplicateSpace(duplicateSpaceRequest)
      this.routeToSpace(newSpace)
      return newSpace
    },
    async duplicateSpace(duplicateSpace: DuplicateSpaceRequest): Promise<Space> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${duplicateSpace.spaceId}/duplicate`,
        data: duplicateSpace,
        timeout: SPACE_DUPLICATION_TIMEOUT
      })
      const space = response.data as Space
      this.setSpace(space)
      return space
    },
    async deleteSpace(spaceId: string): Promise<void> {
      await ApiClient({
        method: 'delete',
        url: `/spaces/${spaceId}`
      })
      this.spaces = this.spaces.filter(p => p.id !== spaceId)
      Vue.delete(this.spacePages, spaceId)
    },
    async publishSpace(id: string): Promise<Space> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/publish`
      })
      const newSpace = response.data as Space
      this.setSpace(newSpace)
      return newSpace
    },
    async uploadBanner(id: string, file: File): Promise<Space> {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/banner`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const newSpace = response.data as Space
      this.setSpace(newSpace)
      return newSpace
    },
    async updateBannerWithBrandfetch(id: string, updateSpaceBannerRequest: UpdateSpaceBannerRequest): Promise<Space> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/banner-brandfetch`,
        data: updateSpaceBannerRequest
      })
      const newSpace = response.data as Space
      this.setSpace(newSpace)
      return newSpace
    },
    async updateSpace(id: string, request: UpdateSpaceRequest): Promise<void> {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${id}`,
        data: request
      })
      const updatedSpace = (response.data as GeneralApiResponse<Space>).data
      this.setSpace(updatedSpace)
    },
    async addRecipient(id: string, recipientRequest: AddRecipientRequest): Promise<void> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/recipients`,
        data: recipientRequest
      })
      const oldSpace = this.spaces.find(space => space.id === id)
      const newSpace = response.data as Space

      const newRecipient = newSpace?.recipients.find(newRecipient => {
        return !oldSpace?.recipients.some(existingRecipient => existingRecipient.userId === newRecipient.userId)
      })

      if (newRecipient) {
        const userStore = useUserStore()
        try {
          await userStore.fetchUser(newRecipient.userId)
        } catch (error) {
          console.error(`Failed to fetch user with ID ${newRecipient.userId}`, error)
        }
      }
      this.setSpace(newSpace)
      const space = response.data as Space
      this.setSpace(space)
    },
    async removeMembers(id: string, removeRecipientsRequest: RemoveRecipientsRequest): Promise<void> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/remove-recipients`,
        data: removeRecipientsRequest
      })
      const space = response.data as Space
      this.setSpace(space)
    },
    async getPages(spaceId: string): Promise<SpacePage[]> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/pages`
      })
      const pages = response.data as SpacePage[]
      this.setSpacePages(spaceId, pages)
      return pages
    },
    async createPage(spaceId: string, page: SpacePage): Promise<SpacePage> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${spaceId}/pages`,
        data: page
      })
      const newPage = response.data as SpacePage
      this.setSpacePage(spaceId, newPage)
      return newPage
    },
    async updatePageName(spaceId: string, pageId: string, name: string): Promise<void> {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${spaceId}/pages/${pageId}/name`,
        data: {
          name
        }
      })
      const page = response.data as SpacePage
      this.setSpacePage(spaceId, page)
    },
    async updatePageSharedStatus(spaceId: string, pageId: string, isShared: boolean): Promise<void> {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${spaceId}/pages/${pageId}/shared`,
        data: {
          isShared: isShared
        }
      })
      const page = response.data as SpacePage
      this.setSpacePage(spaceId, page)
    },
    async updatePagePosition(spaceId: string, pageId: string, position: number): Promise<void> {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${spaceId}/pages/${pageId}/position`,
        data: {
          position
        }
      })
      const page = response.data as SpacePage
      this.setSpacePage(spaceId, page)
    },
    async duplicatePage(pageId: string, spaceId: string, name: string, position: number): Promise<void> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${spaceId}/pages/${pageId}/duplicate`,
        data: {
          name,
          position
        }
      })
      const page = response.data as SpacePage
      this.setSpacePage(spaceId, page)
    },
    async deletePage(spaceId: string, pageId: string): Promise<void> {
      await ApiClient({
        method: 'delete',
        url: `/spaces/${spaceId}/pages/${pageId}`
      })
      this.deleteSpacePage(spaceId, pageId)
    },
    async setVerificationMode(spaceId: string, verificationMode: SpaceVerificationMode): Promise<void> {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${spaceId}/verification-mode`,
        data: { verificationMode }
      })
      const space = response.data as Space
      this.setSpace(space)
    },
    async routeToSpace(space: Space) {
      const router = (await import('@/router')).default
      router.push(`/${this.getSpaceSlug(space)}`)
    },
    async createSpaceAndRoute(payload: CreateSpaceRequest) {
      const createdSpace = await this.createSpace(payload)
      this.routeToSpace(createdSpace)
    },
    async fetchUsers(spaceId: string): Promise<void> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/users`
      })
      const users = response.data as User[]
      useUserStore().setUsers(users)
    },
    async getSpaceListDirectory() {
      const response = await ApiClient({
        method: 'get',
        url: '/space-list-directories'
      })
      const spaceDirectoryColumns = response.data as SpaceListDirectoryColumn[]
      this.spaceListDirectoryColumns = spaceDirectoryColumns
      return spaceDirectoryColumns
    },
    async updateSpaceListDirectoryVisibility(category: SpaceListColumnCategory, visible: boolean) {
      const response = await ApiClient({
        method: 'put',
        url: `/space-list-directories/${encodeURIComponent(category)}/visibility`,
        params: {
          visible
        }
      })
      const spaceDirectoryColumns = response.data as SpaceListDirectoryColumn[]
      this.spaceListDirectoryColumns = spaceDirectoryColumns
      return spaceDirectoryColumns
    },
    async uploadSellerLogo(id: string, file: File): Promise<Space> {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/seller-logo`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const space = response.data as Space
      this.setSpace(space)
      return space
    },
    async uploadClientLogo(id: string, file: File): Promise<Space> {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/client-logo`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const space = response.data as Space
      this.setSpace(space)
      return space
    },
    async clearSignaturesOfPage(spaceId: string, pageId: string): Promise<Element[]> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${spaceId}/pages/${pageId}/clear-signatures`
      })
      this.getSignaturePositions(spaceId)
      const elements = response.data as Element[]
      const elementStore = useElementStore()
      elements.map(e => elementStore.setElement(e))
      return elements
    },
    async getSignaturePositions(spaceId: string): Promise<SignaturePositionsResponse[]> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/signature-positions`
      })
      this.signaturePositions = response.data as SignaturePositionsResponse[]
      return this.signaturePositions
    },
    async onSignatureElementUpdated(element: Element): Promise<SignaturePositionsResponse[]> {
      if (element.rootEntityType === RootEntityType.SPACE_PAGE) {
        const page = this.spacePageByPageId(element.rootEntityId)
        if (page !== null) {
          const space = this.spaces.find(s => s.id === page?.spaceId)
          if (space !== null) {
            return this.getSignaturePositions(space!.id)
          }
        }
      }
      return []
    },
    async verifyEmail(spaceId: string, email: string): Promise<void> {
      await ApiClient({
        method: 'post',
        url: `/spaces/${spaceId}/verify-email`,
        params: { email }
      })
    },
    async getTasks(spaceId: string) {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/tasks`
      })
      const tasksResult = response.data as Task[]
      const taskStore = useTaskStore()
      tasksResult.forEach(task => taskStore.setTask(task))
      return tasksResult
    },
    async generatePagePdf(spaceId: string, pageId: string) {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/pages/${pageId}/pdf`,
        timeout: PDF_GENERATION_TIMEOUT
      })

      const urlToDownload = response.data.url
      const link = document.createElement('a')
      link.href = urlToDownload
      link.download = `${pageId}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return link
    },
    async generateSpacePdf(spaceId: string) {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/pdf`,
        timeout: PDF_GENERATION_TIMEOUT
      })

      const urlToDownload = response.data.url
      const link = document.createElement('a')
      link.href = urlToDownload
      link.download = `${spaceId}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return link
    },
    async isPublic(id: string): Promise<Boolean> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${id}/is-public`
      })
      const isPublic = response.data
      return isPublic
    },
    async canAccessSpace(id: string): Promise<Boolean> {
      const isLoggedIn = await useAuthStore().isLoggedIn()
      if (!isLoggedIn) {
        return false
      }
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${id}/can-access`
      })
      const canAccess = response.data
      return canAccess
    },
    async inviteRecipients(id: string, request: EmailRecipientRequest): Promise<void> {
      const response = await ApiClient({
        method: 'post',
        url: `/spaces/${id}/invite-recipients`,
        data: request
      })
      const space = response.data as Space
      this.setSpace(space)
    },
    async getRecipientPersonalizedLink(spaceId: string, userId: string): Promise<string> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/recipients/${userId}/access-url`
      })
      const link = response.data as string
      return link
    },
    async getVerificationScreenData(spaceId: string): Promise<VerificationScreenData> {
      const response = await ApiClient({
        method: 'get',
        url: `/spaces/${spaceId}/verification-screen-data`
      })
      return response.data as VerificationScreenData
    }
  }
})

export type SpaceStore = ReturnType<typeof useSpaceStore>
