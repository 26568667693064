
<article :class="type">
  <n-icon :icon="icon" size="m" />
  <div class="content">
    <h6 v-if="title">{{ title }}</h6>
    <div v-else>{{ content }}</div>
    <p>
      <slot>{{ title ? content : '' }}</slot>
    </p>
  </div>
  <slot name="actions" />
</article>
