import User from '@/modules/user/models/User'
import { useUserStore } from '@/modules/user/stores/UserStore'
import ApiClient from '@/util/ApiClient'
import { trackLogin, trackLogout } from '@/util/segment-tracking'
import { Pinia, Store, defineStore, getActivePinia } from 'pinia'
import RegisterRequest from '../models/RegisterRequest'
import { TokenStorage } from './TokenStorage'
import { useWebSocketStore } from '@/modules/global/stores/WebSocketStore'

export type AuthState = {
  loginLoading: boolean
  loginOnce: boolean
  redirectPath: string | null
  // this promise can be awaited from everywhere. implicitly tracks if currently a refresh is running or not
  refreshTokenPromise: Promise<void>
}

const tokenStorage = new TokenStorage()

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    loginLoading: false,
    loginOnce: false,
    redirectPath: null,
    refreshTokenPromise: Promise.resolve()
  }),
  actions: {
    getToken() {
      return tokenStorage.tokens?.token
    },
    getTokenExpiry() {
      return tokenStorage.tokens?.tokenExpiry
    },
    async isLoggedIn(): Promise<boolean> {
      await this.checkSpaceToken()
      return Boolean(this.getToken())
    },
    startLogin() {
      this.loginLoading = true
      this.loginOnce = true
    },
    endLogin() {
      this.loginLoading = false
    },
    setRedirectPath(redirectPath: string | null) {
      this.redirectPath = redirectPath
    },
    saveToken(token: string, refreshToken: string) {
      tokenStorage.storeTokenPair(token, refreshToken)
    },
    savePublicToken(token: string, refreshToken: string) {
      tokenStorage.storeTokenPairPublic(token, refreshToken)
      tokenStorage.setUsePublicSpaceToken(true)
    },
    async refreshToken() {
      const refreshToken = tokenStorage.tokens?.refreshToken
      if (!refreshToken) {
        return console.info('No refresh token stored')
      }

      try {
        const response = await ApiClient.post('/users/refreshtoken', { refreshToken })
        if (response.data.token && response.data.refreshToken) {
          tokenStorage.storeTokenPairDependingOnSpaceTokenUsage(response.data.token, response.data.refreshToken)
        } else {
          this.clear()
          console.error('Invalid or missing tokens in the response')
        }
      } catch (error: any) {
        this.clear()
        if (!error.response) {
          console.error('Network error')
        } else {
          console.error('Refresh token request failed', error.response.data)
        }
      }
    },
    clear() {
      tokenStorage.deleteTokens()
    },
    async checkSpaceToken(): Promise<void> {
      const query = new URLSearchParams(window.location.search)

      const spaceTokenNames = ['spaceToken', 'workspaceToken']
      const spaceTokenName = query.has(spaceTokenNames[0])
        ? spaceTokenNames[0]
        : query.has(spaceTokenNames[1])
        ? spaceTokenNames[1]
        : null
      if (!spaceTokenName) {
        return
      }

      const spaceToken = query.get(spaceTokenName)!
      await ApiClient({
        method: 'post',
        url: '/users/checkToken',
        headers: {
          Authorization: spaceToken
        }
      })

      this.clearAllStores()
      this.saveToken(spaceToken, '')

      query.delete(spaceTokenName)

      window.location.replace(window.location.pathname + '?' + query.toString())
    },
    async registerVerification(verificationToken: string): Promise<string> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/register-verification',
        data: {
          verificationToken
        }
      })
      const token = response.data['token']
      const refreshToken = response.data['refreshToken']
      if (token && refreshToken) {
        this.saveToken(token, refreshToken)
        return response.headers['userid']
      } else {
        throw new Error('Something went wrong with the Login')
      }
    },
    async sendGoogleRegistration(googleToken: string): Promise<void> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/register-with-google',
        data: { token: googleToken }
      })
      const token = response.data.token
      const refreshToken = response.data.refreshToken
      if (token && refreshToken) {
        this.saveToken(token, refreshToken)
        return
      } else {
        throw new Error('Something went wrong with the Google Registration')
      }
    },
    async sendInviteRegistration(registerRequest: RegisterRequest): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/registerFromInvite',
        data: registerRequest
      })
      return response.data as User
    },
    async sendRegistration(registerRequest: RegisterRequest): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/register',
        data: registerRequest
      })
      return response.data as User
    },
    async resendVerificationMail(): Promise<void> {
      await ApiClient({
        method: 'post',
        url: '/users/resend-verification'
      })
    },
    async validateInviteRegisterToken(token: String): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/validateRegisterFromInvite',
        data: {
          token
        }
      })
      return response.data as User
    },
    async sendPasswordResetEmail(email: string): Promise<void> {
      await ApiClient({
        method: 'post',
        url: '/users/sendPasswordResetEmail',
        data: {
          email
        }
      })
    },
    async validatePasswordResetToken(token: String): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/validatePasswordReset',
        data: {
          token
        }
      })
      return response.data as User
    },
    async updateUserPassword(token: String, newPassword: String): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/changePassword',
        data: {
          token,
          newPassword
        }
      })
      return response.data as User
    },
    async startTokenRefresh() {
      this.refreshTokenPromise = this.refreshToken()
    },

    async login(loginCall: () => Promise<string | void>): Promise<string | void> {
      this.startLogin()

      return new Promise((resolve, reject) => {
        loginCall()
          .then(async () => {
            await useUserStore().fetchCurrentUser()

            if (this.redirectPath) {
              resolve(this.redirectPath)
              this.setRedirectPath(null)
            } else {
              if (useUserStore().isUserNotSetup) {
                resolve('/setup')
              } else {
                resolve('/recently-viewed')
              }
            }
          })
          .catch(() => {
            reject()
          })
          .finally(() => {
            this.endLogin()
          })
      })
    },
    async loginWithPassword(email: string, password: string): Promise<string | void> {
      return this.login(async () => {
        const response = await ApiClient({
          method: 'post',
          url: '/login',
          data: { email, password }
        })
        const token = response.headers['token']
        const refreshToken = response.headers['refreshtoken']

        if (token && refreshToken) {
          await this.saveToken(token, refreshToken)
          await useUserStore().fetchCurrentUser()
          const currentUser = useUserStore().currentUser
          if (currentUser) {
            trackLogin('email_password', currentUser.email, currentUser.id)
          }
        } else {
          throw new Error('Something went wrong with the Login')
        }
      })
    },
    async loginWithGoogle(googleToken: string): Promise<string | void> {
      return this.login(async () => {
        const response = await ApiClient({
          method: 'post',
          url: '/users/login-with-google',
          data: { token: googleToken }
        })
        const token = response.data.token
        const refreshToken = response.data.refreshToken
        if (token && refreshToken) {
          await this.saveToken(token, refreshToken)
          await useUserStore().fetchCurrentUser()
          const currentUser = useUserStore().currentUser
          if (currentUser) {
            trackLogin('email_password', currentUser.email, currentUser.id)
          }
        } else {
          throw new Error('Something went wrong with the Google Login')
        }
      })
    },
    async loginAsAnonymous(spaceId: string) {
      try {
        await this.login(async () => {
          const response = await ApiClient({
            method: 'post',
            url: `/users/login-as-anonymous`,
            params: {
              spaceId,
              userId: tokenStorage.anonymousUserId
            }
          })
          const token = response.data.token
          const refreshToken = response.data.refreshToken
          if (token && refreshToken) {
            this.savePublicToken(token, refreshToken)
          } else {
            throw new Error('Something went wrong with the Anonymous Login')
          }
        })
        return true
      } catch (e) {
        return false
      }
    },
    async initUser() {
      if (this.loginOnce || useUserStore().currentUser) {
        return
      }

      this.startLogin()
      await useUserStore()
        .fetchCurrentUser()
        .finally(async () => {
          this.endLogin()
        })
    },
    async logout(emailPrefill: string | undefined = undefined) {
      const currentUser = useUserStore().currentUser
      if (currentUser) await trackLogout()
      this.clearAllStores()
      let queryString = ''
      if (emailPrefill) {
        const params = new URLSearchParams()
        params.set('email', emailPrefill)
        queryString = `?${params.toString()}`
      }
      const webSocketStore = useWebSocketStore()
      webSocketStore.disconnectWebSocket()
      const router = (await import('@/router')).default
      await router.push(`/login${queryString}`)
    },
    clearAllStores() {
      interface ExtendedPinia extends Pinia {
        _s: Map<string, Store>
      }

      const pinia = getActivePinia() as ExtendedPinia

      if (pinia) {
        pinia._s.forEach(store => {
          store.$reset()
        })
      }
      this.clear()
    }
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
