
<div
  v-on="$listeners"
  class="editor-wrapper"
  :class="[styleType, { 'single-line': isSingleLine, 'no-heading-margins': noHeadingMargins }]"
>
  <bubble-menu :editor="editor" v-if="useFloatingMenu && editor" :tippyOptions="tippyOptions">
    <TextStylingBar
      :editor="editor"
      :showSend="false"
      :showOverlay="true"
      :hideActions="hideActions"
      :hideTextTypes="hideTextTypes"
    />
  </bubble-menu>
  <editor-content :editor="editor" ref="editor" />

  <div
    v-if="showEditorPlaceholder"
    @click="editor.view.focus()"
    class="editor-placeholder"
    :class="styleType"
    v-html="placeholder"
  />
</div>
