import NMenu from '@/modules/global/components/core/NMenu/NMenu.vue'
import { defineStore } from 'pinia'

export type MenuState = {
  getCurrentlyOpenElementRef: () => HTMLElement | null
  getCurrentMenuInstance: () => NMenu | null
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    getCurrentlyOpenElementRef: () => null,
    getCurrentMenuInstance: () => null
  }),
  getters: {
    currentlyOpenElementRef(): HTMLElement | null {
      return this.getCurrentlyOpenElementRef()
    },
    currentMenuInstance(): NMenu | null {
      return this.getCurrentMenuInstance()
    }
  },
  actions: {
    setCurrentMenuData(menuInstance: NMenu | null, openElementRef: HTMLElement | null) {
      this.getCurrentMenuInstance = () => menuInstance
      this.getCurrentlyOpenElementRef = () => openElementRef
    },
    closeActiveMenu() {
      const activeMenuComponent = this.currentMenuInstance as any
      if (activeMenuComponent) {
        activeMenuComponent.close()
      }
    }
  }
})

export type MenuStore = ReturnType<typeof useMenuStore>
