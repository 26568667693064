import RegisterTrackingData, { REGISTRATION_METHOD } from '@/modules/auth/models/RegisterTrackingData'
import { EVENT_NAMES } from '@/util/EventNames'
import ApiClient from '@/util/ApiClient'

const TRACKING_API_INIT_URL = '/tracking/init'
const TRACKING_API_URL = '/tracking/event'

async function sendInitTrackingData(
  userId: string,
  teamId?: string,
  country?: string,
  registrationMethod?: string,
  deviceOnSignup?: string,
  signupType?: string,
  department?: string,
  position?: string,
  companySize?: string,
  companyWebsite?: string,
  teamName?: string,
  useCase?: string[]
) {
  try {
    const response = await ApiClient.post(TRACKING_API_INIT_URL, {
      userId: userId,
      teamId: teamId || null,
      country: country || null,
      registrationMethod: registrationMethod || null,
      deviceOnSignup: deviceOnSignup || null,
      signupType: signupType || null,
      department: department || null,
      position: position || null,
      companySize: companySize || null,
      companyWebsite: companyWebsite || null,
      teamName: teamName || null,
      useCase: useCase || null
    })

    if (!response) {
      console.error('Failed to initialize tracking:', 'Response status:', response)
    }
  } catch (error) {
    console.error('Error initializing tracking:', error)
  }
}

export async function initTracking(
  userId: string,
  teamId?: string,
  country?: string,
  registrationMethod?: string,
  deviceOnSignup?: string,
  signupType?: string,
  department?: string,
  position?: string,
  companySize?: string,
  companyWebsite?: string,
  teamName?: string,
  useCase?: string[]
) {
  await sendInitTrackingData(
    userId,
    teamId,
    country,
    registrationMethod,
    deviceOnSignup,
    signupType,
    department,
    position,
    companySize,
    companyWebsite,
    teamName,
    useCase
  )
}

async function sendTrackingEvent(eventName: any, params: any, sync: boolean = false) {
  try {
    if (sync) {
      const response = await ApiClient.post(TRACKING_API_URL, {
        eventName: eventName,
        properties: params
      })

      if (!response) {
        console.error('Failed to track event synchronously:', eventName, 'Response status:', response)
      }
    } else {
      const response = ApiClient.post(TRACKING_API_URL, {
        eventName: eventName,
        properties: params
      })

      if (!response) {
        console.error('Failed to track event asynchronously:', eventName, 'Response status:', response)
      }
    }
  } catch (error) {
    console.error(`Error sending ${sync ? 'synchronous' : 'asynchronous'} tracking event:`, eventName, error)
  }
}

export function trackPage() {
  const analytics = window.analytics
  analytics.page()
}

// Events for registration and login
export function trackUserSignup(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.USER_SIGNED_UP, {
    user_id: registerTrackingData.user_id,
    first_name: registerTrackingData.first_name,
    last_name: registerTrackingData.last_name,
    registration_method: registerTrackingData.registration_method,
    email: registerTrackingData.email,
    department: registerTrackingData.department,
    position: registerTrackingData.position,
    company_size: registerTrackingData.company_size,
    company_website: registerTrackingData.company_website,
    team_name: registerTrackingData.team_name,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type
  })
  trackSignupDataLayer(registerTrackingData)
}

export function trackSignupDataLayer(registerTrackingData: RegisterTrackingData) {
  if (window.dataLayer) {
    const provider = registerTrackingData.registration_method === REGISTRATION_METHOD.GOOGLE ? 'google' : 'email'

    window.dataLayer.push({
      event: 'signup',
      provider: provider,
      user_email: registerTrackingData.email,
      user_id: registerTrackingData.user_id
    })
  }
}

export async function trackRegistrationStep1(registerTrackingData: RegisterTrackingData) {
  await sendTrackingEvent(
    EVENT_NAMES.REGISTRATION_STEP_1,
    {
      user_id: registerTrackingData.user_id,
      email: registerTrackingData.email,
      registration_method: registerTrackingData.registration_method,
      device_on_signup: registerTrackingData.device_on_signup,
      signup_type: registerTrackingData.signup_type
    },
    true
  )
}

export function trackRegistrationStep2(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.REGISTRATION_STEP_2, {
    user_id: registerTrackingData.user_id,
    first_name: registerTrackingData.first_name,
    last_name: registerTrackingData.last_name,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type,
    department: registerTrackingData.department
  })
}

export function trackRegistrationStep3(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.REGISTRATION_STEP_3, {
    user_id: registerTrackingData.user_id,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type,
    position: registerTrackingData.position,
    company_size: registerTrackingData.company_size,
    company_website: registerTrackingData.company_website
  })
}

export function trackRegistrationStep4(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.REGISTRATION_STEP_4, {
    user_id: registerTrackingData.user_id,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type
  })
}

export function trackRegistrationStep5(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.REGISTRATION_STEP_5, {
    user_id: registerTrackingData.user_id,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type,
    use_case: registerTrackingData.use_case
  })
}

export function trackRegistrationStep6(registerTrackingData: RegisterTrackingData) {
  sendTrackingEvent(EVENT_NAMES.REGISTRATION_STEP_6, {
    user_id: registerTrackingData.user_id,
    device_on_signup: registerTrackingData.device_on_signup,
    signup_type: registerTrackingData.signup_type,
    team_name: registerTrackingData.team_name,
    upgrade_button_clicked: registerTrackingData.upgrade_button_clicked
  })
}

export async function trackLogin(loginMethod: string, userEmail: string, userId: string) {
  sendTrackingEvent(EVENT_NAMES.USER_LOGGED_IN, {
    login_method: loginMethod
  })
  trackLoginDataLayer(loginMethod, userEmail, userId)
}

export function trackLoginDataLayer(loginMethod: string, userEmail: string, userId: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'login',
      provider: loginMethod,
      user_email: userEmail,
      user_id: userId
    })
  }
}

export async function trackLogout() {
  await sendTrackingEvent(EVENT_NAMES.USER_LOGGED_OUT, {}, true)
}

// Events for team interactions
export function trackInviteTeammember(inviteeEmail: string) {
  sendTrackingEvent(EVENT_NAMES.TEAM_MEMBER_INVITED, {
    team_member_email: inviteeEmail
  })
  trackTeamMemberInvitedDataLayer(inviteeEmail)
}

export function trackTeamMemberInvitedDataLayer(inviteeEmail: string) {
  const dataLayer: any = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({
      event: 'team_member_invited',
      invitee_email: inviteeEmail
    })
  }
}

// Events for onboarding
export function trackOnboardingFlow1() {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_FLOW_1, {})
}

export function trackOnboardingFlow2(templateName: string) {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_FLOW_2, {
    template_name: templateName
  })
}

export function trackOnboardingFlow3() {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_FLOW_3, {})
}

// TODO: TBD
export function trackOnboardingFlow4() {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_FLOW_4, {})
}

// TODO: TBD
export function trackOnboardingTourStarted() {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_TOUR_STARTED, {})
}

// TODO: TBD
export function trackOnboardingTourCompleted(userId: string) {
  sendTrackingEvent(EVENT_NAMES.ONBOARDING_TOUR_COMPLETED, { user_id: userId })
}

// Events for sales indicators
export function trackCustomerPortalClick() {
  sendTrackingEvent(EVENT_NAMES.STRIPE_PORTAL_OPENED, {})
}

export function trackTalkToSalesClick(featureName: string) {
  sendTrackingEvent(EVENT_NAMES.SALES_LINK_CLICKED, {
    feature_name: featureName
  })
}

export function trackPricingPageViewed() {
  sendTrackingEvent(EVENT_NAMES.PRICING_PAGE_VIEWED, {})
}

export function trackIntegrationPageViewed() {
  sendTrackingEvent(EVENT_NAMES.INTEGRATION_PAGE_VIEWED, {})
}

export function trackBrandingPageViewed() {
  sendTrackingEvent(EVENT_NAMES.BRANDING_PAGE_VIEWED, {})
}

export function trackDomainPageViewed() {
  sendTrackingEvent(EVENT_NAMES.DOMAIN_PAGE_VIEWED, {})
}

// Events for space interactions
export function trackCreateSpace(
  spaceId: string,
  templateUsed: boolean,
  templateName?: string,
  templateGallery?: string,
  brandfetchUsed?: boolean
) {
  const eventData: any = {
    space_id: spaceId,
    templated_used: templateUsed,
    brandfetch_used: brandfetchUsed
  }

  if (templateUsed) {
    eventData.template_name = templateName
    eventData.template_gallery = templateGallery
  }

  sendTrackingEvent(EVENT_NAMES.SPACE_CREATED, eventData)
}

export function trackSpacePublished(spaceId: string, accessOption: string) {
  sendTrackingEvent(EVENT_NAMES.SPACE_PUBLISHED, {
    space_id: spaceId,
    access_option: accessOption
  })
  trackSpacePublishedDataLayer(spaceId, accessOption)
}

export function trackSpacePublishedDataLayer(spaceId: string, accessOption: string) {
  const dataLayer: any = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({
      event: 'space_published',
      space_id: spaceId,
      access_option: accessOption
    })
  }
}

export function trackSpaceUnpublished(spaceId: string, accessOption: string) {
  sendTrackingEvent(EVENT_NAMES.SPACE_UNPUBLISHED, {
    space_id: spaceId,
    access_option: accessOption
  })
}

export function trackSpaceInvitationSent(spaceId: string, recipientEmails: string[], numberOfRecipients: number) {
  sendTrackingEvent(EVENT_NAMES.SPACE_INVITATION_SENT, {
    space_id: spaceId,
    recipient_emails: recipientEmails,
    number_of_recipients: numberOfRecipients
  })
}

export function trackSpaceLinkCopied(spaceId: string) {
  sendTrackingEvent(EVENT_NAMES.SPACE_LINK_COPIED, {
    space_id: spaceId
  })
  trackSpaceLinkCopiedDataLayer(spaceId)
}

export function trackSpaceLinkCopiedDataLayer(spaceId: string) {
  const dataLayer: any = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({
      event: 'space_link_copied',
      space_id: spaceId
    })
  }
}

export function trackPersonalLinkCopied(spaceId: string, recipientId: string) {
  sendTrackingEvent(EVENT_NAMES.PERSONAL_LINK_COPIED, {
    space_id: spaceId,
    recipient_id: recipientId
  })
  trackPersonalLinkCopiedDataLayer(spaceId, recipientId)
}

export function trackPersonalLinkCopiedDataLayer(spaceId: string, recipientId: string) {
  const dataLayer: any = (window as any).dataLayer
  if (dataLayer) {
    dataLayer.push({
      event: 'personal_link_copied',
      space_id: spaceId,
      recipient_id: recipientId
    })
  }
}

export function trackVisitSpace(spaceId: string) {
  sendTrackingEvent(EVENT_NAMES.SPACE_VIEWED, {
    space_id: spaceId
  })
}

// Events for task management
export function trackCreatedTask(taskId: string) {
  sendTrackingEvent(EVENT_NAMES.TASK_CREATED, {
    task_id: taskId
  })
}

export function trackOpenTask(taskId: string) {
  sendTrackingEvent(EVENT_NAMES.TASK_OPENED, {
    task_id: taskId
  })
}

export function trackTaskCompleted(taskId: string) {
  sendTrackingEvent(EVENT_NAMES.TASK_COMPLETED, {
    task_id: taskId
  })
}

// Events for element interactions
export function trackViewedSpaceAnalytics() {
  sendTrackingEvent(EVENT_NAMES.VIEWED_SPACE_ANALYTICS, {})
}

export function trackElementCreated(elementId: string, elementType: string, spaceId: string) {
  sendTrackingEvent(EVENT_NAMES.ELEMENT_CREATED, {
    element_id: elementId,
    element_type: elementType,
    space_id: spaceId
  })
}

export function trackElementRemoved(elementId: string, elementType: string, spaceId: string) {
  sendTrackingEvent(EVENT_NAMES.ELEMENT_REMOVED, {
    element_id: elementId,
    element_type: elementType,
    space_id: spaceId
  })
}

export function trackElementsRemoved(elements: { id: string; type: string; rootEntityId: string }[]) {
  const elementIds = elements.map(e => e.id)
  const elementTypes = elements.map(e => e.type)
  const spaceIds = elements.map(e => e.rootEntityId)

  sendTrackingEvent(EVENT_NAMES.ELEMENT_REMOVED, {
    element_ids: elementIds,
    element_types: elementTypes,
    space_ids: spaceIds
  })
}

export function trackDuplicateElement(elementId: string, elementType: string, rootEntityId: string) {
  sendTrackingEvent(EVENT_NAMES.ELEMENT_DUPLICATED, {
    element_id: elementId,
    element_type: elementType,
    root_entity_id: rootEntityId
  })
}

export function trackDuplicateElements(elements: { id: string; type: string; rootEntityId: string }[]) {
  const elementIds = elements.map(e => e.id)
  const elementTypes = elements.map(e => e.type)
  const rootEntityId = elements[0]?.rootEntityId || ''

  sendTrackingEvent(EVENT_NAMES.ELEMENT_DUPLICATED, {
    element_ids: elementIds,
    element_types: elementTypes,
    root_entity_id: rootEntityId
  })
}

export function trackElementInteracted(
  elementId: string,
  elementType: string,
  spaceId?: string | null,
  additionalData?: Record<string, any>
) {
  const properties: Record<string, any> = {
    element_id: elementId,
    element_type: elementType
  }

  if (spaceId != null) {
    properties.space_id = spaceId
  }

  if (additionalData) {
    Object.assign(properties, additionalData)
  }

  sendTrackingEvent(EVENT_NAMES.ELEMENT_INTERACTED, properties)
}

export function trackMadeInNotchButtonClick() {
  sendTrackingEvent(EVENT_NAMES.MADE_IN_NOTCH_CLICKED, {})
}

export function trackTryNotchForFreeInSuccessfullySignedModelClick(signatureId: string) {
  sendTrackingEvent(EVENT_NAMES.TRY_NOTCH_FOR_FREE_IN_SUCCESSFULLY_SIGNED_MODEL_CLICKED, {
    signature_id: signatureId
  })
}

// Events for messages and reactions
export function trackComment(commentId: string, messageContent: string) {
  sendTrackingEvent(EVENT_NAMES.MESSAGE_SENT, {
    message_id: commentId,
    message_content: messageContent
  })
}

export function trackCommentReply(commentId: string, messageContent: string) {
  sendTrackingEvent(EVENT_NAMES.MESSAGE_REPLIED, {
    message_id: commentId,
    message_content: messageContent
  })
}

export function trackCommentReaction(commentId: string, reactionContent: string) {
  sendTrackingEvent(EVENT_NAMES.MESSAGE_REACTED, {
    message_id: commentId,
    reaction_content: reactionContent
  })
}

// Events for integration
export function trackSlackConnected() {
  sendTrackingEvent(EVENT_NAMES.SLACK_CONNECTED, {})
}

export function trackSlackDisconnected() {
  sendTrackingEvent(EVENT_NAMES.SLACK_DISCONNECTED, {})
}

export function trackHubspotConnected() {
  sendTrackingEvent(EVENT_NAMES.HUBSPOT_CONNECTED, {})
}

export function trackHubspotDisconnected() {
  sendTrackingEvent(EVENT_NAMES.HUBSPOT_DISCONNECTED, {})
}

export function trackSalesforceConnected() {
  sendTrackingEvent(EVENT_NAMES.SALESFORCE_CONNECTED, {})
}

export function trackSalesforceDisconnected() {
  sendTrackingEvent(EVENT_NAMES.SALESFORCE_DISCONNECTED, {})
}

// Events for support
export function trackHelpWidgetOpened() {
  sendTrackingEvent(EVENT_NAMES.HELP_WIDGET_OPENED, {})
}

export function trackHelpMessageSent(messageType: string, messageContent: string) {
  sendTrackingEvent(EVENT_NAMES.HELP_MESSAGE_SENT, {
    message_type: messageType,
    message_content: messageContent
  })
}

export function trackHelpCalendarOpen() {
  sendTrackingEvent(EVENT_NAMES.HELP_CALENDAR_OPEN, {})
}
