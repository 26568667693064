import { RootEntity, RootEntityType } from '@/modules/elements/models/Element'
import { GeneralApiResponse } from '@/modules/global/models/GeneralApiResponse'
import Space from '@/modules/space/models/Space'
import SpacePage from '@/modules/space/models/SpacePage'
import Task from '@/modules/task/models/Task'
import { UpdateUserRequest } from '@/modules/user/models/UpdateUserRequest'
import ApiClient, { FILE_TIMEOUT } from '@/util/ApiClient'
import { defineStore } from 'pinia'
import User, { TeamRole, UserState } from '../models/User'
import { SubscriptionPlan } from '@/modules/team/models/Subscription'

export type UserStoreState = {
  currentUserId: string | null
  users: User[]
}

export const useUserStore = defineStore('user', {
  state: (): UserStoreState => ({
    currentUserId: null,
    users: []
  }),
  getters: {
    currentUser(): User | undefined {
      return this.users.find(u => u.id === this.currentUserId)
    },
    currentTeamMembers(): User[] {
      return this.users.filter(u => u.role !== TeamRole.EXTERNAL && u.state !== UserState.DISABLED)
    },
    isUserNotSetup(): boolean {
      const user = this.currentUser!
      return !user.isSetup
    }
  },
  actions: {
    setUsers(users: User[]) {
      this.users = users.sort((a, b) => a.email.localeCompare(b.email))
    },
    setUser(user: User) {
      let oldUser = this.users.find(u => u.id === user.id)
      if (!oldUser) {
        this.users = [...this.users, user].sort((a, b) => a.email.localeCompare(b.email))
      } else {
        const usersWithoutOldUser = this.users.filter(u => u.id !== user.id)
        oldUser = { ...oldUser, ...user }
        this.users = [...usersWithoutOldUser, oldUser].sort((a, b) => a.email.localeCompare(b.email))
      }
    },
    setCurrentUser(user: User) {
      this.currentUserId = user.id
    },
    async inviteMultipleUsers(emails: string[]): Promise<User[]> {
      const response = await ApiClient({
        method: 'post',
        url: `/users/invite-multiple`,
        data: { emails }
      })
      const users = response.data as User[]
      users.forEach(user => {
        this.setUser(user)
      })
      return users
    },
    async inviteUser(email: string): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: `/users/invite`,
        data: { email }
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async checkUserInvite(email: string): Promise<void> {
      await ApiClient({
        method: 'post',
        url: `/users/check-invite`,
        data: { email }
      })
    },
    async fetchAllUsers(): Promise<User[]> {
      const response = await ApiClient({
        method: 'get',
        url: `/users`
      })
      const users = response.data as User[]
      this.setUsers(users)
      return users
    },
    async fetchUser(userId: string): Promise<User> {
      const response = await ApiClient({
        method: 'get',
        url: `/users/${userId}`
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async fetchCurrentUser(): Promise<User> {
      const response = await ApiClient({
        method: 'get',
        url: `/users/current`
      })
      const user = response.data as User
      this.setUser(user)
      this.setCurrentUser(user)
      return user
    },
    async updateUser(updatedUser: UpdateUserRequest, userId: string): Promise<User> {
      const response = await ApiClient({
        method: 'put',
        url: `/users/${userId}`,
        data: updatedUser
      })
      const { data: user } = response.data as GeneralApiResponse<User>
      this.setUser(user)
      return user
    },
    async acceptInvite(): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: `/users/current/accept-invitation`
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async updateUserRole(id: string, updatedUser: UpdateUserRequest): Promise<User> {
      const response = await ApiClient({
        method: 'put',
        url: `/users/${id}/role`,
        data: updatedUser
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async disableUser(id: string): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: `/users/${id}/disable`
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async uploadProfilePicture(file: File) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/users/current/profilepicture`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async deleteProfilePicture() {
      const response = await ApiClient({
        method: 'delete',
        url: `/users/current/profilepicture`
      })
      const user = response.data as User
      this.setUser(user)
      return user
    },
    async completeSetup(): Promise<User> {
      const response = await ApiClient({
        method: 'post',
        url: '/users/complete-setup'
      })
      const currentUser = response.data as User
      this.setUser(currentUser)
      return currentUser
    },
    async getSubscriptionPlanForUser(userId: string): Promise<SubscriptionPlan | undefined> {
      return (await ApiClient.get<SubscriptionPlan | undefined>(`/users/${userId}/subscription-plan`)).data
    },
    getFilteredUsers(
      rootEntity: RootEntity,
      rootEntityType: RootEntityType,
      allUsers: User[],
      allSpaces: Space[]
    ): User[] {
      switch (rootEntityType) {
        case RootEntityType.SPACE_PAGE: {
          const spacePage = rootEntity as SpacePage
          if (spacePage.spaceId) {
            const space = allSpaces.find(w => w.id === spacePage.spaceId)
            if (space) {
              return allUsers.filter(
                u => space.recipients.map(r => r.userId).includes(u.id) && u.state !== UserState.DISABLED
              )
            }
          }
          return []
        }
        case RootEntityType.TASK: {
          const task = rootEntity as Task
          if (task.spaceId) {
            const space = allSpaces.find(w => w.id === task.spaceId)
            if (space) {
              return allUsers.filter(
                u => space.recipients.map(r => r.userId).includes(u.id) && u.state !== UserState.DISABLED
              )
            } else {
              return []
            }
          } else {
            return allUsers.filter(u => u.role !== TeamRole.EXTERNAL && u.state !== UserState.DISABLED)
          }
        }
        case RootEntityType.SPACE_PAGE_TEMPLATE:
        case RootEntityType.TASK_TEMPLATE: {
          return allUsers.filter(u => u.role !== TeamRole.EXTERNAL && u.state !== UserState.DISABLED)
        }
        default:
          return []
      }
    }
  }
})

export type UserStore = ReturnType<typeof useUserStore>
