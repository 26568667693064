import { useAuthStore } from '@/modules/auth/stores/AuthStore'
import { useCommentStore } from '@/modules/messages/stores/CommentStore'
import { Client, StompSubscription } from '@stomp/stompjs'
import { defineStore } from 'pinia'
import SockJS from 'sockjs-client/dist/sockjs'

const RETRY_WAIT_TIME = 20000
const CONNECTION_TIMEOUT = 10000

let client!: Client
let manualDisconnect = false
const connectionInits: (() => void)[] = []
const subscriptions: { [topicId: string]: StompSubscription } = {}

export const useWebSocketStore = defineStore('websocket', {
  state: (): {} => ({}),
  actions: {
    connectWebSocket() {
      manualDisconnect = false
      client = this.createClient()
      client.activate()
    },

    createClient(): Client {
      return new Client({
        webSocketFactory: () => new SockJS(import.meta.env.VITE_BASE_URL + '/socket'),
        reconnectDelay: RETRY_WAIT_TIME,
        connectionTimeout: CONNECTION_TIMEOUT,
        beforeConnect: () => {
          client.connectHeaders = {
            Authentication: useAuthStore().getToken() || ''
          }
        },
        onConnect: () => {
          this.initializeSubscriptions()
        },
        onStompError: frame => {
          console.error(`Broker reported error: ${frame.headers['message']}`)
          console.error(`Additional details: ${frame.body}`)
        },
        onWebSocketClose: () => {
          if (!manualDisconnect) {
            console.warn('WebSocket connection closed unexpectedly. Trying to reconnect...')
            this.retryConnection()
          } else {
            console.log('WebSocket disconnected manually. No reconnect attempt.')
          }
        }
      })
    },

    retryConnection() {
      setTimeout(() => {
        if (!client.connected) {
          console.log('Retrying WebSocket connection...')
          client.activate()
        }
      }, RETRY_WAIT_TIME)
    },

    initializeSubscriptions() {
      connectionInits.forEach(init => init())
      connectionInits.length = 0
    },

    subscribeToNewAppVersion(callback: Function) {
      const topic = '/topic/new-app-version'

      const messageCallback = (message: any) => {
        const body = this.parseMessageBody(message.body)
        callback(body)
      }

      this.subscribeToTopic(topic, 'new-app-version', messageCallback)
    },

    subscribeToChatTopic(topicId: string) {
      const messageCallback = (message: any) => {
        const body = this.parseMessageBody(message.body)
        this.handleChatMessage(body)
      }

      this.subscribeToTopic(`/topic/chat/space/${topicId}`, topicId, messageCallback)
    },

    handleChatMessage(body: any) {
      const commentStore = useCommentStore()
      switch (body.operation) {
        case 'CREATE':
        case 'UPDATE':
          commentStore.setComment(body.comment)
          break
        case 'DELETE':
          commentStore.updateStateDeleteComment(body.comment)
          break
        default:
          console.warn(`Unhandled operation: ${body.operation}`)
      }
    },

    subscribeToTopic(topic: string, topicId: string, messageCallback: (message: any) => void) {
      if (client.connected) {
        if (!subscriptions[topicId]) {
          subscriptions[topicId] = client.subscribe(topic, messageCallback)
        }
      } else {
        connectionInits.push(() => {
          if (!subscriptions[topicId]) {
            subscriptions[topicId] = client.subscribe(topic, messageCallback)
          }
        })
      }
    },

    parseMessageBody(body: string) {
      try {
        return JSON.parse(body)
      } catch (e) {
        console.error('Failed to parse WebSocket message body:', body)
        return null
      }
    },

    disconnectWebSocketTopic(topicId: string) {
      if (subscriptions[topicId]) {
        subscriptions[topicId].unsubscribe()
        delete subscriptions[topicId]
      }
    },

    disconnectWebSocket() {
      manualDisconnect = true
      if (client.connected) {
        Object.keys(subscriptions).forEach(topicId => {
          this.disconnectWebSocketTopic(topicId)
        })
        client.deactivate()
      }
    }
  }
})

export type WebSocketStore = ReturnType<typeof useWebSocketStore>
