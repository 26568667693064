import Comment from '@/modules/messages/models/Comment'
import { useSpaceStore } from '@/modules/space/stores/SpaceStore'
import { useUserStore } from '@/modules/user/stores/UserStore'
import ApiClient from '@/util/ApiClient'
import { trackComment, trackCommentReaction, trackCommentReply } from '@/util/segment-tracking'
import { defineStore } from 'pinia'

type CommentsForEntity = {
  comments: Comment[]
  currentPage: number
  allLoaded: boolean
}

type CommentsState = {
  commentsForEntity: { [entityId: string]: CommentsForEntity }
}

export const useCommentStore = defineStore('comment', {
  state: (): CommentsState => ({
    commentsForEntity: {}
  }),
  getters: {
    commentsByEntity() {
      return (spaceId: string): CommentsForEntity =>
        this.commentsForEntity[spaceId] || {
          comments: [],
          currentPage: 0,
          allLoaded: false
        }
    },
    commentsBySpace() {
      return (spaceId: string) => this.commentsByEntity(spaceId).comments || []
    },
    getCurrentPage() {
      return (spaceId: string) => this.commentsByEntity(spaceId).currentPage || 0
    },
    areAllCommentsLoaded() {
      return (spaceId: string) => this.commentsByEntity(spaceId).allLoaded || false
    }
  },
  actions: {
    async fetchComments(spaceId: string, page: number = 0, size: number = 40) {
      const response = (
        await ApiClient.get(`/spaces/${spaceId}/comments`, {
          params: { page, size }
        })
      ).data

      this.commentsForEntity[spaceId] = this.commentsByEntity(spaceId)

      const comments = response.content
      if (page === 0) {
        this.commentsForEntity[spaceId].comments = sortComments(comments)
      } else {
        this.commentsForEntity[spaceId].comments = sortComments([
          ...this.commentsByEntity(spaceId).comments,
          ...comments
        ])
      }
      this.commentsForEntity[spaceId].currentPage = page

      this.commentsForEntity[spaceId].allLoaded = response.last
      this.commentsForEntity = { ...this.commentsForEntity }
      return comments
    },
    async loadMoreComments(spaceId: string) {
      if (this.commentsByEntity(spaceId).allLoaded) {
        return []
      }
      const nextPage = (this.commentsByEntity(spaceId).currentPage || 0) + 1
      return this.fetchComments(spaceId, nextPage)
    },
    async setComments(comments: Comment[]) {
      comments.forEach(comment => {
        this.updateStateUpdateComment(comment)
      })
    },
    async setComment(comment: Comment) {
      const user = useUserStore().users.find(user => user.id === comment.createdBy)
      if (!user) {
        await useSpaceStore().fetchUsers(comment.entityId)
      }
      this.updateStateUpdateComment(comment)
    },
    async createComment(comment: Comment) {
      const commentResult = (await ApiClient.post(`/spaces/${comment.entityId}/comments`, comment)).data
      commentResult.replyTo
        ? trackCommentReply(commentResult.id, commentResult.message)
        : trackComment(commentResult.id, commentResult.message)
      this.updateStateUpdateComment(commentResult)
    },
    async updateComment(comment: Comment) {
      const commentResult = (await ApiClient.put(`/spaces/${comment.entityId}/comments/${comment.id}`, comment)).data
      this.updateStateUpdateComment(commentResult)
    },
    async deleteComment(comment: Comment) {
      const commentResult = (await ApiClient.delete(`/spaces/${comment.entityId}/comments/${comment.id}`)).data
      this.updateStateDeleteComment(commentResult)
    },
    async addCommentReaction(comment: Comment, reaction: string) {
      const response = await ApiClient({
        method: 'put',
        url: `/spaces/${comment.entityId}/comments/${comment.id}/reactions`,
        params: {
          emoji: reaction
        }
      })
      const commentResult = response.data as Comment
      trackCommentReaction(commentResult.id, commentResult.message)
      this.updateStateUpdateComment(commentResult)
    },
    async removeCommentReaction(comment: Comment, reaction: string) {
      const response = await ApiClient({
        method: 'delete',
        url: `/spaces/${comment.entityId}/comments/${comment.id}/reactions`,
        params: {
          emoji: reaction
        }
      })
      const commentResult = response.data as Comment
      this.updateStateUpdateComment(commentResult)
    },
    async setCommentSeen(comment: Comment) {
      const commentResult = (await ApiClient.post<Comment>(`/spaces/${comment.entityId}/comments/${comment.id}/seen`))
        .data
      this.updateStateUpdateComment(commentResult)
    },
    async fetchUnseenCommentsCount(spaceId: string): Promise<number> {
      return (await ApiClient.get<number>(`/spaces/${spaceId}/unseen-comments-count`)).data
    },
    clearComments() {
      this.commentsForEntity = {}
    },
    updateStateUpdateComment(comment: Comment) {
      this.commentsForEntity[comment.entityId] = this.commentsByEntity(comment.entityId)
      const originalComments = this.commentsByEntity(comment.entityId).comments || []
      const oldComment = originalComments.find(p => p.id === comment.id)
      if (!oldComment) {
        const newComments = sortComments([...originalComments, comment])
        this.commentsForEntity[comment.entityId].comments = newComments
        this.commentsForEntity = { ...this.commentsForEntity }
      } else {
        const updatedComment = { ...oldComment, ...comment }
        const newComments = sortComments([...originalComments.filter(p => p.id !== comment.id), updatedComment])
        this.commentsForEntity[comment.entityId].comments = newComments
        this.commentsForEntity = { ...this.commentsForEntity }
      }
    },
    updateStateDeleteComment(comment: Comment) {
      this.commentsForEntity[comment.entityId] = this.commentsByEntity(comment.entityId)
      const originalComments = this.commentsByEntity(comment.entityId).comments || []
      const newComments = originalComments.filter(item => item.id !== comment.id)
      this.commentsForEntity[comment.entityId].comments = newComments
      this.commentsForEntity = { ...this.commentsForEntity }
    }
  }
})

function sortComments(comments: Comment[]) {
  return comments.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return 1
    } else if (a.createdAt < b.createdAt) {
      return -1
    } else {
      return 0
    }
  })
}

export type CommentStore = ReturnType<typeof useCommentStore>
