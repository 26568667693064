export default class SpacePageTemplate {
  id!: string
  name!: string
  createdAt!: Date
  isGlobal!: boolean
  description?: string
  categories?: SpacePageTemplateCategoryName[]
  thumbnailUrl?: string
}

export class SpacePageTemplateCategory {
  id!: SpacePageTemplateCategoryName
  t_name!: string
}

export enum TemplateUsageContext {
  CREATE_SPACE_WITH_SPACE_LIST = 'CREATE_SPACE_WITH_SPACE_LIST',
  CREATE_SPACE_SELECT_SPACE_LIST = 'CREATE_SPACE_SELECT_SPACE_LIST',
  REPLACE_EMPTY_PAGE = 'REPLACE_EMPTY_PAGE',
  REPLACE_NON_EMPTY_PAGE = 'REPLACE_NON_EMPTY_PAGE'
}

export enum SpacePageTemplateCategoryName {
  FREELANCE_CONTRACTS = 'FREELANCE_CONTRACTS',
  SERVICE_AGREEMENTS = 'SERVICE_AGREEMENTS',
  COMPLIANCE_DOCS = 'COMPLIANCE_DOCS',
  CORPORATE_CONTRACTS = 'CORPORATE_CONTRACTS',
  SALES_CONTRACTS = 'SALES_CONTRACTS',
  EMPLOYMENT_DOCUMENTS = 'EMPLOYMENT_DOCUMENTS',
  FINANCIAL_CONTRACTS = 'FINANCIAL_CONTRACTS',
  PURCHASE_AGREEMENTS = 'PURCHASE_AGREEMENTS',
  CREATIVE_CONTRACTS = 'CREATIVE_CONTRACTS',
  RENTAL_AGREEMENTS = 'RENTAL_AGREEMENTS',
  LOAN_AGREEMENTS = 'LOAN_AGREEMENTS',
  EMPLOYMENT_AGREEMENTS = 'EMPLOYMENT_AGREEMENTS',
  EVENT_CONTRACTS = 'EVENT_CONTRACTS',
  REAL_ESTATE_CONTRACTS = 'REAL_ESTATE_CONTRACTS',
  RELEASE_FORMS = 'RELEASE_FORMS',
  RECOMMENDATION_LETTERS = 'RECOMMENDATION_LETTERS',
  RECEIPTS = 'RECEIPTS',
  VERIFICATION_FORMS = 'VERIFICATION_FORMS',
  VEHICLE_DOCS = 'VEHICLE_DOCS',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PERSONAL_DOCS = 'PERSONAL_DOCS',
  EVICTION_DOCS = 'EVICTION_DOCS',
  CONSENT_FORMS = 'CONSENT_FORMS',
  BILL_OF_SALE = 'BILL_OF_SALE',
  WILLS_AND_TRUSTS = 'WILLS_AND_TRUSTS',
  HEALTH_DOCS = 'HEALTH_DOCS'
}

export const categories: SpacePageTemplateCategory[] = [
  { id: SpacePageTemplateCategoryName.FREELANCE_CONTRACTS, t_name: 'freelanceContracts' },
  { id: SpacePageTemplateCategoryName.SERVICE_AGREEMENTS, t_name: 'serviceAgreements' },
  { id: SpacePageTemplateCategoryName.COMPLIANCE_DOCS, t_name: 'complianceDocs' },
  { id: SpacePageTemplateCategoryName.CORPORATE_CONTRACTS, t_name: 'corporateContracts' },
  { id: SpacePageTemplateCategoryName.SALES_CONTRACTS, t_name: 'salesContracts' },
  { id: SpacePageTemplateCategoryName.EMPLOYMENT_DOCUMENTS, t_name: 'employmentDocuments' },
  { id: SpacePageTemplateCategoryName.FINANCIAL_CONTRACTS, t_name: 'financialContracts' },
  { id: SpacePageTemplateCategoryName.PURCHASE_AGREEMENTS, t_name: 'purchaseAgreements' },
  { id: SpacePageTemplateCategoryName.CREATIVE_CONTRACTS, t_name: 'creativeContracts' },
  { id: SpacePageTemplateCategoryName.RENTAL_AGREEMENTS, t_name: 'rentalAgreements' },
  { id: SpacePageTemplateCategoryName.LOAN_AGREEMENTS, t_name: 'loanAgreements' },
  { id: SpacePageTemplateCategoryName.EMPLOYMENT_AGREEMENTS, t_name: 'employmentAgreements' },
  { id: SpacePageTemplateCategoryName.EVENT_CONTRACTS, t_name: 'eventContracts' },
  { id: SpacePageTemplateCategoryName.REAL_ESTATE_CONTRACTS, t_name: 'realEstateContracts' },
  { id: SpacePageTemplateCategoryName.RELEASE_FORMS, t_name: 'releaseForms' },
  { id: SpacePageTemplateCategoryName.RECOMMENDATION_LETTERS, t_name: 'recommendationLetters' },
  { id: SpacePageTemplateCategoryName.RECEIPTS, t_name: 'receipts' },
  { id: SpacePageTemplateCategoryName.VERIFICATION_FORMS, t_name: 'verificationForms' },
  { id: SpacePageTemplateCategoryName.VEHICLE_DOCS, t_name: 'vehicleDocs' },
  { id: SpacePageTemplateCategoryName.POWER_OF_ATTORNEY, t_name: 'powerOfAttorney' },
  { id: SpacePageTemplateCategoryName.PERSONAL_DOCS, t_name: 'personalDocs' },
  { id: SpacePageTemplateCategoryName.EVICTION_DOCS, t_name: 'evictionDocs' },
  { id: SpacePageTemplateCategoryName.CONSENT_FORMS, t_name: 'consentForms' },
  { id: SpacePageTemplateCategoryName.BILL_OF_SALE, t_name: 'billOfSale' },
  { id: SpacePageTemplateCategoryName.WILLS_AND_TRUSTS, t_name: 'willsAndTrusts' },
  { id: SpacePageTemplateCategoryName.HEALTH_DOCS, t_name: 'healthDocs' }
]
