
<div>
  <div class="contact-banner">
    <div class="left-side">
      <div class="avatars">
        <div v-for="link in notchMemberPictureLinks" :key="link" class="avatar">
          <img class="image-size" :src="link" />
        </div>
      </div>
    </div>
    <div class="right-side">
      <n-button :label="$t('general.close')" variant="tertiary" @click="onClose" />
      <n-button
        icon="play"
        :label="$t('framework.watchDemoVideo')"
        variant="secondary"
        @click="showGettingStartedModal = true"
      />
      <n-button :label="$t('framework.bookDemo')" @click="showTalkToSalesModal = true" />
    </div>
  </div>
  <GettingStartedModal v-if="showGettingStartedModal" @close="showGettingStartedModal = false" />
  <TalkToSalesModal v-if="showTalkToSalesModal" @close="showTalkToSalesModal = false" />
</div>
