export const clarityInit = function (c: any, l: any, a: any, r: any, i: any, t?: any, y?: any) {
  c[a] =
    c[a] ||
    function (...args: any[]) {
      c[a].q = c[a].q || []
      c[a].q.push(args)
    }
  t = l.createElement(r)
  t.async = 1
  t.src = 'https://www.clarity.ms/tag/' + i
  y = l.getElementsByTagName(r)[0]
  y.parentNode.insertBefore(t, y)
}
