
<div class="container" v-on="$listeners">
  <slot></slot>
  <div
    class="bubble"
    v-if="showBadge"
    :class="{
      dot: isDot,
      'anchor-top': top,
      'anchor-top-right': top && right,
      'anchor-top-left': top && left,
      'anchor-bottom': bottom,
      'anchor-bottom-right': bottom && right,
      'anchor-bottom-left': bottom && left,
      'help-widget': isHelpWidget,
      inside: inside
    }"
  >
    {{ countValue }}
  </div>
</div>
