import axios, { AxiosInstance } from 'axios'
import { uuid } from 'vue-uuid'

export const DEFAULT_API_CLIENT_TIMEOUT = 10000
export const FILE_TIMEOUT = 500000
export const SPACE_DUPLICATION_TIMEOUT = 500000
export const PDF_GENERATION_TIMEOUT = 50000

// List of paths that do not require authentication (no token check needed)
// Ant Design-like wildcards (*, **, ?) are supported for path matching.
const publicPaths: string[] = [
  '/crm/hubspot/createspace',
  '/crm/hubspot/autocomplete',
  '/crm/hubspot/spacelists',
  '/users/refreshtoken',
  '/login',
  '/users/register',
  '/users/register-with-google',
  '/users/login-with-google',
  '/users/login-as-anonymous',
  '/users/registerFromInvite',
  '/users/validateRegisterFromInvite',
  '/users/sendPasswordResetEmail',
  '/users/validatePasswordReset',
  '/users/changePassword',
  '/users/checkToken',
  '/users/register-verification',
  '/crm/salesforce/widget/**',
  '/spaces/*/verify-email',
  '/spaces/*/is-public',
  '/spaces/*/verification-screen-data'
]

class ApiClientService {
  readonly apiClient: AxiosInstance

  async getToken() {
    // wait if refresh is currently running
    const authStore = (await import('@/modules/auth/stores/AuthStore')).useAuthStore()
    await authStore.refreshTokenPromise
    return authStore.getToken()!
  }

  constructor() {
    const apiClient = axios.create({
      baseURL: import.meta.env.VITE_BASE_URL,
      timeout: DEFAULT_API_CLIENT_TIMEOUT
    })

    function createAntPathPatterns(paths: string[]): RegExp[] {
      return paths.map(pattern => {
        const regexPattern = pattern
          .replace(/\//g, '\\/')
          .replace(/\*\*/g, '.+') // Matches multiple path segments with at least one character
          .replace(/\*/g, '[^/]*') // Matches a single path segment
          .replace(/\?/g, '[^/]') // Matches a single character in a path segment
        return new RegExp(`^${regexPattern}$`)
      })
    }

    const placeholderPatterns: RegExp[] = createAntPathPatterns(publicPaths)

    apiClient.interceptors.request.use(
      async request => {
        const traceId = uuid.v4()
        request.headers['X-Trace-ID'] = traceId
        localStorage.setItem('traceId', traceId)
        if (request.url && placeholderPatterns.some(pattern => pattern.test(request.url!!))) {
          return request
        } else {
          const authStore = (await import('@/modules/auth/stores/AuthStore')).useAuthStore()
          const tokenExpiry = authStore.getTokenExpiry()?.getTime() || 0
          const inFiveMinutes = Date.now() + 5 * 60 * 1000
          const isTokenExpiredOrSoonExpiring = tokenExpiry < inFiveMinutes
          if (isTokenExpiredOrSoonExpiring) {
            await authStore.startTokenRefresh()
          }
          request.headers.Authorization = await this.getToken()
          return request
        }
      },
      error => error
    )

    const retryFlag = Symbol('retry')

    apiClient.interceptors.response.use(
      async response => {
        return response
      },
      async error => {
        const originalRequest = error.config
        const authStore = (await import('@/modules/auth/stores/AuthStore')).useAuthStore()

        if (originalRequest.url === '/users/refreshtoken') {
          authStore.logout()
          Promise.reject(error)
        }

        if (error.response?.status === 401 && !originalRequest[retryFlag]) {
          originalRequest[retryFlag] = true

          try {
            await authStore.startTokenRefresh()

            originalRequest.headers['Authorization'] = await this.getToken()

            return this.apiClient(originalRequest)
          } catch (e) {
            authStore.logout()
            return Promise.reject(e)
          }
        }
        return Promise.reject(error)
      }
    )

    this.apiClient = apiClient
  }
}

export default new ApiClientService().apiClient
