
<div class="items" ref="container">
  <div v-for="group in groupedItems" :key="group[0].category" class="group">
    <div v-if="group.length" class="group-title">{{ group[0].category }}</div>
    <CommandItem
      v-for="item in group"
      :key="item.key"
      :ref="item.index"
      :title="item.title"
      :subtitle="item.subTitle"
      :icon="item.icon"
      :selected="item.index === selectedIndex"
      :isNew="isNewElement(item)"
      :isFeatureRestricted="isFeatureRestricted(item)"
      @click="selectItem(item.index)"
      @mouseenter="onMouseEnter(item.index)"
    />
  </div>
  <div v-if="!items.length" class="no-results">{{ $t('slashCommands.noResults') }}</div>
</div>
