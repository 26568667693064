import { Node } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export default Node.create({
  name: 'paste-handler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('notchPasteRule'),
        props: {
          handlePaste: (_view, event, slice) => {
            return this.options.handlePaste(_view, event, slice)
          }
        }
      })
    ]
  }
})
