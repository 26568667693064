import { useFeatureRestrictionStore } from '@/modules/auth/stores/FeatureRestrictionStore'
import { ContainerType, ElementType, RootEntityType } from '@/modules/elements/models/Element'
import { EmbedType } from '@/modules/elements/models/Embed'
import { FeatureUpgrade } from '@/modules/featurerestriction/models/FeatureUpgrade'
import { useUpgradeFeatureModalStore } from '@/modules/global/stores/UpgradeFeatureModalStore'
import i18n from '@/util/config/i18n'
import { Extension } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { Editor } from '@tiptap/vue-2'
import VueI18n from 'vue-i18n'
import { FocusPosition } from './reorder-util'

type CommandProps = {
  editor: Editor
  range: any
  parent: Vue | null
  addElement: (...data: AddElementData[]) => void
  replaceElement: (data: AddElementData) => void
  boundsFunction: Function
}

export type SlashCommand = {
  key?: number
  index?: number
  category: string
  title: string
  subTitle: string
  icon: string
  command: (props: CommandProps) => void
  keywords?: string[]
}

export type AddElementData = {
  type: ElementType
  containerType?: ContainerType
  embedType?: EmbedType
  textContent?: string
  embedUrl?: string
  embedHtml?: string
  welcomeElementPayload?: WelcomeElementPayload
  focus?: FocusPosition
}

export type WelcomeElementPayload = {
  welcomeText?: string
  profileText?: string
}

export default Extension.create({
  name: 'slashcommand',

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...(this.options as any).suggestion
      })
    ]
  }
})

type SlashCommandGetter = (t: typeof VueI18n.prototype.t) => SlashCommand[]

const textCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('text'),
      title: t('heading1Title'),
      subTitle: t('heading1Subtitle'),
      icon: 'header-1',
      keywords: ['h1'],
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<h1></h1>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('heading2Title'),
      subTitle: t('heading2Subtitle'),
      icon: 'header-2',
      keywords: ['h2'],
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<h2></h2>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('heading3Title'),
      subTitle: t('heading3Subtitle'),
      icon: 'header-3',
      keywords: ['h3'],
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<h3></h3>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('paragraphTitle'),
      subTitle: t('paragraphSubtitle'),
      icon: 'menu-alt-2',
      keywords: ['t', 'te', 'tex', 'text'],
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).setNode('paragraph').focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<p></p>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('paragraphSmallTitle'),
      subTitle: t('paragraphSmallSubtitle'),
      icon: 'menu-alt-2',
      keywords: ['t', 'te', 'tex', 'text'],
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('paragraph')
            .updateAttributes('paragraph', { class: 'small' })
            .focus()
            .run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<p class="small"></p>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('bulletListTitle'),
      subTitle: t('bulletListSubtitle'),
      icon: 'bullet-list',
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).toggleBulletList().focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<ul><li></li></ul>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('checkBoxListTitle'),
      subTitle: t('checkBoxListSubtitle'),
      icon: 'checklist',
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).toggleTaskList().focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<ul data-type="taskList"><li></li></ul>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('orderedListTitle'),
      subTitle: t('orderedListSubtitle'),
      icon: 'ordered-list',
      command: ({ editor, range, addElement }: CommandProps) => {
        if (editor.state.doc.textContent.startsWith('/')) {
          editor.chain().focus().deleteRange(range).toggleOrderedList().focus().run()
        } else {
          editor.chain().focus().deleteRange(range).run()
          addElement({ type: ElementType.message, textContent: '<ol><li></li></ol>' })
        }
      }
    },
    {
      category: t('text'),
      title: t('mentionTitle'),
      subTitle: t('mentionSubtitle'),
      icon: 'at-symbol',
      command: ({ editor, range }: CommandProps) => {
        editor!.chain().focus().deleteRange(range).insertContent('@').run()
      }
    },
    {
      category: t('text'),
      title: t('blockquoteTitle'),
      subTitle: t('blockquoteSubtitle'),
      icon: 'quote',
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleBlockquote().focus().run()
      }
    }
  ] as SlashCommand[]
}

const proposalCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('proposal'),
      title: t('welcomeTitle'),
      subTitle: t('welcomeSubtitle'),
      icon: 'hand',
      command: (props: CommandProps) => {
        const elementData = {
          type: ElementType.welcome,
          welcomeElementPayload: {
            welcomeText: i18n.t('welcomeElement.prefilledWelcomeText') as string,
            profileText: i18n.t('welcomeElement.prefilledProfileText') as string
          }
        }
        replaceOrAddElement(props, elementData)
      }
    },
    {
      category: t('proposal'),
      title: t('summaryTitle'),
      subTitle: t('summarySubtitle'),
      icon: 'chat',
      command: (props: CommandProps) => {
        const elementData = { type: ElementType.summary, textContent: i18n.t('summary.prefilledText1') as string }
        replaceOrAddElement(props, elementData)
      }
    },
    {
      category: t('proposal'),
      title: t('propositionTitle'),
      subTitle: t('propositionSubtitle'),
      icon: 'star',
      keywords: ['vp'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.proposition })
      }
    },
    {
      category: t('proposal'),
      title: t('teamOverviewTitle'),
      subTitle: t('teamOverviewSubtitle'),
      icon: 'users',
      keywords: ['teams', 'overview', 'team'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.teamoverview })
      }
    },
    {
      category: t('proposal'),
      title: t('contactCard'),
      subTitle: t('contactCardSubtitle'),
      icon: 'identification',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.contactcard })
      }
    },
    {
      category: t('proposal'),
      title: t('clientLogosTitle'),
      subTitle: t('clientLogosSubtitle'),
      icon: 'office-building',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.clientlogos })
      }
    },
    {
      category: t('proposal'),
      title: t('faqTitle'),
      subTitle: t('faqSubtitle'),
      icon: 'question-mark-circle',
      keywords: ['faq', 'fa', 'qna', 'question', 'answer', 'q&a'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.faq })
      }
    },
    {
      category: t('proposal'),
      title: t('ctaBoxTitle'),
      subTitle: t('ctaBoxSubtitle'),
      icon: 'cursor-click',
      keywords: ['cta'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.ctabox })
      }
    }
  ] as SlashCommand[]
}

const progressCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('progress'),
      title: t('timelineTitle'),
      subTitle: t('timelineSubtitle'),
      icon: 'calendar',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.timeline })
      }
    },
    {
      category: t('progress'),
      title: t('taskListTitle'),
      subTitle: t('taskListSubtitle'),
      icon: 'clipboard-list',
      keywords: ['task', 'list', 'tasklist'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.tasklist })
      }
    }
  ] as SlashCommand[]
}

const offerCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('offer'),
      title: t('offerHeaderTitle'),
      subTitle: t('offerHeaderSubtitle'),
      icon: 'printer',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.offerheader })
      }
    },
    {
      category: t('offer'),
      title: t('offerTitle'),
      subTitle: t('offerSubtitle'),
      icon: 'cash',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.offer })
      }
    },
    {
      category: t('offer'),
      title: t('signatureTitle'),
      subTitle: t('signatureSubtitle'),
      icon: 'pencil',
      command: (props: CommandProps) => {
        if (!useFeatureRestrictionStore().allowSignature) {
          props.editor!.chain().focus().deleteRange(props.range).run()
          useUpgradeFeatureModalStore().showUpgradeFeatureModal(FeatureUpgrade.SIGNATURE)
        } else {
          replaceOrAddElement(props, { type: ElementType.signature })
        }
      }
    }
  ] as SlashCommand[]
}

const mediaCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('media'),
      title: t('fileTitle'),
      subTitle: t('fileSubtitle'),
      icon: 'document-text',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.FILE })
      }
    },
    {
      category: t('media'),
      title: t('imageTitle'),
      subTitle: t('imageSubtitle'),
      icon: 'photograph',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.IMAGE })
      }
    },
    {
      category: t('media'),
      title: t('videoTitle'),
      subTitle: t('videoSubtitle'),
      icon: 'video-camera',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.VIDEO })
      }
    },
    {
      category: t('media'),
      title: t('pdfTitle'),
      subTitle: t('pdfSubtitle'),
      icon: 'document',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.PDF })
      }
    },
    {
      category: t('media'),
      title: t('bookmarkTitle'),
      subTitle: t('bookmarkSubtitle'),
      icon: 'bookmark',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.linkbookmark })
      }
    }
  ] as SlashCommand[]
}

const fileManagementCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('fileManagement'),
      title: t('resourceListTitle'),
      subTitle: t('resourceListSubtitle'),
      icon: 'folder',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.resourcelist })
      }
    },
    {
      category: t('fileManagement'),
      title: t('clientResourceListTitle'),
      subTitle: t('clientResourceListSubtitle'),
      icon: 'folder-add',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.clientresources })
      }
    }
  ] as SlashCommand[]
}

const layoutCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('layout'),
      title: t('dividerTitle'),
      subTitle: t('dividerSubtitle'),
      icon: 'minus',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.divider })
      }
    },
    {
      category: t('layout'),
      title: t('tableTitle'),
      subTitle: t('tableSubtitle'),
      icon: 'table',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.table })
      }
    },
    {
      category: t('layout'),
      title: t('accordionTitle'),
      subTitle: t('accordionSubtitle'),
      icon: 'chevron-down',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.container, containerType: ContainerType.TOGGLE })
      }
    }
  ] as SlashCommand[]
}

const embedCommands: SlashCommandGetter = t => {
  return [
    {
      category: t('embeds'),
      title: t('genericEmbedTitle'),
      subTitle: t('genericEmbedSubtitle'),
      icon: 'puzzle',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.GENERIC })
      }
    },
    {
      category: t('embeds'),
      title: t('googleDriveTitle'),
      subTitle: t('googleDriveSubtitle'),
      icon: 'google-drive',
      keywords: ['gd'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.GDOCS })
      }
    },
    {
      category: t('embeds'),
      title: t('youtubeEmbedHeadline'),
      subTitle: t('youtubeEmbedSubtitle'),
      icon: 'youtube',
      keywords: ['yt'],
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.YOUTUBE })
      }
    },
    {
      category: t('embeds'),
      title: t('pitchEmbedTitle'),
      subTitle: t('pitchEmbedSubtitle'),
      icon: 'pitch',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.PITCH })
      }
    },
    {
      category: t('embeds'),
      title: t('loomEmbedHeadline'),
      subTitle: t('loomEmbedSubtitle'),
      icon: 'loom',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.LOOM })
      }
    },
    {
      category: t('embeds'),
      title: t('calendlyEmbedHeadline'),
      subTitle: t('calendlyEmbedSubtitle'),
      icon: 'calendly',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.CALENDLY })
      }
    },
    {
      category: t('embeds'),
      title: t('typeformEmbedTitle'),
      subTitle: t('typeformEmbedSubtitle'),
      icon: 'typeform',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.TYPEFORM })
      }
    },
    {
      category: t('embeds'),
      title: t('canvaEmbedTitle'),
      subTitle: t('canvaEmbedSubtitle'),
      icon: 'canva',
      command: (props: CommandProps) => {
        replaceOrAddElement(props, { type: ElementType.embed, embedType: EmbedType.CANVA })
      }
    }
  ] as SlashCommand[]
}

export function availableSlashCommands(query: { query: string; editor: Editor }, parent: Vue): SlashCommand[] {
  const rootEntityType = (parent as any).rootEntityType
  const isInTemplate = rootEntityType === RootEntityType.SPACE_PAGE_TEMPLATE
  const t = (key: string) => parent.$t(`slashCommands.${key}`)

  // position in the array matters and items of a group should always be next to each other
  const commands: SlashCommand[] = [
    ...textCommands(t),
    ...proposalCommands(t),
    ...progressCommands(t),
    ...offerCommands(t),
    ...mediaCommands(t),
    ...fileManagementCommands(t),
    ...layoutCommands(t),
    ...embedCommands(t)
  ]

  const isInTask = rootEntityType === RootEntityType.TASK || rootEntityType === RootEntityType.TASK_TEMPLATE

  return commands
    .map((item, index) => {
      item.key = index
      return item
    })
    .filter(item => {
      switch (item.title) {
        case t('taskListTitle'):
        case t('teamOverviewTitle'):
        case t('timelineTitle'):
        case t('welcomeTitle'):
        case t('clientLogosTitle'):
        case t('offerTitle'):
          if (isInTask) return false
          break
        case t('mentionTitle'):
          if (isInTemplate) return false
          break
        case t('signatureTitle'): {
          if (isInTask) {
            return false
          }
          break
        }
        default:
          break
      }

      const lowercaseQuery = query.query.toLowerCase()
      return (
        item.title.toLowerCase().includes(lowercaseQuery) ||
        item.keywords?.includes(lowercaseQuery) ||
        item.subTitle.toLowerCase().includes(lowercaseQuery) ||
        item.category.toLowerCase().includes(lowercaseQuery)
      )
    })
    .map((item, index) => {
      item.index = index
      return item
    })
}

function replaceOrAddElement({ editor, range, replaceElement, addElement }: CommandProps, data: AddElementData) {
  if (editor.state.doc.textContent.startsWith('/')) {
    replaceElement(data)
  } else {
    editor!.chain().focus().deleteRange(range).run()
    addElement(data)
  }
}
