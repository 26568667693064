
<div class="item" :class="{ selected: selected }" v-on="$listeners">
  <div class="icon-container"><n-icon :icon="icon" size="s" /></div>
  <div>
    <div class="title-container">
      <div class="title">{{ title }}</div>
      <div v-if="isNew && !isFeatureRestricted" class="new-element">{{ $t('slashCommands.new') }}</div>
      <div v-if="isFeatureRestricted" class="upgrade-element">{{ $t('general.upgrade') }}</div>
    </div>
    <div class="subtitle">{{ subtitle }}</div>
  </div>
</div>
