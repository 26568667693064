import { initDatadog, logError } from '@/util/init/datadog'
import { initComponents } from '@/util/init/initComponents'
import 'clickout-event'
import 'reflect-metadata'
import 'tippy.js/dist/tippy.css'
import Vue from 'vue'
import App from './App.vue'
import './assets/styles/core/fonts.scss'
import './assets/styles/globals.scss'
import i18n from './util/config/i18n'
import { clarityInit } from './util/init/clarity'
import { segmentInit } from './util/init/segment'
import { initValidation } from './util/init/validation'
// store must be initialized before router
import { createPinia } from 'pinia'

initValidation()

initDatadog()

Vue.config.errorHandler = (error: Error, vm, info) => {
  const context = {
    component: vm.$options.name,
    info,
    propsData: vm.$options.propsData
  }
  logError(error, context)
}

Vue.config.warnHandler = (msg: string, vm, trace) => {
  const context = {
    component: vm.$options.name,
    trace,
    propsData: vm.$options.propsData
  }
  logError(new Error(msg), context)
}

clarityInit(window, document, 'clarity', 'script', import.meta.env.VITE_CLARITY_ID)

segmentInit(import.meta.env.VITE_SEGMENT_ID!)

initComponents()

Vue.config.productionTip = false

console.log(
  ' @@@@@@@@   @@@@@                                      @@@@\n' +
    '@@@@@@@@@@  @@@@@                @@@@@                 @@@@\n' +
    '@@@@@@@@@@@ @@@@@                @@@@@         @@@@    @@@@    \n' +
    '@@@@  @@@@@ @@@@@   @@@@@@@@@  @@@@@@@@@@   @@@@@@@@@@ @@@@@@@@@@@\n' +
    '@@@@   @@@@@@@@@@ @@@@@@@@@@@@ @@@@@@@@@@ @@@@@@@@@@@  @@@@@@@@@@@\n' +
    '@@@@    @@@@@@@@@ @@@@@   @@@@@  @@@@@    @@@@@        @@@@   @@@@\n' +
    '@@@@    @@@@@@@@@ @@@@@   @@@@@  @@@@@    @@@@@@       @@@@   @@@@\n' +
    '@@@@     @@@@@@@   @@@@@@@@@@@    @@@@@@@@ @@@@@@@@@@@ @@@@   @@@@\n' +
    '@@@@     @@@@@@@    @@@@@@@@@     @@@@@@@@  @@@@@@@@@  @@@@   @@@@\n\n' +
    'Hello, fellow explorer! 🕵️‍♂️ Are you digging around for something interesting? If you are as passionate about ' +
    'uncovering new frontiers as we are, we should talk! 🚀 Check out our job openings at https://bit.ly/42kd87Z ' +
    'and join us on this exciting journey.'
)

const pinia = createPinia()

// Dynamically import router, to make sure pinia is initialized before
import('./router').then(r => {
  const router = r.default

  new Vue({
    pinia,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
