
<div>
  <textarea
    :value="value"
    v-on="listeners"
    @keydown="updateBodyHeight"
    ref="body"
    rows="1"
    :readonly="readonly"
    :class="['n-textarea', variant]"
    :maxlength="maxlength"
    :placeholder="placeholder"
  />
  <div v-if="maxlength && !readonly" class="maxlength">{{ length }}/{{ maxlength }}</div>
</div>
