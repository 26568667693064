const data = {
  body: `<svg width="1900" height="1900" viewBox="0 0 1900 1900" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_218_264)">
<path d="M950 1900C1474.67 1900 1900 1474.67 1900 950C1900 425.329 1474.67 0 950 0C425.329 0 0 425.329 0 950C0 1474.67 425.329 1900 950 1900Z" fill="#7D2AE7"/>
<path d="M950 1900C1474.67 1900 1900 1474.67 1900 950C1900 425.329 1474.67 0 950 0C425.329 0 0 425.329 0 950C0 1474.67 425.329 1900 950 1900Z" fill="url(#paint0_radial_218_264)"/>
<path d="M950 1900C1474.67 1900 1900 1474.67 1900 950C1900 425.329 1474.67 0 950 0C425.329 0 0 425.329 0 950C0 1474.67 425.329 1900 950 1900Z" fill="url(#paint1_radial_218_264)"/>
<path d="M950 1900C1474.67 1900 1900 1474.67 1900 950C1900 425.329 1474.67 0 950 0C425.329 0 0 425.329 0 950C0 1474.67 425.329 1900 950 1900Z" fill="url(#paint2_radial_218_264)"/>
<path d="M950 1900C1474.67 1900 1900 1474.67 1900 950C1900 425.329 1474.67 0 950 0C425.329 0 0 425.329 0 950C0 1474.67 425.329 1900 950 1900Z" fill="url(#paint3_radial_218_264)"/>
<path d="M1360.14 1144.88C1352.3 1144.88 1345.4 1151.5 1338.22 1165.96C1257.14 1330.37 1117.1 1446.7 955.046 1446.7C767.666 1446.7 651.627 1277.55 651.627 1043.87C651.627 648.036 872.175 419.173 1065.89 419.173C1156.41 419.173 1211.69 476.058 1211.69 566.583C1211.69 674.022 1150.65 730.907 1150.65 768.799C1150.65 785.808 1161.23 796.107 1182.21 796.107C1266.5 796.107 1365.43 699.252 1365.43 562.425C1365.43 429.756 1249.96 332.239 1056.25 332.239C736.105 332.239 451.585 629.043 451.585 1039.71C451.585 1357.59 633.107 1567.65 913.186 1567.65C1210.46 1567.65 1382.35 1271.88 1382.35 1175.88C1382.35 1154.62 1371.48 1144.88 1360.14 1144.88Z" fill="white"/>
</g>
<defs>
<radialGradient id="paint0_radial_218_264" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(367.009 1684.01) rotate(-49.416) scale(1469.49)">
<stop stop-color="#6420FF"/>
<stop offset="1" stop-color="#6420FF" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint1_radial_218_264" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(502.997 215.996) rotate(54.703) scale(1657.12)">
<stop/>
</radialGradient>
<radialGradient id="paint2_radial_218_264" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(367 1684) rotate(-45.1954) scale(1451.7 667.656)">
<stop stop-color="#6420FF"/>
<stop offset="1" stop-color="#6420FF" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_218_264" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(777 256) rotate(66.5198) scale(1495.86 2505.9)">
<stop offset="0.0001" stop-color="#141618"/>
</radialGradient>
<clipPath id="clip0_218_264">
<rect width="1900" height="1900" fill="white"/>
</clipPath>
</defs>
</svg>
`,
  width: 1900,
  height: 1900
}
export default data
