import ApiClient from '@/util/ApiClient'
import { trackCreatedTask, trackTaskCompleted } from '@/util/segment-tracking'
import { defineStore } from 'pinia'
import Task from '../models/Task'
import { uuid } from 'vue-uuid'

export type TaskState = {
  tasks: Task[]
}

export const useTaskStore = defineStore('task', {
  state: (): TaskState => ({
    tasks: [] as Task[]
  }),
  actions: {
    async createTask(task: Task): Promise<Task> {
      task.id = uuid.v4()
      task.inCreation = true
      this.setTask(task)
      const taskResponse = (await ApiClient.post<Task>(`/tasks`, task)).data
      this.setTask(taskResponse)
      trackCreatedTask(taskResponse.id)
      return taskResponse
    },
    async updateTask(task: Task): Promise<Task> {
      const taskResponse = (await ApiClient.put<Task>(`/tasks/${task.id}`, task)).data
      this.setTask(taskResponse)
      return taskResponse
    },
    async duplicateTask(taskId: string, newPosition: number): Promise<Task> {
      const duplicatedTask = (await ApiClient.post<Task>(`/tasks/${taskId}/duplicate`, { newPosition })).data
      this.setTask(duplicatedTask)
      trackCreatedTask(duplicatedTask.id)
      return duplicatedTask
    },
    async updateTaskProperty(task: Task, property: string, value: any): Promise<Task> {
      const genericTask = task as any
      genericTask[property] = value
      const data = {
        [property]: value
      }
      this.setTask(task)
      return (await ApiClient.put<Task>(`/tasks/${task.id}/${property}`, data)).data
    },
    async setStatus(task: Task, status: boolean): Promise<Task> {
      task.status = status
      const taskResponse = (
        await ApiClient({
          method: 'post',
          url: `/tasks/${task.id}/status`,
          params: {
            value: status
          }
        })
      ).data as Task
      this.setTask(taskResponse)
      if (status) {
        trackTaskCompleted(task.id)
      }
      return taskResponse
    },
    async setPositionInMilestone(task: Task, position: number, parentId?: string): Promise<Task> {
      if (parentId) {
        task.taskListId = parentId
      }

      const taskResponse = (
        await ApiClient.post<Task>(`/tasks/${task.id}/position-in-milestone`, {
          position: position,
          parentId: parentId
        })
      ).data
      this.setTask(taskResponse)
      return taskResponse
    },
    async deleteTask(taskId: string): Promise<void> {
      await ApiClient.delete(`/tasks/${taskId}`)
      this.deleteTaskFromStore(taskId)
    },
    setTask(task: Task) {
      let tasks = this.tasks as Task[]
      let oldTask = tasks.find(c => c.id === task.id)
      if (!oldTask) {
        this.tasks = [...tasks, task].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      } else {
        tasks = tasks.filter(c => c.id !== task.id)
        oldTask = { ...oldTask, ...task } as Task
        this.tasks = [...tasks, oldTask].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      }
    },
    deleteTaskFromStore(taskId: string) {
      const tasks = this.tasks as Task[]
      const taskIndex = tasks.findIndex(t => t.id === taskId)

      if (taskIndex >= 0) {
        this.tasks.splice(taskIndex, 1)
      }
    }
  }
})

export type TaskStore = ReturnType<typeof useTaskStore>
