
<label
  :for="id"
  :class="{
    'size-s': size === 's',
    'size-m': size === 'm',
    active: value,
    disabled: disabled
  }"
  @click="!disabled ? $emit('change', !value) : null"
>
  <span></span>
</label>
