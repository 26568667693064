export class Embed {
  type: EmbedType = EmbedType.IFRAME
  fileId?: string = ''
  embedUrl?: string = ''
  embedHTML?: string
  width?: number
  height?: number
  isSeamless?: Boolean = false
}

export enum EmbedType {
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  VIDEO = 'VIDEO',
  GDOCS = 'GDOCS',
  IFRAME = 'IFRAME',
  GENERIC = 'GENERIC',
  TYPEFORM = 'TYPEFORM',
  PITCH = 'PITCH',
  YOUTUBE = 'YOUTUBE',
  LOOM = 'LOOM',
  CALENDLY = 'CALENDLY',
  RECLAIM = 'RECLAIM',
  CANVA = 'CANVA',
  IFRAMELY = 'IFRAMELY'
}

export const DEFAULT_CALENDLY_HEIGHT = 920
export const DEFAULT_RECLAIM_HEIGHT = 550
