
<n-modal-background v-if="modals.length" ref="bg" :closeOnOutside="false" :closeOnEscape="false">
  <component
    v-for="config in modals"
    :key="config.id"
    :is="config.variant === 'confirm' ? 'NConfirm' : 'NAlert'"
    :title="config.title"
    :confirmButtonText="config.confirmButtonText"
    :confirmButtonIcon="config.confirmButtonIcon"
    @confirm="config.onConfirm ? config.onConfirm() : null"
    @cancel="config.onCancel ? config.onCancel() : null"
    @close="confirmModalStore.remove(config.id)"
  >
    {{ config.message }}
  </component>
</n-modal-background>
