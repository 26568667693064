const data = {
  body: `<svg xmlns="http://www.w3.org/2000/svg" width="37" height="10" viewBox="0 0 37 10" fill="none" style="height: 37px; width: 10px;">
  <g id="cta-logo">
  <path id="Vector" d="M17.1363 6.57409C17.1481 8.52039 15.7102 9.96934 13.7447 9.98055C11.7792 9.99175 10.33 8.55926 10.3182 6.61296C10.3064 4.66666 11.738 3.21774 13.7036 3.20653C15.6691 3.19533 17.1244 4.62779 17.1363 6.57409ZM12.3673 6.60128C12.3726 7.46665 12.9524 8.07198 13.7331 8.06753C14.5137 8.06308 15.0924 7.45114 15.0871 6.58577C15.0819 5.72039 14.502 5.11506 13.7152 5.11955C12.9283 5.12404 12.3621 5.7359 12.3673 6.60128Z" fill="#141618"/>
  <path id="Vector_2" d="M20.8882 5.11435L20.8997 7.01055C20.9043 7.7572 21.287 8.00604 21.7898 8.00317C22.1649 8.00104 22.5572 7.78775 22.8681 7.59411L23.5635 9.20341C22.9757 9.62247 22.3672 9.93132 21.3572 9.93708C19.6799 9.94664 18.9234 8.90689 18.9135 7.28729L18.9004 5.12569L17.7382 5.13231L17.727 3.30167L18.8893 3.29504L18.8778 1.40524L20.8672 1.3939L20.8786 3.2837L23.0079 3.27156L23.019 5.10221L20.8898 5.11435L20.8882 5.11435Z" fill="#141618"/>
  <path id="Vector_3" d="M30.0403 4.52393L28.4442 5.65362C28.1204 5.23544 27.7537 5.04099 27.2569 5.04382C26.5448 5.04788 25.939 5.60767 25.9446 6.52535C25.9501 7.44302 26.5485 7.99595 27.2621 7.99188C27.74 7.98915 28.1438 7.77763 28.4688 7.36209L30.0723 8.49256C29.4558 9.36782 28.5301 9.89615 27.3338 9.90297C25.2863 9.91464 23.8646 8.50101 23.8527 6.53569C23.8408 4.57037 25.2452 3.14063 27.2927 3.12895C28.489 3.12213 29.4147 3.64148 30.0419 4.52233L30.0403 4.52393Z" fill="#141618"/>
  <path id="Vector_4" d="M34.7355 2.97611C35.987 2.96897 36.9644 3.88416 36.9737 5.41166L37 9.74201L35.0007 9.75341L34.9789 6.16972C34.9741 5.38239 34.6007 4.95505 33.9812 4.95858C33.425 4.96175 32.9685 5.35478 32.9737 6.21532L32.9952 9.76647L30.996 9.77787L30.9371 0.0858154L32.9364 0.0744167L32.9594 3.85982C33.3575 3.25889 33.9506 2.98058 34.7355 2.97611Z" fill="#141618"/>
  <path id="Vector_5" d="M9.46518 0.207801L7.24167 0.220478L7.06622 7.3821L5.78853 2.97863C5.29334 1.25936 4.77296 0.0267422 2.8184 0.0378861C0.702778 0.0499482 -0.0143357 1.95655 0.0250558 4.06666L0.128032 9.95405L2.35155 9.94137C2.35155 9.94137 2.19747 4.65193 2.19258 3.84649C2.18601 2.76398 2.372 2.23453 2.83015 2.23192C3.32896 2.22907 3.64404 3.1294 3.93271 4.05888L5.77438 9.92186L7.79618 9.91033L8.88761 9.90411L9.46518 0.207801Z" fill="#141618"/>
  </g>
  </svg>
  `,
  width: 37,
  height: 10
}
export default data
