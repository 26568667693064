export const EVENT_NAMES = {
  USER_SIGNED_UP: 'user_signed_up',
  USER_LOGGED_IN: 'user_logged_in',
  USER_LOGGED_OUT: 'user_logged_out',
  TEAM_MEMBER_INVITED: 'team_member_invited',
  SPACE_CREATED: 'space_created',
  SPACE_VIEWED: 'space_viewed',
  STRIPE_PORTAL_OPENED: 'stripe_portal_opened',
  SALES_LINK_CLICKED: 'sales_link_clicked',
  MADE_IN_NOTCH_CLICKED: 'made_in_notch_clicked',
  TRY_NOTCH_FOR_FREE_IN_SUCCESSFULLY_SIGNED_MODEL_CLICKED: 'try_notch_for_free_in_successfully_signed_model_clicked',
  REGISTRATION_STEP_1: 'registration_step_1',
  REGISTRATION_STEP_2: 'registration_step_2',
  REGISTRATION_STEP_3: 'registration_step_3',
  REGISTRATION_STEP_4: 'registration_step_4',
  REGISTRATION_STEP_5: 'registration_step_5',
  REGISTRATION_STEP_6: 'registration_step_6',
  ONBOARDING_FLOW_1: 'onboarding_flow_1',
  ONBOARDING_FLOW_2: 'onboarding_flow_2',
  ONBOARDING_FLOW_3: 'onboarding_flow_3',
  ONBOARDING_FLOW_4: 'onboarding_flow_4',
  ONBOARDING_TOUR_STARTED: 'onboarding_tour_started',
  ONBOARDING_TOUR_COMPLETED: 'onboarding_tour_completed',
  // Implemented in backend in updateSubscriptionFromWebhook
  PLAN_CHANGED: 'plan_changed',
  // Implemented in backend in updateSubscriptionFromWebhook
  PLAN_CANCELED: 'plan_canceled',
  PRICING_PAGE_VIEWED: 'pricing_page_viewed',
  INTEGRATION_PAGE_VIEWED: 'integration_page_viewed',
  BRANDING_PAGE_VIEWED: 'branding_page_viewed',
  DOMAIN_PAGE_VIEWED: 'domain_page_viewed',
  SPACE_PUBLISHED: 'space_published',
  SPACE_UNPUBLISHED: 'space_unpublished',
  SPACE_INVITATION_SENT: 'space_invitation_sent',
  SPACE_LINK_COPIED: 'space_link_copied',
  PERSONAL_LINK_COPIED: 'personal_link_copied',
  TASK_CREATED: 'task_created',
  TASK_OPENED: 'task_opened',
  TASK_COMPLETED: 'task_completed',
  VIEWED_SPACE_ANALYTICS: 'viewed_space_analytics',
  ELEMENT_CREATED: 'element_created',
  ELEMENT_REMOVED: 'element_removed',
  ELEMENT_INTERACTED: 'element_interacted',
  ELEMENT_DUPLICATED: 'element_duplicated',
  // Implemented in backend
  SPACE_SIGNED: 'space_signed',
  // Implemented in backend
  SIGNATURE_SIGNED: 'signature_signed',
  MESSAGE_SENT: 'message_sent',
  MESSAGE_REPLIED: 'message_replied',
  MESSAGE_REACTED: 'message_reacted',
  // Implemented in backend
  DOMAIN_SETUP_STARTED: 'domain_setup_started',
  // Implemented in backend
  DOMAIN_SETUP_COMPLETED: 'domain_setup_completed',
  SLACK_CONNECTED: 'slack_connected',
  SLACK_DISCONNECTED: 'slack_disconnected',
  HUBSPOT_CONNECTED: 'hubspot_connected',
  HUBSPOT_DISCONNECTED: 'hubspot_disconnected',
  SALESFORCE_CONNECTED: 'salesforce_connected',
  SALESFORCE_DISCONNECTED: 'salesforce_disconnected',
  HELP_WIDGET_OPENED: 'help_widget_opened',
  HELP_MESSAGE_SENT: 'help_message_sent',
  HELP_CALENDAR_OPEN: 'help_calendar_open'
}
