import { useSpaceStore } from '@/modules/space/stores/SpaceStore'
import { LoadPageFromSpacePageTemplateRequest } from '@/modules/spacepagetemplates/models/LoadPageFromSpacePageTemplateRequest'
import { SavePageAsSpacePageTemplateRequest } from '@/modules/spacepagetemplates/models/SavePageAsSpacePageTemplateRequest'
import SpacePageTemplate, {
  SpacePageTemplateCategoryName,
  TemplateUsageContext
} from '@/modules/spacepagetemplates/models/SpacePageTemplate'
import { UpdateSpacePageTemplateNameRequest } from '@/modules/spacepagetemplates/models/UpdateSpacePageTemplateNameRequest'
import ApiClient from '@/util/ApiClient'
import { defineStore } from 'pinia'
import UpdateSpacePageTemplateRequest from '../models/UpdateSpacePageTemplateRequest'
import Space from '../models/Space'

export type SpacePageTemplateState = {
  activeTemplateId: string | null
  templates: SpacePageTemplate[]
  frequentlyUsedTemplates: SpacePageTemplate[]
  isLoading: boolean
  isTemplateManagerModalOpen: boolean
  currentUsageContext: TemplateUsageContext | null
}
export const useSpacePageTemplateStore = defineStore('spacePageTemplate', {
  state: (): SpacePageTemplateState => ({
    activeTemplateId: null,
    templates: [],
    frequentlyUsedTemplates: [],
    isLoading: false,
    isTemplateManagerModalOpen: false,
    currentUsageContext: null
  }),
  getters: {
    activeTemplate(): SpacePageTemplate | null {
      return this.templates.find(t => t.id === this.activeTemplateId) || null
    },
    localTemplates(): SpacePageTemplate[] {
      return this.templates.filter(t => !t.isGlobal)
    },
    spacePageTemplatesById() {
      return (templateId: string): SpacePageTemplate | null => {
        return this.templates.find(t => t.id === templateId) || null
      }
    },
    spacePageTemplatesByCategory() {
      return (category: SpacePageTemplateCategoryName): SpacePageTemplate[] => {
        return this.templates.filter(t => t.categories?.includes(category))
      }
    }
  },
  actions: {
    async loadTemplates(): Promise<void> {
      if (this.isLoading) {
        return
      }
      try {
        this.isLoading = true
        const response = await Promise.all([
          ApiClient.get<SpacePageTemplate[]>(`/space-page-templates`),
          ApiClient.get<SpacePageTemplate[]>(`/space-page-templates/global`)
        ])
        this.templates = response.flatMap(r => r.data)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    async loadFrequentlyUsedTemplates(): Promise<void> {
      const response = (await ApiClient.get<SpacePageTemplate[]>(`/space-page-templates/frequent`)).data
      this.frequentlyUsedTemplates = response
    },
    async updateTemplate(request: UpdateSpacePageTemplateRequest): Promise<void> {
      try {
        const template = (await ApiClient.put<SpacePageTemplate>(`/space-page-templates/${request.id}`, request)).data
        this.setTemplate(template)
      } catch (error) {
        console.error(error)
      }
    },
    async createTemplate(): Promise<void> {
      try {
        const newTempalte = (await ApiClient.post<SpacePageTemplate>(`/space-page-templates`, {})).data
        this.templates = [...this.templates, newTempalte]
        this.activeTemplateId = newTempalte.id
      } catch (error) {
        console.error(error)
      }
    },
    async deleteTemplate(id: string): Promise<void> {
      try {
        await ApiClient.delete(`/space-page-templates/${id}`)
        this.templates = this.templates.filter(t => t.id !== id)
        this.activeTemplateId = null
        this.loadFrequentlyUsedTemplates()
      } catch (error) {
        console.error(error)
      }
    },
    async updateTemplateName(data: UpdateSpacePageTemplateNameRequest): Promise<void> {
      try {
        const template = (
          await ApiClient.put<SpacePageTemplate>(`/space-page-templates/${data.id}/name`, { name: data.name })
        ).data
        if (template) {
          const index = this.templates.findIndex(t => t.id === template.id)
          if (index !== -1) {
            this.templates[index] = template
            this.templates = [...this.templates]
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async duplicateTemplate(id: string): Promise<void> {
      try {
        const template = (await ApiClient.post<SpacePageTemplate>(`/space-page-templates/${id}/duplicate`)).data
        this.templates = [...this.templates, template]
        this.activeTemplateId = template.id
      } catch (error) {
        console.error(error)
      }
    },
    async loadPageFromTemplate(payload: LoadPageFromSpacePageTemplateRequest): Promise<void> {
      try {
        const response = await ApiClient({
          method: 'post',
          url: `/spaces/${payload.spaceId}/pages/${payload.pageId}/load-from-template`,
          params: { templateId: payload.templateId }
        })
        const space = response.data as Space
        useSpaceStore().setSpace(space)

        this.loadFrequentlyUsedTemplates()
        this.activeTemplateId = null
      } catch (error) {
        console.error(error)
      }
    },
    async savePageAsTemplate({ pageId, spaceId }: SavePageAsSpacePageTemplateRequest): Promise<void> {
      try {
        const response = await ApiClient({
          method: 'post',
          url: `/spaces/${spaceId}/pages/${pageId}/save-as-template`
        })

        const template = response.data as SpacePageTemplate
        this.templates = [...this.templates, template]
      } catch (error) {
        console.error(error)
      }
    },
    async copyGlobalToTeamTemplate(id: string): Promise<void> {
      try {
        const template = (
          await ApiClient.post<SpacePageTemplate>(`/space-page-templates/${id}/copy-global-to-team-template`)
        ).data
        this.templates = [...this.templates, template]
        this.activeTemplateId = template.id
      } catch (error) {
        console.error(error)
      }
    },
    setActiveTemplate(id: string | null) {
      this.activeTemplateId = id
    },
    setTemplate(template: SpacePageTemplate) {
      const index = this.templates.findIndex(t => t.id === template.id)

      if (index !== -1) {
        this.templates.splice(index, 1, template)
      } else {
        this.templates.push(template)
      }
    },
    setTemplateManagerModal(isOpen: boolean, usage?: TemplateUsageContext) {
      this.isTemplateManagerModalOpen = isOpen
      this.currentUsageContext = isOpen && usage ? usage : null
    }
  }
})

export type SpacePageTemplateStore = ReturnType<typeof useSpacePageTemplateStore>
