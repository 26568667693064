import { defineStore } from 'pinia'

export type AppState = {
  isInPreviewMode: boolean
  isInPrintMode: boolean
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    isInPreviewMode: false,
    isInPrintMode: false
  }),
  actions: {
    async setPreviewMode(value: boolean) {
      this.isInPreviewMode = value
    },
    async setPrintMode(value: boolean) {
      this.isInPrintMode = value
    }
  }
})

export type AppStore = ReturnType<typeof useAppStore>
