
<div v-if="showSnackbars" class="snackbar-provider">
  <transition-group name="fade-snackbar" tag="article" class="top-center">
    <NSnackbar v-for="snackbarProps in topCenter" :key="snackbarProps.id" class="nsnackbar" v-bind="snackbarProps" />
  </transition-group>

  <transition-group name="fade-snackbar" tag="article" class="top-right">
    <NSnackbar v-for="snackbarProps in topRight" :key="snackbarProps.id" class="nsnackbar" v-bind="snackbarProps" />
  </transition-group>

  <transition-group name="fade-snackbar" tag="article" class="top-left">
    <NSnackbar v-for="snackbarProps in topLeft" :key="snackbarProps.id" class="nsnackbar" v-bind="snackbarProps" />
  </transition-group>

  <transition-group name="fade-snackbar" tag="article" class="bottom-center">
    <NSnackbar
      v-for="snackbarProps in bottomCenter"
      :key="snackbarProps.id"
      class="nsnackbar"
      v-bind="snackbarProps"
    />
  </transition-group>

  <transition-group name="fade-snackbar" tag="article" class="bottom-right">
    <NSnackbar
      v-for="snackbarProps in bottomRight"
      :key="snackbarProps.id"
      class="nsnackbar"
      v-bind="snackbarProps"
    />
  </transition-group>

  <transition-group name="fade-snackbar" tag="article" class="bottom-left">
    <NSnackbar v-for="snackbarProps in bottomLeft" :key="snackbarProps.id" class="nsnackbar" v-bind="snackbarProps" />
  </transition-group>
</div>
