import User from '@/modules/user/models/User'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

const validSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl'] as const

export default class UserAvatarMixin extends Vue {
  @Prop() protected user!: User
  @Prop() protected avatarInitials!: string
  @Prop({ type: String, validator: (prop: typeof validSizes[number]) => validSizes.includes(prop), default: 'l' })
  protected size!: typeof validSizes[number]
}
