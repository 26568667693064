import TaskItem from '@tiptap/extension-task-item'
import { uuid } from 'vue-uuid'

export const CustomTaskItem = TaskItem.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        parseHTML: element => element.getAttribute('data-task-id'),
        renderHTML: attributes => {
          return {
            'data-task-id': attributes.id ? attributes.id : uuid.v4()
          }
        },
        keepOnSplit: false
      }
    }
  }
})
