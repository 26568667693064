import { FeatureUpgrade } from '@/modules/featurerestriction/models/FeatureUpgrade'
import { defineStore } from 'pinia'

export type UpgradeFeatureModalState = {
  featureUpgrade?: FeatureUpgrade
}

export const useUpgradeFeatureModalStore = defineStore('upgradeFeatureModal', {
  state: (): UpgradeFeatureModalState => ({
    featureUpgrade: undefined
  }),
  actions: {
    showUpgradeFeatureModal(featureUpgrade: FeatureUpgrade) {
      this.featureUpgrade = featureUpgrade
    },
    hideUpgradeFeatureModal() {
      this.featureUpgrade = undefined
    }
  }
})

export type UpgradeFeatureModalStore = ReturnType<typeof useUpgradeFeatureModalStore>
