
<div class="plan" :class="{ 'active-plan': isActivePlan }">
  <div class="plan-header">{{ planTitle }}</div>
  <div class="plan-price">
    {{ planPrice }}
    <span>{{ planDuration }}</span>
  </div>
  <ul class="plan-features">
    <li class="check-item" v-for="(feature, index) in planFeatures" :key="index">
      <n-icon class="icon-space" icon="check" size="s" />
      {{ feature }}
    </li>
  </ul>
  <n-button :label="buttonLabel" @click="onButtonClick" class="plan-action" :loading="loading" />
</div>
