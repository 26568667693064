
<component :is="tag" class="n-list-item" ref="listItem" v-on="$listeners">
  <div class="list-item" :class="{ active }">
    <div class="leading-section">
      <div v-if="hasRadioButton" class="radio">
        <n-radio :value="value" :selection="selection" @selected="$emit('selected', $event)" />
      </div>

      <div :class="['icon', leadingIconColor]" v-if="hasLeadingIcon">
        <n-icon :icon="leadingIcon" size="s" />
      </div>
    </div>

    <div class="text" v-if="hasText">
      <slot>{{ text }}</slot>
    </div>
    <div v-if="hasTrailingIcon">
      <n-icon :icon="trailingIcon" size="s" />
    </div>

    <slot name="action">
      <n-icon v-if="checked" icon="check" size="s" class="submenu-chevron" />
      <n-icon v-if="$slots.submenu" icon="chevron-right" size="s" class="submenu-chevron" />
      <div v-if="$slots.submenu" class="submenu-wrapper" :class="{ 'open-left': submenuOpenLeft }">
        <slot v-if="showSubmenuInternal" name="submenu" />
      </div>
    </slot>
  </div>
</component>
