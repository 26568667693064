
<div
  class="indicators"
  :class="{
    vertical: variant === 'vertical'
  }"
>
  <span
    class="indicator"
    v-for="index in numPages"
    :key="index"
    :class="{
      active: index === currentPage,
      horizontal: variant === 'horizontal',
      vertical: variant === 'vertical'
    }"
  ></span>
</div>
