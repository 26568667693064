import alignLeft from '@/assets/icons/align-left'
import alignCenter from '@/assets/icons/align-center'
import alignRight from '@/assets/icons/align-right'
import alignJustify from '@/assets/icons/align-justify'
import bar from '@/assets/icons/bar'
import bulletList from '@/assets/icons/bullet-list'
import checkList from '@/assets/icons/checklist'
import calendly from '@/assets/icons/calendly'
import canva from '@/assets/icons/canva'
import circle from '@/assets/icons/circle'
import ctaLogo from '@/assets/icons/cta-logo'
import dot from '@/assets/icons/dot'
import dragHandle from '@/assets/icons/drag-handle'
import formatText from '@/assets/icons/format-text'
import googleDrive from '@/assets/icons/google-drive'
import header1 from '@/assets/icons/header-1'
import header2 from '@/assets/icons/header-2'
import header3 from '@/assets/icons/header-3'
import linkedIn from '@/assets/icons/linkedIn'
import loom from '@/assets/icons/loom'
import orderedList from '@/assets/icons/ordered-list'
import pin from '@/assets/icons/pin'
import pitch from '@/assets/icons/pitch'
import Quote from '@/assets/icons/quote'
import spaces from '@/assets/icons/space'
import switchHandle from '@/assets/icons/switch-handle'
import template from '@/assets/icons/template'
import toggle from '@/assets/icons/toggle'
import toggleSolid from '@/assets/icons/toggle-solid'
import typeform from '@/assets/icons/typeform'
import youtube from '@/assets/icons/youtube'

import archive from '@iconify-icons/heroicons-outline/archive'
import academicCap from '@iconify-icons/heroicons-outline/academic-cap'
import arrowCircleDown from '@iconify-icons/heroicons-outline/arrow-circle-down'
import arrowCircleRight from '@iconify-icons/heroicons-outline/arrow-circle-right'
import arrowDown from '@iconify-icons/heroicons-outline/arrow-down'
import arrowDownTray from '@iconify-icons/heroicons-outline/arrow-down-tray'
import arrowLeft from '@iconify-icons/heroicons-outline/arrow-left'
import arrowRight from '@iconify-icons/heroicons-outline/arrow-right'
import arrowUp from '@iconify-icons/heroicons-outline/arrow-up'
import arrowsExpand from '@iconify-icons/heroicons-outline/arrows-expand'
import bell from '@iconify-icons/heroicons-outline/bell'
import bookmark from '@iconify-icons/heroicons-outline/bookmark'
import calculator from '@iconify-icons/heroicons-outline/calculator'
import calendar from '@iconify-icons/heroicons-outline/calendar'
import adjustments from '@iconify-icons/heroicons-outline/adjustments'
import camera from '@iconify-icons/heroicons-outline/camera'
import cash from '@iconify-icons/heroicons-outline/cash'
import locationMarker from '@iconify-icons/heroicons-outline/location-marker'
import chartBar from '@iconify-icons/heroicons-outline/chart-bar'
import bars3 from '@iconify-icons/heroicons-outline/bars-3'
import chat from '@iconify-icons/heroicons-outline/chat'
import chatAlt from '@iconify-icons/heroicons-outline/chat-alt'
import check from '@iconify-icons/heroicons-outline/check'
import checkCircle from '@iconify-icons/heroicons-outline/check-circle'
import chevronDown from '@iconify-icons/heroicons-outline/chevron-down'
import chevronDoubleLeft from '@iconify-icons/heroicons-outline/chevron-double-left'
import chevronLeft from '@iconify-icons/heroicons-outline/chevron-left'
import chevronRight from '@iconify-icons/heroicons-outline/chevron-right'
import chevronUp from '@iconify-icons/heroicons-outline/chevron-up'
import clipboard from '@iconify-icons/heroicons-outline/clipboard'
import clipboardCheck from '@iconify-icons/heroicons-outline/clipboard-check'
import clipboardList from '@iconify-icons/heroicons-outline/clipboard-list'
import clock from '@iconify-icons/heroicons-outline/clock'
import cog from '@iconify-icons/heroicons-outline/cog'
import collection from '@iconify-icons/heroicons-outline/collection'
import colorSwatch from '@iconify-icons/heroicons-outline/color-swatch'
import currencyEuro from '@iconify-icons/heroicons-outline/currency-euro'
import cursorClick from '@iconify-icons/heroicons-outline/cursor-click'
import documentIcon from '@iconify-icons/heroicons-outline/document'
import documentAdd from '@iconify-icons/heroicons-outline/document-add'
import documentDuplicate from '@iconify-icons/heroicons-outline/document-duplicate'
import documentDuplicateAlt from '@iconify-icons/heroicons-solid/document-duplicate'
import documentReport from '@iconify-icons/heroicons-outline/document-report'
import documentText from '@iconify-icons/heroicons-outline/document-text'
import gift from '@iconify-icons/heroicons-outline/gift'
import dotsHorizontal from '@iconify-icons/heroicons-outline/dots-horizontal'
import dotsVertical from '@iconify-icons/heroicons-outline/dots-vertical'
import shoppingBag from '@iconify-icons/heroicons-outline/shopping-bag'
import download from '@iconify-icons/heroicons-outline/download'
import emojiHappy from '@iconify-icons/heroicons-outline/emoji-happy'
import exclamationCircle from '@iconify-icons/heroicons-outline/exclamation-circle'
import externalLink from '@iconify-icons/heroicons-outline/external-link'
import eye from '@iconify-icons/heroicons-outline/eye'
import eyeOff from '@iconify-icons/heroicons-outline/eye-off'
import flag from '@iconify-icons/heroicons-outline/flag'
import filter from '@iconify-icons/heroicons-outline/filter'
import folder from '@iconify-icons/heroicons-outline/folder'
import folderAdd from '@iconify-icons/heroicons-outline/folder-add'
import folderOpen from '@iconify-icons/heroicons-outline/folder-open'
import hand from '@iconify-icons/heroicons-outline/hand-raised'
import heart from '@iconify-icons/heroicons-outline/heart'
import home from '@iconify-icons/heroicons-outline/home'
import identification from '@iconify-icons/heroicons-outline/identification'
import informationCircle from '@iconify-icons/heroicons-outline/information-circle'
import lightBulb from '@iconify-icons/heroicons-outline/light-bulb'
import lightningBolt from '@iconify-icons/heroicons-outline/lightning-bolt'
import link from '@iconify-icons/heroicons-outline/link'
import lockClosed from '@iconify-icons/heroicons-outline/lock-closed'
import logout from '@iconify-icons/heroicons-outline/logout'
import magnifierPlus from '@iconify-icons/heroicons-outline/magnifying-glass-plus'
import mail from '@iconify-icons/heroicons-outline/mail'
import menuAlt2 from '@iconify-icons/heroicons-outline/menu-alt-2'
import minus from '@iconify-icons/heroicons-outline/minus'
import minusCircle from '@iconify-icons/heroicons-outline/minus-circle'
import officeBuilding from '@iconify-icons/heroicons-outline/office-building'
import paperAirplane from '@iconify-icons/heroicons-outline/paper-airplane'
import pencil from '@iconify-icons/heroicons-outline/pencil'
import pencilAlt from '@iconify-icons/heroicons-outline/pencil-alt'
import phone from '@iconify-icons/heroicons-outline/phone'
import photograph from '@iconify-icons/heroicons-outline/photograph'
import playIcon from '@iconify-icons/heroicons-outline/play'
import plus from '@iconify-icons/heroicons-outline/plus'
import plusCircle from '@iconify-icons/heroicons-outline/plus-circle'
import presentationChartBar from '@iconify-icons/heroicons-outline/presentation-chart-bar'
import printer from '@iconify-icons/heroicons-outline/printer'
import puzzle from '@iconify-icons/heroicons-outline/puzzle-piece'
import questionMarkCircle from '@iconify-icons/heroicons-outline/question-mark-circle'
import refresh from '@iconify-icons/heroicons-outline/refresh'
import reply from '@iconify-icons/heroicons-outline/reply'
import search from '@iconify-icons/heroicons-outline/search'
import selector from '@iconify-icons/heroicons-outline/selector'
import squaresPlus from '@iconify-icons/heroicons-outline/squares-plus'
import star from '@iconify-icons/heroicons-outline/star'
import support from '@iconify-icons/heroicons-outline/support'
import switchVertical from '@iconify-icons/heroicons-outline/switch-vertical'
import share from '@iconify-icons/heroicons-outline/share'
import table from '@iconify-icons/heroicons-outline/table'
import tag from '@iconify-icons/heroicons-outline/tag'
import thumbUp from '@iconify-icons/heroicons-outline/thumb-up'
import trash from '@iconify-icons/heroicons-outline/trash'
import upload from '@iconify-icons/heroicons-outline/upload'
import user from '@iconify-icons/heroicons-outline/user'
import userAdd from '@iconify-icons/heroicons-outline/user-add'
import userGroup from '@iconify-icons/heroicons-outline/user-group'
import userRemove from '@iconify-icons/heroicons-outline/user-remove'
import users from '@iconify-icons/heroicons-outline/users'
import videoCamera from '@iconify-icons/heroicons-outline/video-camera'
import viewGrid from '@iconify-icons/heroicons-outline/view-grid'
import viewGridAdd from '@iconify-icons/heroicons-outline/view-grid-add'
import viewList from '@iconify-icons/heroicons-outline/view-list'
import x from '@iconify-icons/heroicons-outline/x'
import xCircle from '@iconify-icons/heroicons-outline/x-circle'

import exclamation from '@iconify-icons/heroicons-outline/exclamation'
import arrowUturnLeft from '@iconify-icons/heroicons-solid/arrow-uturn-left'
import arrowsRightLeft from '@iconify-icons/heroicons-solid/arrows-right-left'
import atSymbol from '@iconify-icons/heroicons-solid/at-symbol'
import eyeSolid from '@iconify-icons/heroicons-solid/eye'
import eyeOffSolid from '@iconify-icons/heroicons-solid/eye-off'
import globe from '@iconify-icons/heroicons-solid/globe'
import globeAlt from '@iconify-icons/heroicons-solid/globe-alt'
import listBulletSolid from '@iconify-icons/heroicons-solid/list-bullet'
import badgeCheck from '@iconify-icons/heroicons-solid/badge-check'
import IconifyIcon from '@iconify/vue'
import Vue from 'vue'

export const icons = [
  { key: 'arrow-circle-right', value: arrowCircleRight },
  { key: 'archive', value: archive },
  { key: 'arrow-circle-down', value: arrowCircleDown },
  { key: 'align-left', value: alignLeft },
  { key: 'align-center', value: alignCenter },
  { key: 'align-right', value: alignRight },
  { key: 'align-justify', value: alignJustify },
  { key: 'quote', value: Quote },
  { key: 'share', value: share },
  { key: 'cta-logo', value: ctaLogo },
  { key: 'currency-euro', value: currencyEuro },
  { key: 'cash', value: cash },
  { key: 'light-bulb', value: lightBulb },
  { key: 'user-group', value: userGroup },
  { key: 'linkedIn', value: linkedIn },
  { key: 'squares-plus', value: squaresPlus },
  { key: 'adjustments', value: adjustments },
  { key: 'hand', value: hand },
  { key: 'play', value: playIcon },
  { key: 'dot', value: dot },
  { key: 'star', value: star },
  { key: 'home', value: home },
  { key: 'loom', value: loom },
  { key: 'location-marker', value: locationMarker },
  { key: 'list-bullet', value: listBulletSolid },
  { key: 'youtube', value: youtube },
  { key: 'calendly', value: calendly },
  { key: 'pitch', value: pitch },
  { key: 'shopping-bag', value: shoppingBag },
  { key: 'typeform', value: typeform },
  { key: 'canva', value: canva },
  { key: 'view-grid-add', value: viewGridAdd },
  { key: 'pencil-alt', value: pencilAlt },
  { key: 'presentation-chart-bar', value: presentationChartBar },
  { key: 'chart-bar', value: chartBar },
  { key: 'bars-3', value: bars3 },
  { key: 'puzzle', value: puzzle },
  { key: 'magnifier-plus', value: magnifierPlus },
  { key: 'logout', value: logout },
  { key: 'folder-open', value: folderOpen },
  { key: 'folder-add', value: folderAdd },
  { key: 'users', value: users },
  { key: 'user', value: user },
  { key: 'filter', value: filter },
  { key: 'lightning-bolt', value: lightningBolt },
  { key: 'bell', value: bell },
  { key: 'chevron-down', value: chevronDown },
  { key: 'chevron-up', value: chevronUp },
  { key: 'chevron-right', value: chevronRight },
  { key: 'chevron-left', value: chevronLeft },
  { key: 'office-building', value: officeBuilding },
  { key: 'plus', value: plus },
  { key: 'plus-circle', value: plusCircle },
  { key: 'x', value: x },
  { key: 'table', value: table },
  { key: 'chat', value: chat },
  { key: 'chat-alt', value: chatAlt },
  { key: 'cog', value: cog },
  { key: 'user-add', value: userAdd },
  { key: 'user-remove', value: userRemove },
  { key: 'information-circle', value: informationCircle },
  { key: 'dots-vertical', value: dotsVertical },
  { key: 'dots-horizontal', value: dotsHorizontal },
  { key: 'eye', value: eye },
  { key: 'eye-off', value: eyeOff },
  { key: 'bookmark', value: bookmark },
  { key: 'emoji-happy', value: emojiHappy },
  { key: 'pencil', value: pencil },
  { key: 'trash', value: trash },
  { key: 'reply', value: reply },
  { key: 'paper-airplane', value: paperAirplane },
  { key: 'switch-vertical', value: switchVertical },
  { key: 'tag', value: tag },
  { key: 'calendar', value: calendar },
  { key: 'drag-handle', value: dragHandle },
  { key: 'format-text', value: formatText },
  { key: 'calculator', value: calculator },
  { key: 'folder', value: folder },
  { key: 'check', value: check },
  { key: 'arrow-down', value: arrowDown },
  { key: 'arrow-down-tray', value: arrowDownTray },
  { key: 'arrow-up', value: arrowUp },
  { key: 'arrow-uturn-left', value: arrowUturnLeft },
  { key: 'x-circle', value: xCircle },
  { key: 'document', value: documentIcon },
  { key: 'arrows-expand', value: arrowsExpand },
  { key: 'pin', value: pin },
  { key: 'upload', value: upload },
  { key: 'document-duplicate', value: documentDuplicate },
  { key: 'document-text', value: documentText },
  { key: 'gift', value: gift },
  { key: 'photograph', value: photograph },
  { key: 'printer', value: printer },
  { key: 'download', value: download },
  { key: 'external-link', value: externalLink },
  { key: 'toggle', value: toggle },
  { key: 'toggle-solid', value: toggleSolid },
  { key: 'minus', value: minus },
  { key: 'minus-circle', value: minusCircle },
  { key: 'link', value: link },
  { key: 'selector', value: selector },
  { key: 'clipboard', value: clipboard },
  { key: 'clipboard-check', value: clipboardCheck },
  { key: 'video-camera', value: videoCamera },
  { key: 'camera', value: camera },
  { key: 'collection', value: collection },
  { key: 'refresh', value: refresh },
  { key: 'exclamation-circle', value: exclamationCircle },
  { key: 'eye-solid', value: eyeSolid },
  { key: 'eye-off-solid', value: eyeOffSolid },
  { key: 'arrow-left', value: arrowLeft },
  { key: 'arrow-right', value: arrowRight },
  { key: 'lock-closed', value: lockClosed },
  { key: 'clipboard-list', value: clipboardList },
  { key: 'document-report', value: documentReport },
  { key: 'menu-alt-2', value: menuAlt2 },
  { key: 'bullet-list', value: bulletList },
  { key: 'checklist', value: checkList },
  { key: 'ordered-list', value: orderedList },
  { key: 'google-drive', value: googleDrive },
  { key: 'header-1', value: header1 },
  { key: 'header-2', value: header2 },
  { key: 'header-3', value: header3 },
  { key: 'mail', value: mail },
  { key: 'spaces', value: spaces },
  { key: 'search', value: search },
  { key: 'cursor-click', value: cursorClick },
  { key: 'identification', value: identification },
  { key: 'phone', value: phone },
  { key: 'document-add', value: documentAdd },
  { key: 'check-circle', value: checkCircle },
  { key: 'bar', value: bar },
  { key: 'view-list', value: viewList },
  { key: 'view-grid', value: viewGrid },
  { key: 'flag', value: flag },
  { key: 'at-symbol', value: atSymbol },
  { key: 'heart', value: heart },
  { key: 'switch-handle', value: switchHandle },
  { key: 'color-swatch', value: colorSwatch },
  { key: 'circle', value: circle },
  { key: 'support', value: support },
  { key: 'academic-cap', value: academicCap },
  { key: 'clock', value: clock },
  { key: 'arrow-right-left', value: arrowsRightLeft },
  { key: 'template', value: template },
  { key: 'globe', value: globe },
  { key: 'globe-alt', value: globeAlt },
  { key: 'question-mark-circle', value: questionMarkCircle },
  { key: 'thumb-up', value: thumbUp },
  { key: 'question-mark-circle', value: questionMarkCircle },
  { key: 'exclamation', value: exclamation },
  { key: 'documentDuplicateAlt', value: documentDuplicateAlt },
  { key: 'share', value: share },
  { key: 'badge-check', value: badgeCheck },
  { key: 'chevron-double-left', value: chevronDoubleLeft }
]

export default function initIconify() {
  icons.forEach(icon => {
    IconifyIcon.addIcon(icon.key, icon.value)
  })

  Vue.component('IconifyIcon', IconifyIcon)
}
