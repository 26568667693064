
<div class="sidebar" :class="size" ref="body" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
  <div v-if="isMobile" class="swipe-icon" @touchstart="handleTouchStart">
    <n-icon icon="minus" />
  </div>
  <header v-if="title">
    <NTitleBar
      :back-button="titleVariant === 'center'"
      v-bind="{ closable, showMenu, title: titleVariant === 'center' ? '' : title, backButtonLabel }"
      @back="$emit('back')"
      @close="$emit('close')"
      @openMenu="$emit('openMenu', $event)"
    />
    <div class="title-variant-center" v-if="titleVariant === 'center'">{{ title }}</div>
  </header>
  <slot />
</div>
