
<n-modal-background :closeOnEscape="false" :closeOnOutside="false" ref="bg" coverSnackbars>
  <n-modal-body class="getting-started-modal" width="m">
    <div class="main">
      <div class="content">
        <div class="messages">
          <header>{{ $t('framework.welcomeToNotch') }}</header>
          <p class="subtitle">
            {{ $t('framework.welcomeToNotchDescription') }}
          </p>
        </div>
        <p class="features-title">{{ $t('onboarding.highlightedFeatures') }}</p>
        <ul class="plan-features">
          <li class="check-item" v-for="(feature, index) in highlightedFeatures" :key="index">
            <n-icon class="icon-space" icon="check-circle" size="s" />
            <p class="feature-name">{{ feature.featureName }}</p>
            <p class="feature-description">{{ feature.featureDescription }}</p>
          </li>
        </ul>
        <footer>
          <n-button :label="$t('onboarding.createYourFirstSpace')" icon="arrow-right" @click="openTemplateManager" />
        </footer>
      </div>
      <div class="image">
        <img :src="gettingStartedImageLink" alt="Onboarding" />
      </div>
    </div>
  </n-modal-body>
</n-modal-background>
