
<component :is="tag" class="n-list" v-on="$listeners">
  <slot name="subheader">
    <div class="subheader" v-if="hasSubheader">{{ subheader }}</div>
  </slot>

  <div class="list">
    <slot />
  </div>
  <div v-if="divider" class="divider-wrapper"><n-divider /></div>
</component>
