export default class WhiteLabelSettings {
  logoFileId!: string
  logoUrl?: string
  bannerFileId?: string
  bannerUrl?: string
  bannerColor?: string
  bannerType?: BannerType
  primaryColor?: string
  secondaryColor?: string
  buttonShape?: ButtonShape
}

export enum ButtonShape {
  PILL = 'Pill',
  ROUNDED_RECTANGLE = 'Rounded rectangle',
  RECTANGLE = 'Rectangle'
}

export enum BannerType {
  IMAGE = 'IMAGE',
  COLOR = 'COLOR',
  NONE = 'NONE'
}
