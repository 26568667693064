
<div class="text-wrapper" :class="{ centered: centered }" @click="onClick">
  <p
    ref="input"
    :class="[
      'input',
      size,
      textColor,
      {
        'multi-line': multiLine,
        'single-line': !multiLine,
        'never-break': neverBreak
      }
    ]"
    :contentEditable="editable"
    @input="onInput"
    @blur="onBlur"
    @keypress="$emit('keypress', $event)"
    @focus="$emit('focus', $event)"
    @keydown="onKeyDown"
    @mousedown="onMouseDown"
    @paste="onPaste"
  />
  <p :class="['placeholder', size]" v-if="isPlaceholderVisible">{{ placeholder }}</p>
</div>
