import ApiClient, { FILE_TIMEOUT } from '@/util/ApiClient'
import { defineStore } from 'pinia'
import Team from '../models/Team'
import { DomainResponse, WhitelistedDomain } from '../models/Domain'

export type TeamState = {
  currentTeam: Team | null
}

export const useTeamStore = defineStore('team', {
  state: (): TeamState => ({
    currentTeam: null
  }),
  actions: {
    async fetchCurrentTeam(): Promise<Team> {
      const team = (await ApiClient.get<Team>(`/teams/current`)).data
      this.currentTeam = team
      return team
    },
    async updateCurrentTeam(updatedTeam: Team): Promise<Team> {
      const team = (await ApiClient.put<Team>(`/teams/current`, updatedTeam)).data
      this.currentTeam = team
      return team
    },

    async uploadTeamLogo(file: File) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/teams/current/teamlogo`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const team = response.data as Team
      this.currentTeam = team
      return team
    },
    async uploadTeamBanner(file: File) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await ApiClient({
        method: 'post',
        url: `/teams/current/teambanner`,
        data: formData,
        timeout: FILE_TIMEOUT
      })
      const team = response.data as Team
      this.currentTeam = team
      return team
    },
    async deleteTeamLogo() {
      const team = (await ApiClient.delete<Team>(`/teams/current/teamlogo`)).data
      this.currentTeam = team
      return team
    },
    async deleteTeamBanner() {
      const team = (await ApiClient.delete<Team>(`/teams/current/teambanner`)).data
      this.currentTeam = team
      return team
    },
    async getSubscriptionPortalLink(): Promise<string> {
      return (await ApiClient.get(`/teams/current/subscription-portal-link`)).data
    },
    async getCurrentCustomDomain(): Promise<WhitelistedDomain> {
      const { data } = await ApiClient.get<WhitelistedDomain>('/teams/current/domain')
      return data
    },
    async checkCurrentCustomDomain(currentDomain?: String): Promise<boolean> {
      const { data } = await ApiClient.get<boolean>('/teams/current/domain/check', { params: { currentDomain } })
      return data
    },
    async getCustomDomainStatus(hostName: String): Promise<DomainResponse> {
      const { data } = await ApiClient.get<DomainResponse>(`/teams/current/domain/availability/${hostName}`)
      return data
    },
    async createCertificate(hostName: String): Promise<DomainResponse> {
      const { data } = await ApiClient.post<DomainResponse>(`/teams/current/domain/certificate/${hostName}`)
      return data
    },
    async createDistribution(hostName: String): Promise<DomainResponse> {
      const { data } = await ApiClient.post<DomainResponse>(`/teams/current/domain/distribution/${hostName}`)
      return data
    },
    async deleteCustomDomain(hostName: string): Promise<DomainResponse> {
      const { data } = await ApiClient.delete(`/teams/current/domain/delete/${hostName}`)
      return data
    },
    setCurrentTeam(team: Team) {
      this.currentTeam = team
    }
  }
})

export type TeamStore = ReturnType<typeof useTeamStore>
