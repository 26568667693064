
<div class="items">
  <div
    v-for="(item, index) in items"
    :key="index"
    class="item"
    :class="{ 'is-selected': index === selectedIndex }"
    @mouseenter="onMouseEnter(index)"
    @click="selectItem(index)"
  >
    <UserInfo :user="item" showName />
  </div>
</div>
