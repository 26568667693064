import { Extension } from '@tiptap/core'
import { history, redo, undo } from './prosemirror-history'

export interface HistoryOptions {
  /**
   * The amount of history events that are collected before the oldest events are discarded.
   */
  depth: number

  /**
   * The delay (in milliseconds) between changes after which a new group should be started.
   */
  newGroupDelay: number
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    history: {
      /**
       * Undo recent changes
       * @example editor.commands.undo()
       */
      undo: () => ReturnType
      /**
       * Reapply reverted changes
       * @example editor.commands.redo()
       */
      redo: () => ReturnType
    }
  }
}

export const History = Extension.create<HistoryOptions>({
  name: 'history',

  addOptions() {
    return {
      depth: 10,
      newGroupDelay: 500
    }
  },

  addCommands() {
    return {
      undo:
        () =>
        ({ state, dispatch }) => {
          return undo(state, dispatch)
        },
      redo:
        () =>
        ({ state, dispatch }) => {
          return redo(state, dispatch)
        }
    }
  },

  addProseMirrorPlugins() {
    return [history(this.options)]
  }
})
