import RopeSequence from 'rope-sequence'
import { Mapping, Step, StepMap, Transform } from 'prosemirror-transform'
import {
  Plugin,
  Command,
  PluginKey,
  EditorState,
  Transaction,
  SelectionBookmark,
  TextSelection,
  NodeSelection,
  AllSelection,
  Selection
} from 'prosemirror-state'
import { Node } from 'prosemirror-model'
import { Mappable } from 'prosemirror-transform'

// ProseMirror's history isn't simply a way to roll back to a previous
// state, because ProseMirror supports applying changes without adding
// them to the history (for example during collaboration).
//
// To this end, each 'Branch' (one for the undo history and one for
// the redo history) keeps an array of 'Items', which can optionally
// hold a step (an actual undoable change), and always hold a position
// map (which is needed to move changes below them to apply to the
// current document).
//
// An item that has both a step and a selection bookmark is the start
// of an 'event' — a group of changes that will be undone or redone at
// once. (It stores only the bookmark, since that way we don't have to
// provide a document until the selection is actually applied, which
// is useful when compressing.)

// Used to schedule history compression
const max_empty_items = 500

class TextBookmark implements SelectionBookmark {
  constructor(readonly anchor: number, readonly head: number) {}

  map(mapping: Mappable): SelectionBookmark {
    return new TextBookmark(mapping.map(this.anchor), mapping.map(this.head))
  }

  resolve(doc: Node) {
    return TextSelection.between(doc.resolve(this.anchor), doc.resolve(this.head))
  }
}

class NodeBookmark implements SelectionBookmark {
  constructor(readonly anchor: number) {}

  map(mapping: Mappable): SelectionBookmark {
    const { deleted, pos } = mapping.mapResult(this.anchor)
    return deleted ? new TextBookmark(pos, pos) : new NodeBookmark(pos)
  }

  resolve(doc: Node) {
    const $pos = doc.resolve(this.anchor),
      node = $pos.nodeAfter
    if (node && NodeSelection.isSelectable(node)) return new NodeSelection($pos)
    return Selection.near($pos)
  }
}

class AllBookmark implements SelectionBookmark {
  map() {
    return this
  }
  resolve(doc: Node) {
    return new AllSelection(doc)
  }
}

function arrayToRopeSequence<T>(array: T[]) {
  return RopeSequence.from(array)
}

function stepMapToJSON(stepMap: StepMap) {
  return {
    ranges: (stepMap as any).ranges,
    inverted: (stepMap as any).inverted
  }
}

function stepMapFromJSON(json: any) {
  return new StepMap(json.ranges, json.inverted)
}

function getClassType(obj: SelectionBookmark): string {
  if ((obj as any).anchor && (obj as any).head) {
    return 'TextBookmark'
  } else if ((obj as any).anchor) {
    return 'NodeBookmark'
  } else {
    return 'AllBookmark'
  }
}

function selectionBookmarkToJSON(selectionBookmark: SelectionBookmark) {
  return {
    type: getClassType(selectionBookmark),
    anchor: (selectionBookmark as any).anchor,
    head: (selectionBookmark as any).head
  }
}

function selectionBookmarkFromJSON(json: any) {
  if (json.type === 'TextBookmark') {
    return new TextBookmark(json.anchor, json.head)
  } else if (json.type === 'NodeBookmark') {
    return new NodeBookmark(json.anchor)
  } else if (json.type === 'AllBookmark') {
    return new AllBookmark()
  }
}

class Branch {
  constructor(readonly items: RopeSequence<Item>, readonly eventCount: number) {}

  toJSON() {
    return {
      items: this.items.map((i: Item) => i.toJSON()),
      eventCount: this.eventCount
    }
  }

  static fromJSON(schema: any, json: any) {
    return new Branch(arrayToRopeSequence(json.items.map((i: Item) => Item.fromJSON(schema, i))), json.eventCount)
  }

  // Pop the latest event off the branch's history and apply it
  // to a document transform.
  popEvent(state: EditorState, preserveItems: boolean) {
    if (this.eventCount == 0) return null

    let end = this.items.length
    for (; ; end--) {
      const next = this.items.get(end - 1)
      if (next.selection) {
        --end
        break
      }
    }

    let remap: Mapping | undefined, mapFrom: number | undefined
    if (preserveItems) {
      remap = this.remapping(end, this.items.length)
      mapFrom = remap.maps.length
    }
    const transform = state.tr
    let selection: SelectionBookmark | undefined, remaining: Branch | undefined
    const addAfter: Item[] = [],
      addBefore: Item[] = []

    this.items.forEach(
      (item, i) => {
        if (!item.step) {
          if (!remap) {
            remap = this.remapping(end, i + 1)
            mapFrom = remap.maps.length
          }
          mapFrom!--
          addBefore.push(item)
          return
        }

        if (remap) {
          addBefore.push(new Item(item.map))
          const step = item.step.map(remap.slice(mapFrom))
          let map

          if (step && transform.maybeStep(step).doc) {
            map = transform.mapping.maps[transform.mapping.maps.length - 1]
            addAfter.push(new Item(map, undefined, undefined, addAfter.length + addBefore.length))
          }
          mapFrom!--
          if (map) remap.appendMap(map, mapFrom)
        } else {
          transform.maybeStep(item.step)
        }

        if (item.selection) {
          selection = remap ? item.selection.map(remap.slice(mapFrom)) : item.selection
          remaining = new Branch(
            this.items.slice(0, end).append(addBefore.reverse().concat(addAfter)),
            this.eventCount - 1
          )
          return false
        }
      },
      this.items.length,
      0
    )

    return { remaining: remaining!, transform, selection: selection! }
  }

  // Create a new branch with the given transform added.
  addTransform(
    transform: Transform,
    selection: SelectionBookmark | undefined,
    histOptions: Required<HistoryOptions>,
    preserveItems: boolean
  ) {
    const newItems = []
    let eventCount = this.eventCount
    let oldItems = this.items,
      lastItem = !preserveItems && oldItems.length ? oldItems.get(oldItems.length - 1) : null

    for (let i = 0; i < transform.steps.length; i++) {
      const step = transform.steps[i].invert(transform.docs[i])
      let item = new Item(transform.mapping.maps[i], step, selection),
        merged
      if ((merged = lastItem && lastItem.merge(item))) {
        item = merged
        if (i) newItems.pop()
        else oldItems = oldItems.slice(0, oldItems.length - 1)
      }
      newItems.push(item)
      if (selection) {
        eventCount++
        selection = undefined
      }
      if (!preserveItems) lastItem = item
    }
    const overflow = eventCount - histOptions.depth
    if (overflow > DEPTH_OVERFLOW) {
      oldItems = cutOffEvents(oldItems, overflow)
      eventCount -= overflow
    }
    return new Branch(oldItems.append(newItems), eventCount)
  }

  remapping(from: number, to: number): Mapping {
    const maps = new Mapping()
    this.items.forEach(
      (item, i) => {
        const mirrorPos =
          item.mirrorOffset != null && i - item.mirrorOffset >= from ? maps.maps.length - item.mirrorOffset : undefined
        maps.appendMap(item.map, mirrorPos)
      },
      from,
      to
    )
    return maps
  }

  addMaps(array: readonly StepMap[]) {
    if (this.eventCount == 0) return this
    return new Branch(this.items.append(array.map(map => new Item(map))), this.eventCount)
  }

  // When the collab module receives remote changes, the history has
  // to know about those, so that it can adjust the steps that were
  // rebased on top of the remote changes, and include the position
  // maps for the remote changes in its array of items.
  rebased(rebasedTransform: Transform, rebasedCount: number) {
    if (!this.eventCount) return this

    const rebasedItems: Item[] = [],
      start = Math.max(0, this.items.length - rebasedCount)

    const mapping = rebasedTransform.mapping
    let newUntil = rebasedTransform.steps.length
    let eventCount = this.eventCount
    this.items.forEach(item => {
      if (item.selection) eventCount--
    }, start)

    let iRebased = rebasedCount
    this.items.forEach(item => {
      const pos = mapping.getMirror(--iRebased)
      if (pos == null) return
      newUntil = Math.min(newUntil, pos)
      const map = mapping.maps[pos]
      if (item.step) {
        const step = rebasedTransform.steps[pos].invert(rebasedTransform.docs[pos])
        const selection = item.selection && item.selection.map(mapping.slice(iRebased + 1, pos))
        if (selection) eventCount++
        rebasedItems.push(new Item(map, step, selection))
      } else {
        rebasedItems.push(new Item(map))
      }
    }, start)

    const newMaps = []
    for (let i = rebasedCount; i < newUntil; i++) newMaps.push(new Item(mapping.maps[i]))
    const items = this.items.slice(0, start).append(newMaps).append(rebasedItems)
    let branch = new Branch(items, eventCount)

    if (branch.emptyItemCount() > max_empty_items) branch = branch.compress(this.items.length - rebasedItems.length)
    return branch
  }

  emptyItemCount() {
    let count = 0
    this.items.forEach(item => {
      if (!item.step) count++
    })
    return count
  }

  // Compressing a branch means rewriting it to push the air (map-only
  // items) out. During collaboration, these naturally accumulate
  // because each remote change adds one. The `upto` argument is used
  // to ensure that only the items below a given level are compressed,
  // because `rebased` relies on a clean, untouched set of items in
  // order to associate old items with rebased steps.
  compress(upto = this.items.length) {
    const remap = this.remapping(0, upto)
    let mapFrom = remap.maps.length
    const items: Item[] = []
    let events = 0
    this.items.forEach(
      (item, i) => {
        if (i >= upto) {
          items.push(item)
          if (item.selection) events++
        } else if (item.step) {
          const step = item.step.map(remap.slice(mapFrom)),
            map = step && step.getMap()
          mapFrom--
          if (map) remap.appendMap(map, mapFrom)
          if (step) {
            const selection = item.selection && item.selection.map(remap.slice(mapFrom))
            if (selection) events++
            const newItem = new Item(map!.invert(), step, selection)
            const last = items.length - 1
            let merged
            if ((merged = items.length && items[last].merge(newItem))) items[last] = merged
            else items.push(newItem)
          }
        } else if (item.map) {
          mapFrom--
        }
      },
      this.items.length,
      0
    )
    return new Branch(RopeSequence.from(items.reverse()), events)
  }

  static empty = new Branch(RopeSequence.empty, 0)
}

function cutOffEvents(items: RopeSequence<Item>, n: number) {
  let cutPoint: number | undefined
  items.forEach((item, i) => {
    if (item.selection && n-- == 0) {
      cutPoint = i
      return false
    }
  })
  return items.slice(cutPoint!)
}

class Item {
  constructor(
    // The (forward) step map for this item.
    readonly map: StepMap,
    // The inverted step
    readonly step?: Step,
    // If this is non-null, this item is the start of a group, and
    // this selection is the starting selection for the group (the one
    // that was active before the first step was applied)
    readonly selection?: SelectionBookmark,
    // If this item is the inverse of a previous mapping on the stack,
    // this points at the inverse's offset
    readonly mirrorOffset?: number
  ) {}

  toJSON() {
    return {
      map: stepMapToJSON(this.map),
      step: this.step ? this.step.toJSON() : undefined,
      selection: this.selection ? selectionBookmarkToJSON(this.selection) : undefined,
      mirrorOffset: this.mirrorOffset
    }
  }

  static fromJSON(schema: any, json: any) {
    return new Item(
      stepMapFromJSON(json.map),
      json.step ? Step.fromJSON(schema, json.step) : undefined,
      json.selection ? selectionBookmarkFromJSON(json.selection) : undefined,
      json.mirrorOffset
    )
  }

  merge(other: Item) {
    if (this.step && other.step && !other.selection) {
      const step = other.step.merge(this.step)
      if (step) return new Item(step.getMap().invert(), step, this.selection)
    }
  }
}

// The value of the state field that tracks undo/redo history for that
// state. Will be stored in the plugin state when the history plugin
// is active.
class HistoryState {
  constructor(
    readonly done: Branch,
    readonly undone: Branch,
    readonly prevRanges: readonly number[] | null,
    readonly prevTime: number,
    readonly prevComposition: number
  ) {}

  toJSON() {
    return {
      done: this.done.toJSON(),
      undone: this.undone.toJSON(),
      prevRanges: this.prevRanges,
      prevTime: this.prevTime,
      prevComposition: this.prevComposition
    }
  }

  static fromJSON(schema: any, json: any) {
    return new HistoryState(
      Branch.fromJSON(schema, json.done),
      Branch.fromJSON(schema, json.undone),
      json.prevRanges,
      json.prevTime,
      json.prevComposition
    )
  }
}

const DEPTH_OVERFLOW = 20

// Record a transformation in undo history.
function applyTransaction(
  history: HistoryState,
  state: EditorState,
  tr: Transaction,
  options: Required<HistoryOptions>
) {
  const historyTr = tr.getMeta(historyKey)
  let rebased
  if (historyTr) return historyTr.historyState

  if (tr.getMeta(closeHistoryKey)) history = new HistoryState(history.done, history.undone, null, 0, -1)

  const appended = tr.getMeta('appendedTransaction')

  if (tr.steps.length == 0) {
    return history
  } else if (appended && appended.getMeta(historyKey)) {
    if (appended.getMeta(historyKey).redo)
      return new HistoryState(
        history.done.addTransform(tr, undefined, options, mustPreserveItems(state)),
        history.undone,
        rangesFor(tr.mapping.maps[tr.steps.length - 1]),
        history.prevTime,
        history.prevComposition
      )
    else
      return new HistoryState(
        history.done,
        history.undone.addTransform(tr, undefined, options, mustPreserveItems(state)),
        null,
        history.prevTime,
        history.prevComposition
      )
  } else if (tr.getMeta('addToHistory') !== false && !(appended && appended.getMeta('addToHistory') === false)) {
    // Group transforms that occur in quick succession into one event.
    const composition = tr.getMeta('composition')
    const newGroup =
      history.prevTime == 0 ||
      (!appended &&
        history.prevComposition != composition &&
        (history.prevTime < (tr.time || 0) - options.newGroupDelay || !isAdjacentTo(tr, history.prevRanges!)))
    const prevRanges = appended
      ? mapRanges(history.prevRanges!, tr.mapping)
      : rangesFor(tr.mapping.maps[tr.steps.length - 1])
    const newState = new HistoryState(
      history.done.addTransform(
        tr,
        newGroup ? state.selection.getBookmark() : undefined,
        options,
        mustPreserveItems(state)
      ),
      Branch.empty,
      prevRanges,
      tr.time,
      composition == null ? history.prevComposition : composition
    )
    if (newGroup) {
      tr.setMeta('pushToUndoStack', true)
    }
    return newState
  } else if ((rebased = tr.getMeta('rebased'))) {
    // Used by the collab module to tell the history that some of its
    // content has been rebased.
    return new HistoryState(
      history.done.rebased(tr, rebased),
      history.undone.rebased(tr, rebased),
      mapRanges(history.prevRanges!, tr.mapping),
      history.prevTime,
      history.prevComposition
    )
  } else {
    return new HistoryState(
      history.done.addMaps(tr.mapping.maps),
      history.undone.addMaps(tr.mapping.maps),
      mapRanges(history.prevRanges!, tr.mapping),
      history.prevTime,
      history.prevComposition
    )
  }
}

function isAdjacentTo(transform: Transform, prevRanges: readonly number[]) {
  if (!prevRanges) return false
  if (!transform.docChanged) return true
  let adjacent = false
  transform.mapping.maps[0].forEach((start, end) => {
    for (let i = 0; i < prevRanges.length; i += 2)
      if (start <= prevRanges[i + 1] && end >= prevRanges[i]) adjacent = true
  })
  return adjacent
}

function rangesFor(map: StepMap) {
  const result: number[] = []
  map.forEach((_from, _to, from, to) => result.push(from, to))
  return result
}

function mapRanges(ranges: readonly number[], mapping: Mapping) {
  if (!ranges) return null
  const result = []
  for (let i = 0; i < ranges.length; i += 2) {
    const from = mapping.map(ranges[i], 1),
      to = mapping.map(ranges[i + 1], -1)
    if (from <= to) result.push(from, to)
  }
  return result
}

// Apply the latest event from one branch to the document and shift the event
// onto the other branch.
function histTransaction(
  history: HistoryState,
  state: EditorState,
  dispatch: (tr: Transaction) => void,
  redo: boolean
) {
  const preserveItems = mustPreserveItems(state)
  const histOptions = (historyKey.get(state)!.spec as any).config as Required<HistoryOptions>
  const pop = (redo ? history.undone : history.done).popEvent(state, preserveItems)
  if (!pop) return

  const selection = pop.selection!.resolve(pop.transform.doc)
  const added = (redo ? history.done : history.undone).addTransform(
    pop.transform,
    state.selection.getBookmark(),
    histOptions,
    preserveItems
  )

  const newHist = new HistoryState(redo ? added : pop.remaining, redo ? pop.remaining : added, null, 0, -1)
  dispatch(pop.transform.setSelection(selection).setMeta(historyKey, { redo, historyState: newHist }).scrollIntoView())
}

let cachedPreserveItems = false,
  cachedPreserveItemsPlugins: readonly Plugin[] | null = null
// Check whether any plugin in the given state has a
// `historyPreserveItems` property in its spec, in which case we must
// preserve steps exactly as they came in, so that they can be
// rebased.
function mustPreserveItems(state: EditorState) {
  const plugins = state.plugins
  if (cachedPreserveItemsPlugins != plugins) {
    cachedPreserveItems = false
    cachedPreserveItemsPlugins = plugins
    for (let i = 0; i < plugins.length; i++)
      if ((plugins[i].spec as any).historyPreserveItems) {
        cachedPreserveItems = true
        break
      }
  }
  return cachedPreserveItems
}

/// Set a flag on the given transaction that will prevent further steps
/// from being appended to an existing history event (so that they
/// require a separate undo command to undo).
export function closeHistory(tr: Transaction) {
  return tr.setMeta(closeHistoryKey, true)
}

export const historyKey = new PluginKey('history')
const closeHistoryKey = new PluginKey('closeHistory')

interface HistoryOptions {
  /// The amount of history events that are collected before the
  /// oldest events are discarded. Defaults to 100.
  depth?: number

  /// The delay between changes after which a new group should be
  /// started. Defaults to 500 (milliseconds). Note that when changes
  /// aren't adjacent, a new group is always started.
  newGroupDelay?: number
}

/// Returns a plugin that enables the undo history for an editor. The
/// plugin will track undo and redo stacks, which can be used with the
/// [`undo`](#history.undo) and [`redo`](#history.redo) commands.
///
/// You can set an `"addToHistory"` [metadata
/// property](#state.Transaction.setMeta) of `false` on a transaction
/// to prevent it from being rolled back by undo.
export function history(config: HistoryOptions = {}): Plugin {
  config = { depth: config.depth || 100, newGroupDelay: config.newGroupDelay || 500 }

  return new Plugin({
    key: historyKey,

    state: {
      init() {
        return new HistoryState(Branch.empty, Branch.empty, null, 0, -1)
      },
      apply(tr, hist, state) {
        return applyTransaction(hist, state, tr, config as Required<HistoryOptions>)
      },
      toJSON(value: HistoryState) {
        return value.toJSON()
      },
      fromJSON(config, value, _state) {
        return HistoryState.fromJSON(config.schema, value)
      }
    },

    config,

    props: {
      handleDOMEvents: {
        beforeinput(view, e: Event) {
          const inputType = (e as InputEvent).inputType
          const command = inputType == 'historyUndo' ? undo : inputType == 'historyRedo' ? redo : null
          if (!command) return false
          e.preventDefault()
          return command(view.state, view.dispatch)
        }
      }
    }
  })
}

/// A command function that undoes the last change, if any.
export const undo: Command = (state, dispatch) => {
  const hist = historyKey.getState(state)
  if (!hist || hist.done.eventCount == 0) return false
  if (dispatch) histTransaction(hist, state, dispatch, false)
  return true
}

/// A command function that redoes the last undone change, if any.
export const redo: Command = (state, dispatch) => {
  const hist = historyKey.getState(state)
  if (!hist || hist.undone.eventCount == 0) return false
  if (dispatch) histTransaction(hist, state, dispatch, true)
  return true
}

/// The amount of undoable events available in a given state.
export function undoDepth(state: EditorState) {
  const hist = historyKey.getState(state)
  return hist ? hist.done.eventCount : 0
}

/// The amount of redoable events available in a given editor state.
export function redoDepth(state: EditorState) {
  const hist = historyKey.getState(state)
  return hist ? hist.undone.eventCount : 0
}
