
<n-simple-modal-body :title="title" :closable="false" @close="close()">
  <main><slot /></main>
  <template #rightFooter>
    <n-button
      v-if="variant === 'confirm'"
      variant="secondary"
      @click="
        $emit('cancel')
        close()
      "
      :label="$t('general.cancel')"
    />
    <n-button
      @click="
        $emit('confirm')
        close()
      "
      :label="confirmButtonText || $t('general.ok')"
      :icon="confirmButtonIcon"
    />
  </template>
</n-simple-modal-body>
