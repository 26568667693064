
<div class="n-slider">
  <n-progress-bar class="progress-bar" :percentage="value" />
  <input
    type="range"
    min="0"
    max="100"
    :value="value"
    @input="$emit('change', Number.parseInt($event.target.value))"
  />
</div>
