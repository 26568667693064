
<div class="user-info-container" v-if="user">
  <UserAvatar :user="user" :size="size" />
  <div
    v-if="showName"
    class="user-info"
    :class="{
      xl: size === 'xl',
      m: size === 'm'
    }"
  >
    <div
      v-if="showName"
      :class="{
        '--is-pending': isPending,
        thick: thickName
      }"
      class="username-label"
    >
      {{ userDisplayName }}
    </div>
    <div
      v-if="showEmail && user.email && (!isPending || !showPending)"
      class="email subtitle-label"
      :class="{
        thick: thickEmail
      }"
    >
      {{ user.email }}
    </div>
    <div v-if="showJobTitle && user.jobTitle && !isPending" class="job-title subtitle-label">
      {{ user.jobTitle ?? '' }}
    </div>
    <div v-if="isPending && showPending" class="invitation subtitle-label">
      {{ $t('user.pendingInvitation') }}
    </div>
  </div>
</div>
