import { ElementType } from '@/modules/elements/models/Element'
import EmbedLinkMenu from '@/modules/global/components/EmbedLinkMenu.vue'
import { Command, Editor, Node } from '@tiptap/core'
import { VueRenderer } from '@tiptap/vue-2'
import { Fragment, Node as ProseMirrorNode } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import tippy from 'tippy.js'
import { EmbedLinkMatch, findEmbedLink } from './embed-link-parser'
import { AddElementData } from './tiptap-commands'
import ApiClient from '@/util/ApiClient'
import { EmbedType } from '@/modules/elements/models/Embed'

export interface IframeOptions {
  parent: Vue | null
  replaceElementHandler: ((data: AddElementData) => void) | null
  allowFullscreen: boolean
  HTMLAttributes: {
    [key: string]: any
  }
}

declare module '@tiptap/core' {
  interface Commands {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string }) => Command
    }
  }
}

export default Node.create({
  name: 'iframe',
  group: 'block',

  addOptions() {
    return <IframeOptions>{
      parent: null,
      replaceElementHandler: null,
      allowFullscreen: true,
      HTMLAttributes: {
        class: 'iframe-wrapper'
      }
    }
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      frameborder: {
        default: 0
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => {
          return {
            allowfullscreen: this.options.allowFullscreen
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]]
  },

  addCommands() {
    return {
      setIframe:
        (options: { src: string }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create(options)

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }

          return true
        }
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('embedLinkReplacerPasteRule'),
        props: {
          handlePaste: (_view, event, slice) => {
            const embedLink = findEmbedLinkInChildren(slice.content)
            if (embedLink) {
              const parentElement = (event.target as any).parentElement
              setTimeout(() => {
                openEmbedLinkMenu(
                  this.options.replaceElementHandler,
                  this.options.parent,
                  parentElement,
                  this.editor,
                  embedLink
                )
              }, 10)
            }
            return false
          }
        }
      })
    ]
  }
})

function findEmbedLinkInChildren(childrenContainer: ProseMirrorNode | Fragment): EmbedLinkMatch | null {
  let foundLink = null
  childrenContainer.forEach(n => {
    const embedLink = findEmbededLink(n)
    if (embedLink) {
      foundLink = embedLink
    }
  })
  return foundLink
}

function findEmbededLink(node: ProseMirrorNode): EmbedLinkMatch | null {
  if (node.isText) {
    return findEmbedLink(node.text!!)
  } else {
    return findEmbedLinkInChildren(node)
  }
}

function openEmbedLinkMenu(
  replaceElementHandler: ((data: AddElementData) => void) | null,
  parent: Vue | null,
  parentElement: HTMLElement,
  editor: Editor,
  embedLink: EmbedLinkMatch
) {
  const component = new VueRenderer(EmbedLinkMenu, { parent })

  const popup = tippy('body', {
    getReferenceClientRect: () => (parentElement.lastChild as any)?.getBoundingClientRect(),
    appendTo: 'parent',
    content: component.element,
    aria: {
      content: 'auto',
      expanded: 'auto'
    },
    showOnCreate: true,
    interactive: true,
    hideOnClick: true,
    theme: 'none',
    trigger: 'manual',
    placement: 'bottom-end'
  })

  const ref = component.ref as any
  ref.onSelect = (option: string) => {
    if (popup[0]) {
      popup[0].hide()
    }
    handleSelectEmbedMenu(replaceElementHandler, option, editor, embedLink)
  }
}

function handleSelectEmbedMenu(
  replaceElementHandler: ((data: AddElementData) => void) | null,
  option: string,
  editor: Editor,
  embedLink: EmbedLinkMatch
) {
  if (option !== 'bookmark' && option !== 'embed') {
    editor.chain().focus().insertContent(' ').run()
  } else {
    const lastMatchFrom = findLastMatch(editor.state.doc, embedLink.original)
    const lastMatchTo = lastMatchFrom + embedLink.original.length
    if (lastMatchFrom >= 0) {
      if (replaceElementHandler) {
        if (option === 'embed') {
          const nonSupportedTypes = [EmbedType.GENERIC, EmbedType.IFRAME]
          if (nonSupportedTypes.includes(embedLink.type)) {
            ApiClient.post(`/utilities/analyze-embed-url`, { url: embedLink.original }).then(embeddedCode => {
              const hasError = embeddedCode.data?.error || false
              if (!hasError) {
                replaceElementHandler({
                  type: ElementType.embed,
                  embedType: EmbedType.IFRAMELY,
                  embedHtml: embeddedCode.data.html
                })
              } else {
                replaceElementHandler({ type: ElementType.embed, embedType: embedLink.type, embedUrl: embedLink.embed })
              }
              editor.chain().focus().deleteRange({ from: lastMatchFrom, to: lastMatchTo }).run()
            })
          } else {
            replaceElementHandler({ type: ElementType.embed, embedType: embedLink.type, embedUrl: embedLink.embed })
            editor.chain().focus().deleteRange({ from: lastMatchFrom, to: lastMatchTo }).run()
          }
        } else {
          replaceElementHandler({ type: ElementType.linkbookmark, embedUrl: embedLink.original })
          editor.chain().focus().deleteRange({ from: lastMatchFrom, to: lastMatchTo }).run()
        }
      }
    }
  }
}

function findLastMatch(node: ProseMirrorNode, searchTerm: string): number {
  if (node.isText || node.isTextblock) {
    const index = node.textContent ? node.textContent.lastIndexOf(searchTerm) : -1
    return index
  } else {
    const results: number[] = []
    node.content.forEach(node => {
      results.push(findLastMatch(node, searchTerm))
    })
    for (let i = results.length - 1; i >= 0; i--) {
      const result = results[i]
      if (result >= 0) {
        let sizeBeforeMatch = 0
        for (let j = 0; j < i; j++) {
          sizeBeforeMatch += node.content.child(j).nodeSize
        }
        // If result was found in this tree, add all the sizes of the nodes before, plus one for the node start token
        return sizeBeforeMatch + result + 1
      }
    }

    return -1
  }
}
