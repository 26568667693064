export default class Subscription {
  id!: string
  plan!: SubscriptionPlan
  seats!: number
  billingCycle!: BillingCycle
  isCustomPricing!: boolean
  freeTrialStart!: String
  freeTrialEnd!: String
}

export enum SubscriptionPlan {
  FREE = 'FREE',
  START = 'START',
  GROWTH = 'GROWTH'
}

export enum BillingCycle {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum PlanTier {
  TIER1 = 'TIER1',
  TIER2 = 'TIER2',
  TIER3 = 'TIER3',
  NOTCH_TIER1_V2 = 'NOTCH_TIER1_V2',
  NOTCH_TIER2_V2 = 'NOTCH_TIER2_V2',
  NOTCH_TIER3_V2 = 'NOTCH_TIER3_V2',
  NOTCH_TIER4_V2 = 'NOTCH_TIER4_V2'
}
