import { SortDirection } from './SortDirection'
import TableColumn from './TableColumn'
import TableRow from './TableRow'

export default class Table {
  id!: string
  columns!: TableColumn[]
  rows!: TableRow[]
  createdBy!: string
  createdAt!: string
  sortByColumn!: string | undefined
  sortDirection!: SortDirection | undefined
  fullWidth = false
  isBorderless = false
  properties!: DeprecatedProperty[]
  deprecatedTotal!: string
}

export class DeprecatedProperty {
  id!: string
  position!: number
  name!: string
  value!: string
}
