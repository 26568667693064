
<div class="container">
  <div class="back-button" v-if="backButton" @click="$emit('back')">
    <div class="icon-button">
      <n-icon icon="arrow-left" size="s" />
    </div>
    <div>{{ backButtonLabel }}</div>
  </div>
  <div
    class="titlebar-title"
    :class="{
      'with-back-button': backButton,
      'has-subtitle': !!subtitle,
      'with-close-button': closable,
      m: titleSize === 'm'
    }"
  >
    {{ title }}
  </div>
  <div class="subtitle" v-if="subtitle">{{ subtitle }}</div>
  <div v-if="showMenu" class="menu-button" ref="menuButton" @click="$emit('openMenu', $refs.menuButton)">
    <div class="icon-button">
      <n-icon icon="dots-horizontal" size="s" />
    </div>
  </div>
  <div class="close-button">
    <div v-if="closable" class="icon-button" @click="$emit('close')">
      <n-icon icon="x" size="s" />
    </div>
  </div>
</div>
