
<NBackdrop @outsideClick="closeOnOutside && (showSidebar = false)">
  <NSidebarTransition @afterLeave="onAfterLeave">
    <NSidebarContent
      v-if="showSidebar"
      v-bind="$props"
      @close="showSidebar = false"
      @openMenu="$emit('openMenu', $event)"
      @back="$emit('back')"
      class="sidebar-modal"
      :class="{ transition: !isDragging }"
      :size="size"
      ref="sidebar"
      @touchstart="onTouchStart($event)"
      @touchmove="onTouchMove($event)"
      @touchend="onTouchEnd"
    >
      <slot />
    </NSidebarContent>
  </NSidebarTransition>
</NBackdrop>
