import { EmbedType } from '@/modules/elements/models/Embed'

const LOOM_BASE_REGEX_STR =
  '(?:https?://)?((?:stage.loom.com|loom.com|www.loom.com|loomlocal.com:4444)/(share|embed)/[a-f0-9]+)'

const YOUTUBE_BASE_REGEX_STR =
  '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$'

const VIMEO_BASE_REGEX_STR =
  'https?://(?:www\\.|player\\.)?vimeo\\.com/(?:channels/\\w+/|groups/\\w+/videos/|video/)?(\\d+)(?:/(\\w+))?'

const GOOGLE_DOCS_BASE_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:docs.google.com/.*)'

const GOOGLE_FORMS_BASE_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:docs.google.com/forms/.*)'

const GOOGLE_DRIVE_BASE_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:drive.google.com/.*)'

const PITCH_BASE_REGEX_STR =
  '(?:https?://)?(app.)?(?:pitch.com)/(v|public|app/player|app/presentation|app/public/player)/(.*)'

const TYPEFORM_BASE_REGEX_STR = '(?:https?://)?([a-zA-Z0-9]+.typeform.com/to/[a-zA-Z0-9]+.*)'

const PRODUCT_BOARD_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:portal.productboard.com/.*)'

const THREEDVISTA_REGEX_STR = 'https://storage.net-fs.com/hosting/\\d+/\\d+(?:/.*)?(?:#.+)?'

const RECLAIM_REGEX_STR = 'https://app\\.reclaim\\.ai/e/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}'

const UCARE_REGEX_STR = 'https://ucarecdn\\.com/[a-fA-F0-9\\-]+/[^/]+\\.(mp4|avi|mkv|webm)'

const CALENDLY_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:calendly.com/.*)'

const CANVA_BASE_REGEX_STR = '(?:http|https)?:?\\/?\\/?(?:www.)?(?:canva.com\\/design\\/[^\\/]+\\/[^\\/]+\\/view)'

const URL_REGEX_STR =
  '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'

const UUID_LENGTH = 36

export interface EmbedLinkMatch {
  original: string
  embed: string
  type: EmbedType
  genericUrl?: boolean
}

export function isGoogleFormsLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${GOOGLE_FORMS_BASE_REGEX_STR}`, 'g'))
}
export function isUrl(link: string): boolean {
  const matchesUrl = link.match(new RegExp(`${URL_REGEX_STR}`, 'g'))
  return matchesUrl?.length === 1
}

export function isLoomLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${LOOM_BASE_REGEX_STR}`, 'g'))
}
export function isYoutubeLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${YOUTUBE_BASE_REGEX_STR}`, 'g'))
}
export function isVimeoLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(VIMEO_BASE_REGEX_STR))
}
export function isGoogleDocsLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${GOOGLE_DOCS_BASE_REGEX_STR}`, 'g'))
}
export function isGoogleDriveLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${GOOGLE_DRIVE_BASE_REGEX_STR}`, 'g'))
}
export function isPitchLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${PITCH_BASE_REGEX_STR}`, 'g'))
}
export function isTypeformLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${TYPEFORM_BASE_REGEX_STR}`, 'g'))
}
export function isProductBoardLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${PRODUCT_BOARD_REGEX_STR}`, 'g'))
}

export function isThreeDVistaLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${THREEDVISTA_REGEX_STR}`, 'g'))
}

export function isReclaimLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${RECLAIM_REGEX_STR}`, 'g'))
}

export function isUcareLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${UCARE_REGEX_STR}`, 'g'))
}

export function isCalendlyLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(CALENDLY_REGEX_STR, 'g'))
}

export function isCanvaLink(link: string): RegExpMatchArray | null {
  return link.match(new RegExp(`${CANVA_BASE_REGEX_STR}`, 'g'))
}

export function findPitchLink(link: string): EmbedLinkMatch | null {
  const matchesPitch = isPitchLink(link)
  if (!matchesPitch?.length) {
    return null
  }

  let embedUrl = matchesPitch[0]
  const publicPath = 'public'
  const appPitchUrl = 'app.pitch.com'
  const pitchUrl = 'pitch.com'

  if (embedUrl.includes('app/public/player')) {
    embedUrl = embedUrl.replace(appPitchUrl, pitchUrl).replace('app/public/player', publicPath)
  } else if (!embedUrl.includes('public') && !embedUrl.includes('/v/')) {
    const publicEmbedUrl = embedUrl
      .replace(appPitchUrl, pitchUrl)
      .replace('app/player', publicPath)
      .replace('app/presentation', publicPath)

    const teamIdIndex = publicEmbedUrl.indexOf(publicPath) + publicPath.length
    const teamIdPath = publicEmbedUrl.slice(teamIdIndex, teamIdIndex + UUID_LENGTH + 1)

    embedUrl = publicEmbedUrl.replace(teamIdPath, '')
  }

  return {
    original: matchesPitch[0],
    embed: embedUrl,
    type: EmbedType.PITCH
  }
}

export function findLoomLink(link: string): EmbedLinkMatch | null {
  const matchesLoom = isLoomLink(link)

  if (matchesLoom?.length) {
    return { original: matchesLoom[0], embed: matchesLoom[0].replace('share', 'embed'), type: EmbedType.LOOM }
  } else {
    return null
  }
}

export function findTypeformLink(link: string): EmbedLinkMatch | null {
  const matchesTypeform = isTypeformLink(link)

  if (matchesTypeform?.length) {
    return {
      original: matchesTypeform[0],
      embed: matchesTypeform[0],
      type: EmbedType.TYPEFORM
    }
  } else {
    return null
  }
}

function findProductboardLink(link: string): EmbedLinkMatch | null {
  const matchesProductboard = isProductBoardLink(link)

  if (matchesProductboard?.length) {
    return {
      original: matchesProductboard[0],
      embed: matchesProductboard[0],
      type: EmbedType.GENERIC
    }
  } else {
    return null
  }
}

function findThreeDVistaLink(link: string): EmbedLinkMatch | null {
  const matchesThreeDVista = isThreeDVistaLink(link)

  if (matchesThreeDVista?.length) {
    return {
      original: matchesThreeDVista[0],
      embed: matchesThreeDVista[0],
      type: EmbedType.GENERIC
    }
  } else {
    return null
  }
}

function findReclaimLink(link: string): EmbedLinkMatch | null {
  const matchesReclaim = isReclaimLink(link)

  if (matchesReclaim?.length) {
    return {
      original: matchesReclaim[0],
      embed: matchesReclaim[0],
      type: EmbedType.RECLAIM
    }
  } else {
    return null
  }
}

function findUcareLink(link: string): EmbedLinkMatch | null {
  const matchesUcare = isUcareLink(link)

  if (matchesUcare?.length) {
    return {
      original: matchesUcare[0],
      embed: matchesUcare[0],
      type: EmbedType.VIDEO
    }
  } else {
    return null
  }
}

export function findYoutubeLink(link: string): EmbedLinkMatch | null {
  const matchesYoutube = isYoutubeLink(link)
  if (matchesYoutube?.length) {
    return {
      original: matchesYoutube[0],
      embed: matchesYoutube[0].replace('youtu.be', 'youtube.com/embed').replace('watch?v=', 'embed/'),
      type: EmbedType.YOUTUBE
    }
  }
  return null
}

export function findVimeoLink(link: string): EmbedLinkMatch | null {
  const matchesVimeo = isVimeoLink(link)
  if (matchesVimeo) {
    const videoId = matchesVimeo[1]
    let embedUrl = `https://player.vimeo.com/video/${videoId}`
    if (matchesVimeo[2]) {
      embedUrl += `?h=${matchesVimeo[2]}`
    }
    return {
      original: link,
      embed: embedUrl,
      type: EmbedType.VIDEO
    }
  } else {
    return null
  }
}

export function findCalendlyLink(link: string): EmbedLinkMatch | null {
  const matchesCalendly = isCalendlyLink(link)
  if (matchesCalendly?.length) {
    return {
      original: matchesCalendly[0],
      embed: matchesCalendly[0],
      type: EmbedType.CALENDLY
    }
  } else {
    return null
  }
}

export function findCanvaLink(link: string): EmbedLinkMatch | null {
  const matchesCanva = isCanvaLink(link)
  if (matchesCanva) {
    const baseEmbedUrl = matchesCanva[0]
    const embedUrl = `${baseEmbedUrl}?embed`
    return {
      original: link,
      embed: embedUrl,
      type: EmbedType.CANVA
    }
  }
  return null
}

export function findEmbedLink(link: string): EmbedLinkMatch | null {
  const matchesVideo = findVideoLink(link)
  if (matchesVideo) return prependHttps(matchesVideo)
  const matchesGDocs = findGDocsLink(link)
  if (matchesGDocs) return prependHttps(matchesGDocs)
  const matchesOther = findOtherLinks(link)
  if (matchesOther) return prependHttps(matchesOther)
  const matchesUrl = findUrlLink(link)
  if (matchesUrl) return prependHttps(matchesUrl)

  return null
}

function prependHttps(embedLink: EmbedLinkMatch): EmbedLinkMatch {
  if (!embedLink.embed.startsWith('http')) {
    embedLink.embed = `https://${embedLink.embed}`
  }
  return embedLink
}

export function findVideoLink(link: string): EmbedLinkMatch | null {
  const matchesLoom = findLoomLink(link)
  if (matchesLoom) return matchesLoom
  const matchesYoutube = findYoutubeLink(link)
  if (matchesYoutube) return matchesYoutube
  const matchesVimeo = findVimeoLink(link)
  if (matchesVimeo) return matchesVimeo
  const matchesUcare = findUcareLink(link)
  if (matchesUcare) return matchesUcare

  return null
}

export function findGDocsLink(link: string): EmbedLinkMatch | null {
  const matchesGoogleDocs = isGoogleDocsLink(link)
  const matchesGoogleDrive = isGoogleDriveLink(link)

  if (matchesGoogleDrive?.length) {
    return {
      original: link,
      embed: link.replace('/view', '/preview'),
      type: EmbedType.GDOCS
    }
  } else if (matchesGoogleDocs?.length) {
    return {
      original: matchesGoogleDocs[0],
      embed: matchesGoogleDocs[0].split('/edit')[0] + '/preview',
      type: EmbedType.GDOCS
    }
  } else {
    return null
  }
}

export function findOtherLinks(link: string): EmbedLinkMatch | null {
  const matchesPitch = findPitchLink(link)
  if (matchesPitch) return matchesPitch
  const matchesTypeform = findTypeformLink(link)
  if (matchesTypeform) return matchesTypeform
  const matchesProductboard = findProductboardLink(link)
  if (matchesProductboard) return matchesProductboard
  const matchesThreeDVista = findThreeDVistaLink(link)
  if (matchesThreeDVista) return matchesThreeDVista
  const matchesCalendly = findCalendlyLink(link)
  if (matchesCalendly) return matchesCalendly
  const matchesReclaim = findReclaimLink(link)
  if (matchesReclaim) return matchesReclaim
  const matchesCanva = findCanvaLink(link)
  if (matchesCanva) return matchesCanva

  return null
}

export function findUrlLink(link: string): EmbedLinkMatch | null {
  if (isUrl(link)) {
    return {
      original: link,
      embed: link,
      type: EmbedType.IFRAME,
      genericUrl: true
    }
  }
  return null
}
