
<div
  class="chip-wrapper"
  :class="[{ 'icon-right-padding': iconRight, clickable: clickable }, color]"
  v-on="$listeners"
>
  <UserAvatar v-if="user" size="xs" :user="user" class="user-avatar" />
  <n-icon v-if="icon" class="right-margin" :icon="icon" size="xs" />
  <span :class="{ cutoff: cutoffText, closable }">{{ name }}</span>
  <div v-if="closable" class="close-button" @click="$emit('close')">
    <n-icon icon="x" size="s" />
  </div>
  <n-icon v-if="iconRight" class="left-margin" :icon="iconRight" size="s" />
</div>
