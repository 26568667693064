
<div class="filterbar">
  <div class="filters">
    <slot name="filters"></slot>
  </div>

  <div class="actions">
    <slot name="actions"></slot>
  </div>
</div>
