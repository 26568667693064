
<div>
  <div
    class="card"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    :class="{
      focused: hovered && !isLight && !disableBorders,
      light: isLight,
      active: active && !disableBorders,
      '--has-sublabel': !!subLabel
    }"
    v-on="$listeners"
  >
    <div class="leading-icon">
      <DragHandle
        v-if="isDraggable"
        class="drag-handle-selectable"
        ref="dragHandle"
        @click="$emit('dragHandleClick')"
        :hidden="hideDragHandle"
      />
      <n-icon @click="$emit('leadingIconClick')" v-else-if="leadingIcon" :icon="leadingIcon" size="s" />
    </div>
    <div class="text-section">
      <div class="text">
        <span class="label">
          {{ label }}
        </span>
        <span v-if="subLabel" class="sub-label">
          {{ subLabel }}
        </span>
      </div>

      <div class="trailing-icon">
        <n-icon :icon="trailingIcon" size="s" />
      </div>
    </div>
    <div class="action">
      <slot name="action"></slot>
    </div>
  </div>
</div>
