
<n-modal-body width="s">
  <div class="modal-content">
    <NTitleBar
      :class="{ 'has-subtitle': subtitle }"
      v-bind="{ closable, title, backButton, backButtonLabel, subtitle }"
      @back="$emit('back')"
      @close="$emit('close')"
    />
    <NResizable v-if="!hideContent" class="variable-content">
      <slot></slot>
    </NResizable>
    <n-modal-footer v-if="!hideFooter" v-bind="{ currentPage, numPages }">
      <template #right>
        <slot name="rightFooter"></slot>
      </template>
      <template #left>
        <slot name="leftFooter"></slot>
      </template>
    </n-modal-footer>
  </div>
</n-modal-body>
