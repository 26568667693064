import ApiClient from '@/util/ApiClient'
import { defineStore } from 'pinia'
import { uuid } from 'vue-uuid'
import DuplicateElementRequest from '../models/DuplicateElementRequest'
import DuplicateElementResponse from '../models/DuplicateElementResponse'
import Element, { ElementType } from '../models/Element'
import Resource from '../models/Resource'
import Table from '../models/Table'
import TableColumn from '../models/TableColumn'
import TableRow from '../models/TableRow'
import ElementReversibleAction from '../models/ElementReversibleAction'
import { useElementHistoryStore } from './ElementHistoryStore'
import DuplicateElementsRequest from '../models/DuplicateElementsRequest'
import ElementComponent from '@/modules/elements/components/ElementComponent.vue'
import { FocusPosition } from '@/util/reorder-util'
import { useSpaceStore } from '@/modules/space/stores/SpaceStore'
import {
  trackDuplicateElement,
  trackDuplicateElements,
  trackElementCreated,
  trackElementRemoved,
  trackElementsRemoved
} from '@/util/segment-tracking'
import InsertElementsRequest from '../models/InsertElementsRequest'

export type BeforeDeleteCallback = (() => Promise<void>) | undefined

export type ElementCallbacks = {
  beforeDelete: BeforeDeleteCallback[]
}

export type ElementState = {
  elements: Element[]
  deletedElements: string[]
  toggleElementStates: {
    [elementId: string]: boolean
  }
  elementCallbacks: {
    [elementId: string]: ElementCallbacks
  }
  startIndex: number
  selectedElementsLength: number
}

export const useElementStore = defineStore('element', {
  state: (): ElementState => ({
    elements: [],
    deletedElements: [],
    toggleElementStates: {},
    elementCallbacks: {},
    startIndex: -1,
    selectedElementsLength: -1
  }),
  getters: {
    elementById() {
      return (elementId: string): Element | undefined => this.elements.find(element => element.id === elementId)
    },
    isDeletedElement() {
      return (elementId: string): boolean => this.deletedElements.includes(elementId)
    },
    getElementCallbacks() {
      return (elementId: string): ElementCallbacks | undefined => this.elementCallbacks[elementId]
    }
  },
  actions: {
    // REVERSIBLE ACTIONS
    async createElement(
      element: Element,
      elementComponent?: ElementComponent,
      focus?: FocusPosition
    ): Promise<Element> {
      const reversibleAction = new ElementReversibleAction<Element, void>()
      reversibleAction.name = 'Create Element'
      element.id = uuid.v4()
      reversibleAction.action = async () => await this.createElement_Internal(element, elementComponent, focus)
      reversibleAction.reverseAction = async () => await this.deleteElement_Internal(reversibleAction.actionResult)
      return useElementHistoryStore().doAction(element.rootEntityId, reversibleAction)
    },
    async deleteElement(elementId: string): Promise<void> {
      const element = this.elementById(elementId)
      if (element) {
        trackElementRemoved(element.id, element.type, element.rootEntityId)
        const elementCallbacks = this.getElementCallbacks(elementId)
        if (elementCallbacks?.beforeDelete) {
          await Promise.all(elementCallbacks.beforeDelete)
        }
        const reversibleAction = new ElementReversibleAction<void, Element>()
        reversibleAction.name = 'Delete Element'
        reversibleAction.action = async () => await this.deleteElement_Internal(element)
        reversibleAction.reverseAction = async () => await this.createElement_Internal(element)
        return useElementHistoryStore().doAction(element.rootEntityId, reversibleAction)
      }
    },
    async deleteElements(elementIds: string[]): Promise<void> {
      const elementsToDelete = this.elements.filter(e => elementIds.includes(e.id))
      trackElementsRemoved(elementsToDelete)
      const beforeDeleteCallbacks = elementIds
        .flatMap(id => this.getElementCallbacks(id)?.beforeDelete)
        .filter(beforeDeleteCallback => !!beforeDeleteCallback)
      await Promise.all(beforeDeleteCallbacks)

      const reversibleAction = new ElementReversibleAction<void, void>()
      reversibleAction.name = 'Delete multiple Elements'
      reversibleAction.action = async () => await this.deleteElements_Internal(elementsToDelete)
      reversibleAction.reverseAction = async () => {
        const createCalls = elementsToDelete.map(e => this.createElement_Internal(e))
        await Promise.all(createCalls)
      }
      const rootEntityId = elementsToDelete[0].rootEntityId
      return useElementHistoryStore().doAction(rootEntityId, reversibleAction)
    },
    getUpdateElementReversibleAction(
      oldElement: Element,
      newElement: Element
    ): ElementReversibleAction<Element | null, Element | null> | null {
      if (this.isDeletedElement(oldElement.id)) {
        return null
      }
      const reversibleAction = new ElementReversibleAction<Element | null, Element | null>()
      reversibleAction.name = 'Update Element'
      reversibleAction.action = async () => await this.updateElement_Internal(newElement)
      reversibleAction.reverseAction = async () => await this.updateElement_Internal(oldElement)
      return reversibleAction
    },
    async updateElement(oldElement: Element, newElement: Element): Promise<Element | null> {
      const action = this.getUpdateElementReversibleAction(oldElement, newElement)
      if (!action) return null
      return useElementHistoryStore().doAction(oldElement.rootEntityId, action)
    },
    async replaceElement(oldElement: Element, newElement: Element): Promise<Element | null> {
      if (this.isDeletedElement(oldElement.id)) {
        return null
      }
      newElement.id = uuid.v4()
      const reversibleAction = new ElementReversibleAction<Element | null, Element | null>()
      reversibleAction.name = 'Replace Element'
      reversibleAction.action = async () => {
        const newElementForAction = reversibleAction.actionResult
          ? JSON.parse(JSON.stringify(reversibleAction.actionResult))
          : newElement
        return await this.replaceElement_Internal(oldElement, newElementForAction)
      }
      reversibleAction.reverseAction = async () => {
        const oldElementForAction = reversibleAction.reverseActionResult
          ? JSON.parse(JSON.stringify(reversibleAction.reverseActionResult))
          : oldElement
        return await this.replaceElement_Internal(newElement, oldElementForAction)
      }
      trackElementCreated(newElement.id, newElement.type, newElement.rootEntityId)
      return useElementHistoryStore().doAction(oldElement.rootEntityId, reversibleAction)
    },
    async duplicateElement(
      duplicateElement: DuplicateElementRequest,
      element: Element
    ): Promise<DuplicateElementResponse> {
      const reversibleAction = new ElementReversibleAction<DuplicateElementResponse, void>()
      reversibleAction.name = 'Duplicate Element'
      reversibleAction.action = async () => await this.duplicateElement_Internal(duplicateElement, element.id)
      reversibleAction.reverseAction = async () =>
        await this.deleteElement_Internal(reversibleAction.actionResult?.element)
      trackDuplicateElement(element.id, element.type, element.rootEntityId)
      return useElementHistoryStore().doAction(element.rootEntityId, reversibleAction)
    },
    async duplicateElements(request: DuplicateElementsRequest): Promise<DuplicateElementResponse[]> {
      const elementsToDuplicate = this.elements.filter(e => request.ids.includes(e.id))
      const reversibleAction = new ElementReversibleAction<DuplicateElementResponse[], void>()
      reversibleAction.action = async () => await this.duplicateElements_Internal(request)
      reversibleAction.name = 'Duplicate multiple Element'
      reversibleAction.reverseAction = async () =>
        await this.deleteElements_Internal(reversibleAction.actionResult?.map(r => r.element) || [])
      const rootEntityId = elementsToDuplicate[0].rootEntityId
      trackDuplicateElements(elementsToDuplicate)
      return useElementHistoryStore().doAction(rootEntityId, reversibleAction)
    },
    async insertElements(request: InsertElementsRequest): Promise<DuplicateElementResponse[]> {
      const reversibleAction = new ElementReversibleAction<DuplicateElementResponse[], void>()
      reversibleAction.action = async () => await this.insertElements_Internal(request)
      reversibleAction.name = 'Insert multiple Elements'
      reversibleAction.reverseAction = async () =>
        await this.deleteElements_Internal(reversibleAction.actionResult?.map(r => r.element) || [])
      return useElementHistoryStore().doAction(request.targetRootEntityId, reversibleAction)
    },
    async setElementPosition(element: Element, newPosition: number, parentId?: string): Promise<Element> {
      const reversibleAction = new ElementReversibleAction<Element, Element>()
      const currentPosition = element.position
      const currentParentId = element.parentId
      reversibleAction.name = 'Set Element position'
      reversibleAction.action = async () => await this.setElementPosition_Internal(element, newPosition, parentId)
      reversibleAction.reverseAction = async () =>
        await this.setElementPosition_Internal(element, currentPosition, currentParentId)
      return useElementHistoryStore().doAction(element.rootEntityId, reversibleAction)
    },
    async setElementPositions(elements: Element[], newPositions: number[], parentId?: string): Promise<void> {
      const reversibleAction = new ElementReversibleAction<void, void>()
      const currentPositions = elements.map(e => e.position)
      const currentParentId = elements[0].parentId
      reversibleAction.name = 'Set multiple Element positions'
      reversibleAction.action = async () => await this.setElementPositions_Internal(elements, newPositions, parentId)
      reversibleAction.reverseAction = async () =>
        await this.setElementPositions_Internal(elements, currentPositions, currentParentId)
      const rootEntityId = elements[0].rootEntityId
      return useElementHistoryStore().doAction(rootEntityId, reversibleAction)
    },
    // API CALLS
    async createElement_Internal(
      element: Element,
      elementComponent?: ElementComponent,
      focus?: FocusPosition
    ): Promise<Element> {
      element.id = element.id || uuid.v1()
      element.inCreation = true
      if (element.type === ElementType.tasklist) {
        const untypedElement = element as any
        untypedElement.open = true
      }
      this.setElement(element)
      if (elementComponent) {
        elementComponent.$nextTick(() => {
          elementComponent.$nextTick(() => {
            elementComponent.$emit('focusNext', focus)
          })
        })
      }
      const elementResponse = (await ApiClient.post<Element>(`/elements`, element)).data
      elementResponse.inCreation = false
      this.setElement(elementResponse)
      return elementResponse
    },
    async deleteElement_Internal(element: Element | undefined): Promise<void> {
      if (element) {
        this.deleteElementFromStore(element.id)
        await ApiClient.delete(`/elements/${element.id}`)
        if (element.type === ElementType.signature) {
          await useSpaceStore().onSignatureElementUpdated(element)
        }
      }
    },
    async deleteElements_Internal(elements: Element[]): Promise<void> {
      this.deleteElementsFromStore(elements.map(e => e.id))
      await ApiClient.post(
        `/elements/delete`,
        elements.map(e => e.id)
      )
      const signatureElement = elements.find(e => e.type === ElementType.signature)
      if (signatureElement) {
        await useSpaceStore().onSignatureElementUpdated(signatureElement)
      }
    },
    async updateElement_Internal(element: Element): Promise<Element | null> {
      if (this.isDeletedElement(element.id)) {
        return null
      }
      const updatedElement = (await ApiClient.put<Element>(`/elements/${element.id}`, element)).data
      this.setElement(updatedElement)
      return updatedElement
    },
    async replaceElement_Internal(oldElement: Element, newElement: Element): Promise<Element | null> {
      const updatedElement = (await ApiClient.post<Element>(`/elements/${oldElement.id}/replace`, newElement)).data
      this.deleteElementFromStore(oldElement.id)
      this.setElement(updatedElement)
      return updatedElement
    },
    async updateMessageElementTaskItem_Internal(
      message: Element,
      taskItemId: string,
      checked: boolean
    ): Promise<Element> {
      const messageElement = (
        await ApiClient.put<Element>(`/messageelements/${message.id}/taskitems/${taskItemId}`, { checked })
      ).data
      this.setElement(messageElement)
      return messageElement
    },
    async duplicateElement_Internal(
      duplicateElement: DuplicateElementRequest,
      elementId: string
    ): Promise<DuplicateElementResponse> {
      const responseData = (await ApiClient.post(`/elements/${elementId}/duplicate`, duplicateElement)).data
      this.saveDuplicationElementAndChildren(responseData)
      return responseData
    },
    async duplicateElements_Internal(request: DuplicateElementsRequest): Promise<DuplicateElementResponse[]> {
      const responses = (await ApiClient.post<DuplicateElementResponse[]>(`/elements/duplicate`, request)).data
      responses.forEach(response => this.saveDuplicationElementAndChildren(response))
      return responses
    },
    async insertElements_Internal(request: InsertElementsRequest): Promise<DuplicateElementResponse[]> {
      const responses = (await ApiClient.post<DuplicateElementResponse[]>(`/elements/insert`, request)).data
      responses.forEach(response => this.saveDuplicationElementAndChildren(response))
      return responses
    },
    async setElementPosition_Internal(element: Element, newPosition: number, parentId?: string): Promise<Element> {
      element.position = newPosition
      element.parentId = parentId
      this.setElement(element)
      const response = await ApiClient.put<Element>(`/elements/${element.id}/position`, {
        position: newPosition,
        parentId
      })

      const updatedElement = response.data
      this.setElement(updatedElement)
      return updatedElement
    },
    async setElementPositions_Internal(elements: Element[], newPositions: number[], parentId?: string): Promise<void> {
      const response = await ApiClient.put<Element[]>(`/elements/positions`, {
        ids: elements.map(e => e.id),
        positions: newPositions,
        parentId: parentId
      })

      const updatedElements = response.data
      updatedElements.forEach(e => {
        this.setElement(e)
      })
    },
    async fetchAllSpacePageElements(spaceId: string, spacePageId: string): Promise<Element[]> {
      const response = await ApiClient.get<Element[]>(`/spaces/${spaceId}/pages/${spacePageId}/elements`)
      const elements = response.data
      elements.forEach(e => {
        this.setElement(e)
      })
      return elements
    },
    async fetchAllTaskElements(taskId: string): Promise<Element[]> {
      const elements = (await ApiClient.get<Element[]>(`/tasks/${taskId}/elements`)).data
      elements.forEach(e => {
        this.setElement(e)
      })
      return elements
    },
    async fetchAllTaskTemplateElements(taskTemplateId: string): Promise<Element[]> {
      const elements = (await ApiClient.get<Element[]>(`/task-templates/${taskTemplateId}/elements`)).data
      elements.forEach(e => {
        this.setElement(e)
      })
      return elements
    },
    async fetchAllSpacePageTemplateElements(templateId: string): Promise<Element[]> {
      const elements = (await ApiClient.get<Element[]>(`/space-page-templates/${templateId}/elements`)).data
      elements.forEach(e => {
        this.setElement(e)
      })
      return elements
    },
    // Store actions
    registerBeforeDeleteCallback(elementId: string, callback: BeforeDeleteCallback) {
      const elementCallbacks = this.elementCallbacks[elementId] || {}
      const beforeDeleteCallbacks = elementCallbacks.beforeDelete || []
      elementCallbacks.beforeDelete = [...beforeDeleteCallbacks, callback]
      this.elementCallbacks = { ...this.elementCallbacks, ...{ [elementId]: elementCallbacks } }
    },
    unRegisterBeforeDeleteCallback(elementId: string, callback: BeforeDeleteCallback) {
      const elementCallbacks = this.elementCallbacks[elementId] || {}
      elementCallbacks.beforeDelete = elementCallbacks.beforeDelete.filter(c => c !== callback)
      this.elementCallbacks = { ...this.elementCallbacks, ...{ [elementId]: elementCallbacks } }
    },
    setElement(element: Element) {
      this.deletedElements = this.deletedElements.filter(e => e !== element.id)
      if (element.type === ElementType.table) {
        sortColumnsAndRows(element.table!)
      } else if (element.type === ElementType.resourcelist) {
        sortResources(element)
      }
      let oldElement = (this.elements as Element[]).find(p => p.id === element.id)
      if (!oldElement) {
        this.elements = [...this.elements, { ...element } as Element]
      } else {
        oldElement = { ...oldElement, ...element } as Element
        this.elements = [...(this.elements as Element[]).filter(p => p.id !== element.id), oldElement]
      }
    },
    saveDuplicationElementAndChildren(response: DuplicateElementResponse) {
      this.setElement(response.element)
      response.children.forEach(child => this.saveDuplicationElementAndChildren(child))
    },
    deleteElementFromStore(elementId: string) {
      this.deletedElements = [...this.deletedElements, elementId]
      this.elements = this.elements.filter(e => e.id !== elementId)
    },
    deleteElementsFromStore(elementIds: string[]) {
      elementIds.forEach(e => this.deleteElementFromStore(e))
    },
    setToggleElementState(elementId: string, state: boolean) {
      this.toggleElementStates = { ...this.toggleElementStates, [elementId]: state }
    },
    clearPageElements(pageId: string) {
      this.elements = (this.elements as Element[]).filter(e => e.rootEntityId !== pageId)
    },
    setStartIndex(startIndex: number) {
      this.startIndex = startIndex
    },
    setSelectedElementsLength(selectedElementsLength: number) {
      this.selectedElementsLength = selectedElementsLength
    }
  }
})

function sortColumnsAndRows(table: Table) {
  if (table) {
    table.columns = [...table.columns].sort(comparePositions)
    table.rows = [...table.rows].sort(comparePositions)
  }
}

function sortResources(element: Element) {
  if (element.resourceList?.resources) {
    element.resourceList!.resources = [...element.resourceList!.resources!!].sort(comparePositions)
  }
}

function comparePositions(a: TableColumn | TableRow | Resource, b: TableColumn | TableRow | Resource) {
  return a.position - b.position
}

export type ElementStore = ReturnType<typeof useElementStore>
