import { Tokens } from '@/modules/auth/stores/Tokens'
import jwt_decode from 'jwt-decode'
import { uuid } from 'vue-uuid'

export type StoredTokens = { [key in keyof Tokens]: string }

export const storageTokenKey = 'notch.jwt'
export const storageTokenPublicKey = 'notch.public.jwt'
export const storageAnonymousUserIdKey = 'notch.anonymousUserId'

export type TokenSets = {
  [key in string]: Tokens | null
}

export class TokenStorage {
  private _tokens: TokenSets = {}
  private usePublicSpaceToken = false

  constructor() {
    this.loadTokens()
  }

  get anonymousUserId(): string {
    let id = localStorage.getItem(storageAnonymousUserIdKey)
    if (!id) {
      id = uuid.v4()
      localStorage.setItem(storageAnonymousUserIdKey, id)
    }

    return id
  }

  get tokens() {
    const storageKey = this.usePublicSpaceToken ? storageTokenPublicKey : storageTokenKey
    this.loadTokensForStorageKey(storageKey)
    return this._tokens ? this._tokens[storageKey] : null
  }

  get isRefreshExpired() {
    return !this.tokens?.refreshTokenExpiry || new Date() > this.tokens.refreshTokenExpiry
  }

  get isTokenExpired() {
    return !this.tokens?.tokenExpiry || new Date() > this.tokens.tokenExpiry
  }

  storeTokenPair(token: string, refreshToken: string) {
    this.internalStoreTokenPair(token, refreshToken, storageTokenKey)
  }

  storeTokenPairPublic(token: string, refreshToken: string) {
    this.internalStoreTokenPair(token, refreshToken, storageTokenPublicKey)
  }

  storeTokenPairDependingOnSpaceTokenUsage(token: string, refreshToken: string) {
    const storageKey = this.usePublicSpaceToken ? storageTokenPublicKey : storageTokenKey
    this.internalStoreTokenPair(token, refreshToken, storageKey)
  }

  setUsePublicSpaceToken(value: boolean) {
    this.usePublicSpaceToken = value
  }

  deleteTokens() {
    const storageKey = this.usePublicSpaceToken ? storageTokenPublicKey : storageTokenKey
    this.setTokens(null, storageKey)
    localStorage.setItem(storageKey, JSON.stringify(this._tokens[storageKey]))
  }

  private setTokens(tokens: Tokens | null, storageKey: string) {
    this._tokens[storageKey] = tokens

    if (!tokens) {
      localStorage.removeItem(storageKey)
      return
    }

    const storeTokens: StoredTokens = {
      token: tokens.token,
      tokenExpiry: tokens.tokenExpiry.toISOString()
    }
    if (tokens.refreshToken && tokens.refreshTokenExpiry) {
      storeTokens.refreshToken = tokens.refreshToken
      storeTokens.refreshTokenExpiry = tokens.refreshTokenExpiry.toISOString()
    }
    localStorage.setItem(storageKey, JSON.stringify(storeTokens))
  }

  private internalStoreTokenPair(token: string, refreshToken: string, storageKey: string) {
    const decodedToken = jwt_decode(token) as { exp: number; sub: string; teamId: string; scope: 'ALL' }
    const tokenExpiry = new Date(decodedToken.exp * 1000)

    let refreshTokenExpiry = tokenExpiry
    if (refreshToken) {
      const decodedRefreshToken = jwt_decode(refreshToken) as { exp: number; sub: string; teamId: string; scope: 'ALL' }
      refreshTokenExpiry = new Date(decodedRefreshToken.exp * 1000)
    }

    this.setTokens(
      {
        token,
        tokenExpiry,
        refreshToken,
        refreshTokenExpiry
      },
      storageKey
    )
  }

  private loadTokens() {
    this.loadTokensForStorageKey(storageTokenKey)
    this.loadTokensForStorageKey(storageTokenPublicKey)
  }

  private loadTokensForStorageKey(storageKey: string) {
    const storedTokens: StoredTokens | null = JSON.parse(localStorage.getItem(storageKey) || 'null')

    if (!storedTokens) {
      this._tokens[storageKey] = null
      return
    }

    const tokenExpiry = new Date(storedTokens.tokenExpiry)
    const refreshTokenExpiry = storedTokens.refreshTokenExpiry ? new Date(storedTokens.refreshTokenExpiry) : undefined

    this._tokens[storageKey] = {
      token: storedTokens.token,
      tokenExpiry,
      refreshToken: storedTokens.refreshToken,
      refreshTokenExpiry
    }
  }
}
