
<div class="checkbox" :class="{ 'with-label': !!label, disabled: disabled }">
  <input
    ref="input"
    type="checkbox"
    @change="handleChange"
    :id="id"
    :disabled="disabled"
    @focus="isFocused = true"
    @blur="isFocused = false"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  />
  <div
    class="checkbox-radio"
    :class="{
      checked: value,
      focused: isFocused && !value,
      hovered: hovered && !value && !isFocused
    }"
    @click="inputRef.click()"
  >
    <n-icon class="icon" v-show="value" icon="check" size="xs" strokeWidth="thicker" />
  </div>
  <label :for="id" class="checkbox-label">{{ label }}</label>
</div>
