
<div>
  <label class="label" v-if="label">
    {{ label }}
    <span v-if="optional">({{ $t('general.optional') }})</span>
    <n-tooltip v-if="info" :tippyProps="{ content: info }" class="info-icon">
      <n-icon icon="information-circle" size="s" />
    </n-tooltip>
  </label>
  <div class="multi-input" :style="{ minHeight: minHeight ? minHeight + 'px' : 'auto' }" @click="focusEditor">
    <TextEditor
      ref="textEditor"
      :editor="inputEditor"
      :showPlaceholderOnFocusOnly="false"
      :placeholder="placeholder"
      :hideActions="hiddenActions"
      :useFloatingMenu="true"
      :focused="autofocus"
      class="text-editor"
      @input="onInput"
    />
  </div>
</div>
