import { useSpaceStore } from '@/modules/space/stores/SpaceStore'
import { PlanTier, SubscriptionPlan } from '@/modules/team/models/Subscription'
import { useTeamStore } from '@/modules/team/stores/TeamStore'
import { TeamRole, UserState } from '@/modules/user/models/User'
import { useUserStore } from '@/modules/user/stores/UserStore'
import { defineStore } from 'pinia'

const MAX_SPACES_FOR_FREE_PLAN = 5

export const useFeatureRestrictionStore = defineStore('featureRestriction', {
  state: (): {} => ({}),
  getters: {
    subscription() {
      return useTeamStore().currentTeam?.subscription
    },
    subscriptionPlan() {
      return useTeamStore().currentTeam?.subscription?.plan
    },
    appsumoPlanTier() {
      const appsumoLicense = useTeamStore().currentTeam?.appsumoLicense
      return appsumoLicense && !appsumoLicense.isDisabled ? appsumoLicense.currentPlanTier : null
    },
    spaces() {
      return useSpaceStore().activeSpaces
    },
    internalUsers() {
      return useUserStore().users.filter(user => user.role !== TeamRole.EXTERNAL && user.state !== UserState.DISABLED)
    },
    isPlan:
      () =>
      (...plans: SubscriptionPlan[]): boolean => {
        const plan = useTeamStore().currentTeam?.subscription?.plan
        return plan ? plans.includes(plan) : false
      },
    allowCreateSpace(): boolean {
      if (this.appsumoPlanTier || !this.subscription) {
        return true
      }

      if (this.isPlan(SubscriptionPlan.FREE)) {
        return this.spacesCount < MAX_SPACES_FOR_FREE_PLAN
      }

      return this.isPlan(SubscriptionPlan.START, SubscriptionPlan.GROWTH)
    },
    exceedSpaceLimit(): boolean {
      if (this.appsumoPlanTier || !this.subscription) {
        return false
      }

      if (this.isPlan(SubscriptionPlan.FREE)) {
        return this.spacesCount >= MAX_SPACES_FOR_FREE_PLAN
      }

      return false
    },
    maxSpacesForFreePlan(): number {
      return MAX_SPACES_FOR_FREE_PLAN
    },
    allowInviteUser(): boolean {
      switch (this.appsumoPlanTier) {
        case PlanTier.TIER1:
        case PlanTier.NOTCH_TIER1_V2:
          return this.internalUsers.length < 3
        case PlanTier.TIER2:
        case PlanTier.NOTCH_TIER2_V2:
          return this.internalUsers.length < 10
        case PlanTier.TIER3:
        case PlanTier.NOTCH_TIER3_V2:
          return this.internalUsers.length < 25
        case PlanTier.NOTCH_TIER4_V2:
          return true
      }

      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.FREE, SubscriptionPlan.START, SubscriptionPlan.GROWTH)
    },
    exceedUserLimit(): boolean {
      switch (this.appsumoPlanTier) {
        case PlanTier.NOTCH_TIER1_V2:
          return this.internalUsers.length >= 3
        case PlanTier.NOTCH_TIER2_V2:
          return this.internalUsers.length >= 10
        case PlanTier.NOTCH_TIER3_V2:
          return this.internalUsers.length >= 25
        case PlanTier.NOTCH_TIER4_V2:
          return false
        case PlanTier.TIER1:
          return this.internalUsers.length >= 3
        case PlanTier.TIER2:
          return this.internalUsers.length >= 10
        case PlanTier.TIER3:
          return this.internalUsers.length >= 25
      }

      if (!this.subscription) {
        return false
      }
      return false
    },
    allowContentLibrary(): boolean {
      if (this.appsumoPlanTier || !this.subscription) {
        return true
      }
      return !this.isPlan(SubscriptionPlan.FREE)
    },
    allowSlackIntegration(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.TIER1:
            return false
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return !this.isPlan(SubscriptionPlan.FREE)
    },

    allowSalesforceIntegration(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.TIER1:
            return false
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.GROWTH)
    },

    allowHubspotIntegration(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.TIER1:
            return false
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.GROWTH)
    },
    allowSignature(): boolean {
      return true
    },
    allowAnalytics(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
            return false
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER1:
          case PlanTier.TIER2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.START, SubscriptionPlan.GROWTH)
    },
    allowBranding(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.TIER1:
          case PlanTier.TIER2:
            return false
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.GROWTH)
    },
    allowChat(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.NOTCH_TIER2_V2:
            return false
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER1:
          case PlanTier.TIER2:
          case PlanTier.TIER3:
            return true
        }
      }

      if (!this.subscription) {
        return true
      }

      return !this.isPlan(SubscriptionPlan.FREE)
    },
    spacesCount(): number {
      return this.spaces.length
    },
    allowCustomDomains(): boolean {
      if (this.appsumoPlanTier) {
        switch (this.appsumoPlanTier) {
          case PlanTier.NOTCH_TIER1_V2:
          case PlanTier.NOTCH_TIER2_V2:
          case PlanTier.TIER1:
          case PlanTier.TIER2:
            return false
          case PlanTier.NOTCH_TIER3_V2:
          case PlanTier.NOTCH_TIER4_V2:
          case PlanTier.TIER3:
            return true
        }
      }
      if (!this.subscription) {
        return true
      }

      return this.isPlan(SubscriptionPlan.GROWTH)
    },

    allowIntegrations(): boolean {
      return this.allowSlackIntegration || this.allowSalesforceIntegration || this.allowHubspotIntegration
    }
  }
})

export type FeatureRestrictionStore = ReturnType<typeof useFeatureRestrictionStore>
