import Summary from '@/modules/elements/models/Summary'
import Comment from '@/modules/messages/models/Comment'
import { Reactions } from '@/modules/space/models/Reactions'
import SpacePage from '@/modules/space/models/SpacePage'
import SpacePageTemplate from '@/modules/spacepagetemplates/models/SpacePageTemplate'
import Task from '@/modules/task/models/Task'
import TaskTemplate from '@/modules/task/models/TaskTemplate'
import { Reorderable } from '@/util/reorder-util'
import { CTABox } from './CTABox'
import { ContactCard } from './ContactCard'
import { Embed } from './Embed'
import Faq from './Faq'
import { LinkBookmark } from './LinkBookmark'
import Message from './Message'
import Offer from './Offer'
import Proposition from './Proposition'
import ResourceList from './ResourceList'
import Table from './Table'
import TimelineEntry from './TimelineEntry'
import Welcome from './Welcome'
import TeamOverview from './TeamOverview'
import SignatureElement from './SignatureList'

export default class Element implements Reorderable {
  inCreation!: boolean
  id!: string
  name!: string
  tag!: string
  type!: ElementType
  resourceList?: ResourceList
  message?: Message = new Message()
  table?: Table = new Table()
  ctaBox?: CTABox
  contactCard?: ContactCard
  welcome: Welcome = new Welcome()
  teamOverview?: TeamOverview
  offer?: Offer
  summary?: Summary
  linkBookmark?: LinkBookmark
  embed?: Embed
  comments!: Comment[]
  createdBy!: string
  createdAt!: string
  seenBy!: SeenBy[]
  unseen!: boolean
  followedUp!: boolean
  reactions!: Reactions
  position!: number
  containerType?: ContainerType
  parentId?: string
  rootEntityId!: string
  rootEntityType!: RootEntityType
  timelineEntries?: TimelineEntry[]
  faqs?: Faq[]
  proposition?: Proposition
  signatureList?: SignatureElement
}

export class SeenBy {
  userId!: string
  ceatedAt!: string
}

export enum ElementType {
  table = 'table',
  message = 'message',
  resourcelist = 'resourcelist',
  divider = 'divider',
  ctabox = 'ctabox',
  container = 'container',
  contactcard = 'contactcard',
  clientresources = 'clientresources',
  embed = 'embed',
  linkbookmark = 'linkbookmark',
  tasklist = 'tasklist',
  clientlogos = 'clientlogos',
  welcome = 'welcome',
  teamoverview = 'teamoverview',
  summary = 'summary',
  timeline = 'timeline',
  offer = 'offer',
  faq = 'faq',
  proposition = 'proposition',
  offerheader = 'offerheader',
  signature = 'signature'
}

export enum TaskType {
  TASK = 'TASK',
  TASKTEMPLATE = 'TASKTEMPLATE'
}

export enum ContainerType {
  TOGGLE = 'TOGGLE',
  HIDDEN = 'HIDDEN'
}

export enum RootEntityType {
  SPACE_PAGE = 'SPACE_PAGE',
  SPACE_PAGE_TEMPLATE = 'SPACE_PAGE_TEMPLATE',
  TASK = 'TASK',
  TASK_TEMPLATE = 'TASK_TEMPLATE'
}

export type RootEntity = SpacePage | Task | SpacePageTemplate | TaskTemplate
