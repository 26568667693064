enum REGISTRATION_METHOD {
  GOOGLE = 'Google',
  EMAIL_PASSWORD = 'Email & Password'
}

enum SIGNUP_TYPE {
  NEW_USER = 'New User',
  TEAM_INVITE = 'Team Invite'
}

enum DEVICE_TYPE {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile'
}

enum DEPARTMENT {
  CUSTOMER_SUCCESS = 'customerSuccess',
  DESIGN = 'design',
  EDUCATOR = 'educator',
  ENGINEERING = 'engineering',
  FOUNDER_AND_STARTUP_LEAD = 'founderAndStartupLead',
  FREELANCE_AND_BUSINESS_OWNER = 'freelanceAndBusinessOwner',
  MARKETING_AND_BRAND = 'marketingAndBrand',
  OPERATIONS = 'operations',
  PERSONAL = 'personal',
  PRODUCT_MANAGEMENT = 'productManagement',
  SALES = 'sales',
  STUDENT = 'student',
  OTHER = 'other'
}

enum POSITION {
  DEPARTMENT_LEAD = 'departmentLead',
  EXECUTIVE = 'executive',
  SOLOPRENEUR_FREELANCER = 'solopreneurFreelancer',
  TEAM_MANAGER = 'teamManager',
  TEAM_MEMBER = 'teamMember'
}

enum COMPANY_SIZE {
  ONLY_ME = 'onlyMe',
  TWO_TO_TEN = '2-10',
  ELEVEN_TO_FIFTY = '11-50',
  FIFTY_ONE_TO_HUNDRED = '51-100',
  ONE_FIFTY_ONE_TO_THOUSAND = '151-1000',
  THOUSAND_ONE_TO_TEN_K = '1001-10k',
  TEN_K_PLUS = '10k+'
}

enum USE_CASE {
  BUILD_AND_SIGN_CONTRACTS = 'buildAndSignContracts',
  CREATE_AND_SHARE_PROPOSALS = 'createAndShareProposals',
  ORGANIZE_AND_MANAGE_CONTRACTS = 'organizeAndManageContracts',
  SHARE_FILES_AND_RESOURCES = 'shareFilesAndResources',
  CENTRALIZE_SALES_CONTENT = 'centralizeSalesContent',
  ONBOARD_NEW_CLIENTS = 'onboardNewClients',
  AUTOMATE_DOCUMENT_GENERATION = 'automateDocumentGeneration'
}

export default class RegisterTrackingData {
  user_id?: string
  email?: string
  first_name?: string
  last_name?: string
  registration_method?: REGISTRATION_METHOD
  device_on_signup?: DEVICE_TYPE
  signup_type?: SIGNUP_TYPE
  department?: string
  position?: string
  company_size?: string
  company_website?: string
  team_name?: string
  upgrade_button_clicked?: boolean
  use_case?: string[]

  constructor(data: Partial<RegisterTrackingData> = {}) {
    Object.assign(this, data)
  }
}

export { REGISTRATION_METHOD, SIGNUP_TYPE, DEVICE_TYPE, DEPARTMENT, POSITION, COMPANY_SIZE, USE_CASE }
