const data = {
  body: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3293_31155)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.52681 0.195262C7.78716 -0.0650874 8.20927 -0.0650874 8.46962 0.195262L10.9135 2.63913C11.1738 2.89948 11.1738 3.32159 10.9135 3.58194C10.6531 3.84229 10.231 3.84229 9.97068 3.58194L8.66488 2.27614V7.33182H13.72L12.4142 6.02602C12.1539 5.76567 12.1539 5.34356 12.4142 5.08321C12.6746 4.82286 13.0967 4.82286 13.3571 5.08321L15.8009 7.52708C16.0613 7.78743 16.0613 8.20954 15.8009 8.46989L13.3571 10.9138C13.0967 11.1741 12.6746 11.1741 12.4142 10.9138C12.1539 10.6534 12.1539 10.2313 12.4142 9.97096L13.72 8.66516L8.66484 8.66516V13.72L9.97064 12.4142C10.231 12.1539 10.6531 12.1539 10.9134 12.4142C11.1738 12.6746 11.1738 13.0967 10.9134 13.3571L8.46958 15.8009C8.20923 16.0613 7.78712 16.0613 7.52677 15.8009L5.0829 13.3571C4.82255 13.0967 4.82255 12.6746 5.0829 12.4142C5.34325 12.1539 5.76536 12.1539 6.02571 12.4142L7.33151 13.72L7.33151 8.66511L2.27614 8.66511L3.58194 9.97091C3.84229 10.2313 3.84229 10.6534 3.58194 10.9137C3.32159 11.1741 2.89948 11.1741 2.63913 10.9137L0.195263 8.46985C-0.0650876 8.2095 -0.0650875 7.78739 0.195263 7.52704L2.63913 5.08317C2.89948 4.82282 3.32159 4.82282 3.58194 5.08317C3.84229 5.34352 3.84229 5.76563 3.58194 6.02598L2.27614 7.33178L7.33155 7.33178V2.27614L6.02575 3.58194C5.7654 3.84229 5.34329 3.84229 5.08294 3.58194C4.82259 3.32159 4.82259 2.89948 5.08294 2.63913L7.52681 0.195262Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_3293_31155">
<rect width="16" height="16" fill="currentColor"/>
</clipPath>
</defs>
</svg>`,
  width: 16,
  height: 16
}
export default data
