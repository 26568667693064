export default class User {
  id!: string
  createdAt!: string
  createdBy!: string
  email!: string
  firstName!: string
  lastName!: string
  jobTitle!: string
  profilePictureUrl?: string
  profilePictureFileId?: string
  role!: TeamRole
  state!: UserState
  token?: string
  phone?: string
  calendarLink?: string
  linkedinUrl?: string
  isSetup!: boolean
  isInvited!: boolean
  isGlobalTemplateManger!: boolean
  timeZone!: string
  registrationData!: RegistrationData
}

export enum TeamRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  EXTERNAL = 'EXTERNAL',
  ANONYMOUS = 'ANONYMOUS'
}

export enum UserState {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  PENDING_INVITATION = 'PENDING_INVITATION',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export class RegistrationData {
  department?: string
  position?: string
  companySize?: string
  companyWebsite?: string
  useCases!: string[]
}
