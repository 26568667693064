export function isElementInViewport(el: Element, tolerance: number = 0) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top + tolerance >= 0 &&
    rect.left + tolerance >= 0 &&
    rect.bottom - tolerance <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right - tolerance <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function isMobile() {
  return window.matchMedia('only screen and (max-width: 479px)').matches
}

export function isSafari() {
  return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') == -1
}

export function writeTextToClipboard(getText: Promise<string>) {
  if (isSafari()) {
    const clipboardItem = new ClipboardItem({ 'text/plain': getText })
    return navigator.clipboard.write([clipboardItem])
  } else {
    return getText.then(text => navigator.clipboard.writeText(text))
  }
}

export const scrollIfNecessary = (parentElement: HTMLElement, childElement: HTMLElement, down: boolean) => {
  const { scrollTop: parentScrollTop, clientHeight: parentHeight } = parentElement
  const { offsetTop: childTop, clientHeight: childHeight } = childElement

  if (down) {
    const lowerEntryBound = childTop + childHeight
    const lowerListBound = parentScrollTop + parentHeight
    parentElement.scrollBy({ top: Math.max(0, lowerEntryBound - lowerListBound) })
  } else if (childTop < parentScrollTop) {
    parentElement.scrollBy({ top: childTop - parentScrollTop })
  }
}
