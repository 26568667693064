
<portal to="modal">
  <div
    ref="background"
    class="background"
    v-if="!isInPrintMode"
    :class="{
      'center-vertically': centerVertically,
      'cover-snackbars': coverSnackbars,
      'fade-away': fadeAway,
      'fade-in': fadeIn
    }"
    @click.self="$emit('outsideClick')"
  >
    <slot></slot>
  </div>
</portal>
