
<n-menu ref="menu">
  <n-menu-group divider>
    <n-input
      v-model="linkValue"
      @keypress.native.enter="onEnter"
      ref="input"
      size="s"
      variableWidth
      :error="linkValidationFailed ? $t('linkMenu.invalid') : undefined"
    />
  </n-menu-group>
  <n-menu-group>
    <n-list-item leadingIcon="link" :text="$t('linkMenu.link')" @click="tryUpdateLink(linkValue)" />
    <n-list-item leadingIcon="document-duplicate" :text="$t('linkMenu.copy')" @click="copyLink()" />
    <n-list-item leadingIcon="trash" :text="$t('linkMenu.remove')" @click="removeLink()" />
  </n-menu-group>
</n-menu>
