
<n-modal-background @close="$emit('close')" ref="bg">
  <n-modal-body>
    <main>
      <img class="side-image" :src="featureToUpgradeImage" />
      <div class="content">
        <div class="messages">
          <header>{{ $t(`featureRestriction.upgrades.${featureUpgrade}.title`) }}</header>
          <p class="subtitle">
            {{ $t(`featureRestriction.upgrades.${featureUpgrade}.subtitle`) }}
          </p>
        </div>
        <footer>
          <n-button @click="$refs.bg.close()" :label="$t('general.close')" variant="tertiary" />
          <n-button v-if="isAdmin" @click="openSalesLink()" :label="$t('general.talkToNotch')" variant="secondary" />
          <n-button v-if="isAdmin" @click="showUpgradeOptionsModal = true" :label="$t('general.upgrade')" />
        </footer>
      </div>
    </main>
    <UpgradeOptionsModal v-if="showUpgradeOptionsModal" @close="showUpgradeOptionsModal = false" />
  </n-modal-body>
</n-modal-background>
