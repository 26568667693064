
<section
  :class="[
    {
      'with-icon-and-button': showButton && leadingIcon,
      'with-icon-and-button': showButton && !leadingIcon,
      'with-icon-and-no-button': !showButton && leadingIcon
    },
    variant
  ]"
>
  <div class="body">
    <div v-if="showLoadingIndicator" class="loading-indicator">
      <n-loading size="s" dark active />
    </div>
    <n-icon class="icon" :icon="icon" v-if="leadingIcon && icon" size="s" />
    <UserAvatar v-if="leadingAvatar" size="s" class="avatar" :user="user" />
    <span class="label">
      <div>{{ label }}</div>
      <div v-if="description" class="description">{{ description }}</div>
    </span>
  </div>

  <div class="actions" v-if="showActions">
    <div v-if="showButton" class="button-container">
      <n-button :label="buttonLabel" :disabled="isButtonDisabled" size="s" @click="onActionButtonClick" />
    </div>
    <n-icon v-if="!autoHide" class="close-button" icon="x" size="l" @click="onhide" />
  </div>
  <ContactBanner v-if="isContactBanner" :snackbarId="id" />
</section>
