import { Extension, InputRuleFinder, textInputRule } from '@tiptap/core'

function replace(find: InputRuleFinder, replace: string) {
  return textInputRule({ find, replace })
}

export const SmilieReplacer = Extension.create({
  name: 'smilieReplacer',

  addInputRules() {
    return [
      replace(/-___- $/, '😑 '),
      replace(/:'-\) $/, '😂 '),
      replace(/':-\) $/, '😅 '),
      replace(/':-D $/, '😅 '),
      replace(/>:-\) $/, '😆 '),
      replace(/-__- $/, '😑 '),
      replace(/':-\( $/, '😓 '),
      replace(/:'-\( $/, '😢 '),
      replace(/>:-\( $/, '😠 '),
      replace(/O:-\) $/, '😇 '),
      replace(/0:-3 $/, '😇 '),
      replace(/0:-\) $/, '😇 '),
      replace(/0;\^\) $/, '😇 '),
      replace(/O;-\) $/, '😇 '),
      replace(/0;-\) $/, '😇 '),
      replace(/O:-3 $/, '😇 '),
      replace(/:'\) $/, '😂 '),
      replace(/:-D $/, '😃 '),
      replace(/':\) $/, '😅 '),
      replace(/'=\) $/, '😅 '),
      replace(/':D $/, '😅 '),
      replace(/'=D $/, '😅 '),
      replace(/>:\) $/, '😆 '),
      replace(/>;\) $/, '😆 '),
      replace(/>=\) $/, '😆 '),
      replace(/;-\) $/, '😉 '),
      replace(/\*-\) $/, '😉 '),
      replace(/;-\] $/, '😉 '),
      replace(/;\^\) $/, '😉 '),
      replace(/B-\) $/, '😎 '),
      replace(/8-\) $/, '😎 '),
      replace(/B-D $/, '😎 '),
      replace(/8-D $/, '😎 '),
      replace(/:-\* $/, '😘 '),
      replace(/:\^\* $/, '😘 '),
      replace(/:-\) $/, '🙂 '),
      replace(/-_- $/, '😑 '),
      replace(/:-X $/, '😶 '),
      replace(/:-# $/, '😶 '),
      replace(/:-x $/, '😶 '),
      replace(/>.< $/, '😣 '),
      replace(/:-O $/, '😮 '),
      replace(/:-o $/, '😮 '),
      replace(/O_O $/, '😮 '),
      replace(/>:O $/, '😮 '),
      replace(/:-P $/, '😛 '),
      replace(/:-p $/, '😛 '),
      replace(/:-Þ $/, '😛 '),
      replace(/:-þ $/, '😛 '),
      replace(/:-b $/, '😛 '),
      replace(/>:P $/, '😜 '),
      replace(/X-P $/, '😜 '),
      replace(/x-p $/, '😜 '),
      replace(/':\( $/, '😓 '),
      replace(/'=\( $/, '😓 '),
      replace(/>:\\ $/, '😕 '),
      replace(/>:\/ $/, '😕 '),
      replace(/:-\/ $/, '😕 '),
      replace(/:-. $/, '😕 '),
      replace(/>:\[ $/, '😞 '),
      replace(/:-\( $/, '😞 '),
      replace(/:-\[ $/, '😞 '),
      replace(/:'\( $/, '😢 '),
      replace(/;-\( $/, '😢 '),
      replace(/#-\) $/, '😵 '),
      replace(/%-\) $/, '😵 '),
      replace(/X-\) $/, '😵 '),
      replace(/>:\( $/, '😠 '),
      replace(/0:3 $/, '😇 '),
      replace(/0:\) $/, '😇 '),
      replace(/O:\) $/, '😇 '),
      replace(/O=\) $/, '😇 '),
      replace(/O:3 $/, '😇 '),
      replace(/<\/3 $/, '💔 '),
      replace(/:D $/, '😃 '),
      replace(/=D $/, '😃 '),
      replace(/;\) $/, '😉 '),
      replace(/\*\) $/, '😉 '),
      replace(/;\] $/, '😉 '),
      replace(/;D $/, '😉 '),
      replace(/B\) $/, '😎 '),
      replace(/8\) $/, '😎 '),
      replace(/:\* $/, '😘 '),
      replace(/=\* $/, '😘 '),
      replace(/:\) $/, '🙂 '),
      replace(/=\] $/, '🙂 '),
      replace(/=\) $/, '🙂 '),
      replace(/:\] $/, '🙂 '),
      replace(/:X $/, '😶 '),
      replace(/:# $/, '😶 '),
      replace(/=X $/, '😶 '),
      replace(/=x $/, '😶 '),
      replace(/:x $/, '😶 '),
      replace(/=# $/, '😶 '),
      replace(/:O $/, '😮 '),
      replace(/:o $/, '😮 '),
      replace(/:P $/, '😛 '),
      replace(/=P $/, '😛 '),
      replace(/:p $/, '😛  '),
      replace(/=p $/, '😛 '),
      replace(/:Þ $/, '😛 '),
      replace(/:þ $/, '😛 '),
      replace(/:b $/, '😛 '),
      replace(/d: $/, '😛 '),
      replace(/:\/ $/, '😕 '),
      replace(/:\\ $/, '😕 '),
      replace(/=\/ $/, '😕 '),
      replace(/=\\ $/, '😕 '),
      replace(/:L $/, '😕 '),
      replace(/=L $/, '😕 '),
      replace(/:\( $/, '😞 '),
      replace(/:\[ $/, '😞 '),
      replace(/=\( $/, '😞 '),
      replace(/;\( $/, '😢 '),
      replace(/D: $/, '😨 '),
      replace(/:\$ $/, '😳 '),
      replace(/=\$ $/, '😳 '),
      replace(/#\) $/, '😵 '),
      replace(/%\) $/, '😵 '),
      replace(/X\) $/, '😵 '),
      replace(/:@ $/, '😠 '),
      replace(/<3 $/, '❤️ '),
      replace(/\/shrug $/, '¯\\_(ツ)_/¯')
    ]
  }
})
