
<n-menu ref="menu" class="overlay" :autoWidth="true">
  <n-menu-group>
    <n-list-item
      v-for="style in textStyle"
      :key="style.value"
      @click="onClick(style)"
      :text="$t(`textEditor.${style}`)"
    ></n-list-item>
  </n-menu-group>
</n-menu>
