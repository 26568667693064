
<div
  @focus="focus() && $emit('focus', $event)"
  @blur="blur() && $emit('blur', $event)"
  @click="$emit('click', $event)"
  @mouseover="$emit('mouseover', $event)"
  @mouseleave="$emit('mouseleave', $event)"
  :class="{
    'input-size-s': size === 's',
    'input-size-m': size === 'm' && !variableHeight,
    'input-min-size-m': size === 'm' && variableHeight,
    'variable-width': variableWidth
  }"
>
  <label class="label">
    {{ label }}
    <span v-if="optional">({{ $t('general.optional') }})</span>
    <n-tooltip v-if="info" :tippyProps="{ content: info }" class="info-icon">
      <n-icon icon="information-circle" size="s" />
    </n-tooltip>
  </label>
  <fieldset
    :class="{
      disabled,
      errored: Boolean(error),
      active: active,
      'variable-width': variableWidth,
      'non-interactive': nonInteractive,
      'reduce-padding': buttonRightLabel
    }"
    @click="onClick"
  >
    <div v-if="imageLeft" class="image-left">
      <img v-if="isImageLeftUrl" class="image-left-url" :src="imageLeft" />
      <div v-else class="image-left-placeholder">
        {{ imageLeft }}
      </div>
    </div>
    <n-icon v-else-if="iconLeft" class="icon-left" :icon="iconLeft" size="s" @click="onClickIconLeft" />
    <NResizable class="resizable-input" ref="resizable">
      <slot></slot>
      <input
        :value="value"
        :placeholder="placeholder"
        :type="type === 'password' && passwordReveal && doReveal ? 'text' : type"
        ref="input"
        :class="{ 'full-width': emptySlot }"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event.target.value)"
        @keydown="$emit('keydown', $event) && nonInteractive && $event.preventDefault()"
        @keyup="$emit('keyup', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
    </NResizable>
    <n-icon v-if="_iconRight" class="icon-right" :icon="_iconRight" size="s" @click="onClickiconRight" />
    <n-button
      v-if="buttonRightLabel"
      class="button-right"
      :label="buttonRightLabel"
      size="s"
      @click="onClickButton"
    />
  </fieldset>
  <div v-if="error" class="helper-text error">
    {{ error }}
  </div>
  <div v-else-if="helperText" class="helper-text">
    {{ helperText }}
  </div>
</div>
