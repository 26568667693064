import { NSnackbarProps } from '@/modules/global/components/core/NSnackbar/NSnackbar.vue'
import { defineStore } from 'pinia'
import { uuid } from 'vue-uuid'

type SnackbarState = { items: NSnackbarProps[] }

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackbarState => ({
    items: []
  }),
  getters: {
    snackbarsBySpaceId() {
      return (spaceId: string) => this.items.filter(s => (s.meta?.spaceId ? spaceId.includes(s.meta?.spaceId) : false))
    }
  },
  actions: {
    enqueue(props: NSnackbarProps) {
      const { autoHide, autoHideDuration, anchorOrigin } = props
      const id = props.id ?? uuid.v4()
      if (!anchorOrigin) {
        props.anchorOrigin = { vertical: 'bottom', horizontal: 'center' }
      }
      this.items.push({ id, ...props })
      if (autoHide || autoHide === undefined || autoHideDuration !== undefined) {
        setTimeout(() => {
          this.items = this.items.filter(i => i.id !== id)
        }, autoHideDuration || 5000)
      }
      return id
    },
    dequeue(id: string) {
      this.items = this.items.filter(i => i.id !== id)
    },
    removeSpaceSnackbars(spaceId: string) {
      const snackbarBySpaceId = this.snackbarsBySpaceId(spaceId)
      this.items = this.items.filter(i => !snackbarBySpaceId.map(s => s.id).includes(i.id))
    }
  }
})

export type SnackbarStore = ReturnType<typeof useSnackbarStore>
