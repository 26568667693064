
<div class="autocomplete-container">
  <n-input
    class="autocomplete-input"
    v-model="inputValue"
    v-bind="inputProps"
    :autofocus="autofocus"
    :imageLeft="imageLeft"
    :iconLeft="iconLeft"
    @focus.self="onFocus"
    @input="$emit('input', $event)"
    @blur.self="onBlur"
    @keydown.down.prevent="onArrowDown"
    @keydown.up.prevent="onArrowUp"
    @keydown.enter="onSelect(activeElement)"
    @keydown.esc="onEsc($event.target)"
    @clickIconLeft="$emit('clickIconLeft')"
    ref="input"
  />
  <n-menu ref="dropdown">
    <n-list-item
      v-for="(option, index) in options"
      :key="index"
      :active="isActiveElement(index)"
      @mousedown="onSelect(index)"
      @mouseenter="activeElement = index"
    >
      <slot :option="option">{{ toLabel(option) }}</slot>
    </n-list-item>
  </n-menu>
</div>
