export class DnsRecordInfo {
  recordType!: string
  key!: string
  value!: string
}

export enum DomainValidationStatus {
  NO_CERTIFICATE = 'NO_CERTIFICATE',
  CERTIFICATE_CREATING = 'CERTIFICATE_CREATING',
  CERTIFICATE_PENDING = 'CERTIFICATE_PENDING',
  NO_DISTRIBUTION = 'NO_DISTRIBUTION',
  DISTRIBUTION_ACTIVE = 'DISTRIBUTION_ACTIVE',
  DOMAIN_ACTIVE = 'DOMAIN_ACTIVE',
  CERTIFICATE_DELETED = 'CERTIFICATE_DELETED',
  DISTRIBUTION_DISABLED = 'DISTRIBUTION_DISABLED',
  DOMAIN_DELETED = 'DOMAIN_DELETED',
  FAILED = 'FAILED',
  CAA_ERROR = 'CAA_ERROR',
  CNAME_CONFLICT = 'CNAME_CONFLICT'
}

export class DomainResponse {
  status!: DomainValidationStatus
  host!: string
  dnsRecordInfo?: DnsRecordInfo
}

export class WhitelistedDomain {
  domainName!: string
  domainStatus!: DomainValidationStatus
}
