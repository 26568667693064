import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import deJson from '../../assets/locales/de.json'
import enJson from '../../assets/locales/en.json'
import frJson from '../../assets/locales/fr.json'
import esJson from '../../assets/locales/es.json'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  return {
    de_formal: deJson,
    en_default: enJson,
    fr_formal: frJson,
    es_formal: esJson
  }
}

export default new VueI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || 'en_default',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en_default',
  messages: loadLocaleMessages()
})
