
<div v-if="editor">
  <div class="text-editor-wrapper">
    <div class="actions">
      <!-- tabindex is needed to tell the browser that these elements are interactive. fills focus/blur events. -->
      <div
        v-if="showAction(Action.FILE)"
        @click="openFileDialog"
        class="is-clickable black-hover icon-size"
        tabindex="0"
      >
        <n-icon size="s" icon="document-add" />
      </div>
      <div class="divider" />
      <div
        v-if="showAction(Action.BOLD)"
        @click="editor.chain().focus().toggleBold().run()"
        class="right-spacing black-hover button-size"
        tabindex="1"
      >
        <b :class="{ active: editor.isActive('bold') }">B</b>
      </div>
      <div
        v-if="showAction(Action.ITALIC)"
        @click="editor.chain().focus().toggleItalic().run()"
        class="black-hover button-size"
        tabindex="2"
      >
        <i :class="{ active: editor.isActive('italic') }">i</i>
      </div>
      <div
        v-if="showAction(Action.UNDERLINED)"
        @click="editor.chain().focus().toggleUnderline().run()"
        class="right-spacing black-hover button-size"
        tabindex="3"
      >
        <u :class="{ active: editor.isActive('underline') }">U</u>
      </div>

      <div
        v-if="showAction(Action.STRIKE)"
        @click="editor.chain().focus().toggleStrike().run()"
        class="right-spacing black-hover button-size"
        tabindex="4"
      >
        <s :class="{ active: editor.isActive('strike') }">S</s>
      </div>

      <div
        v-if="showAction(Action.LINK)"
        @click="openLinkPopup()"
        class="black-hover icon-size"
        ref="linkbutton"
        tabindex="5"
      >
        <n-icon icon="at-symbol" size="s" :class="{ active: editor.isActive('link') }" />
      </div>
    </div>
    <div
      v-if="showSend"
      class="send-button"
      :class="{ active: canSend, 'is-clickable': canSend, 'black-hover': canSend }"
      @click="canSend && $emit('send')"
      tabindex="6"
    >
      <n-icon icon="paper-airplane" rotate="1" />
    </div>
  </div>
  <LinkInput :link="linkValue" @updateLink="updateLink" @removeLink="removeLink" ref="linkInput" />
</div>
