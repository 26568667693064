const data = {
  body: `<svg width="300" height="306" viewBox="0 0 300 306" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3666_7315)">
<path d="M205.438 197.953C195.73 206.55 183.684 217.251 161.695 217.251H148.537C132.631 217.251 118.187 211.462 107.836 200.994C97.7187 190.76 92.1637 176.784 92.1637 161.579V143.626C92.1637 128.421 97.7187 114.444 107.836 104.211C118.187 93.7427 132.631 87.9532 148.537 87.9532H161.637C183.625 87.9532 195.673 98.655 205.38 107.251C215.439 116.14 224.151 123.86 247.251 123.86C250.877 123.86 254.385 123.567 257.777 123.041C257.777 122.982 257.719 122.924 257.719 122.866C256.316 119.474 254.736 116.082 252.865 112.866L237.368 86.0819C223.157 61.462 196.901 46.3158 168.538 46.3158H137.602C109.181 46.3158 82.924 61.462 68.7719 86.0819L53.2746 112.866C39.064 137.485 39.064 167.778 53.2746 192.398L68.7719 219.181C82.9825 243.801 109.239 258.947 137.602 258.947H168.538C196.959 258.947 223.216 243.801 237.368 219.181L252.865 192.398C254.736 189.181 256.374 185.789 257.719 182.398C257.719 182.339 257.777 182.281 257.777 182.222C254.327 181.696 250.818 181.404 247.251 181.404C224.151 181.345 215.496 189.006 205.438 197.953Z" fill="#231F20"/>
<path d="M161.696 104.269H148.538C124.386 104.269 108.48 121.521 108.48 143.626V161.579C108.48 183.684 124.386 200.936 148.538 200.936H161.637C196.841 200.936 194.093 165.029 247.309 165.029C252.397 165.029 257.427 165.497 262.339 166.433C263.977 157.31 263.977 147.953 262.339 138.83C257.427 139.766 252.397 140.234 247.309 140.234C194.151 140.175 196.901 104.269 161.696 104.269Z" fill="#231F20"/>
<path d="M292.923 179.591C283.859 172.865 273.392 168.538 262.339 166.433C262.339 166.55 262.339 166.608 262.281 166.725C261.345 171.988 259.882 177.193 257.836 182.222C267.134 183.743 275.731 187.251 283.099 192.632C283.099 192.69 283.041 192.807 283.041 192.865C278.83 206.608 272.398 219.591 264.094 231.404C255.848 243.041 245.847 253.333 234.444 261.871C210.701 279.649 182.456 289.064 152.69 289.064C134.269 289.064 116.432 285.439 99.5901 278.363C83.3327 271.52 68.7719 261.637 56.2571 249.123C43.7425 236.608 33.918 221.988 27.0174 205.789C19.8829 189.006 16.3154 171.111 16.3154 152.69C16.3154 134.269 19.9414 116.433 27.0174 99.5906C33.8595 83.3333 43.7425 68.7719 56.2571 56.2573C68.7719 43.7426 83.3912 33.9181 99.5901 27.0175C116.374 19.883 134.269 16.3158 152.69 16.3158C182.456 16.3158 210.701 25.731 234.444 43.5088C245.847 52.1053 255.848 62.3392 264.094 73.9766C272.456 85.7895 278.83 98.7134 283.041 112.515C283.041 112.573 283.099 112.69 283.099 112.749C275.731 118.129 267.134 121.637 257.836 123.158C259.824 128.187 261.345 133.392 262.281 138.655C262.281 138.713 262.281 138.83 262.339 138.889C273.392 136.842 283.801 132.456 292.923 125.731C301.637 119.298 299.942 111.988 298.596 107.661C279.415 45.3216 221.345 0 152.69 0C68.3626 0 0.0582581 68.3626 0.0582581 152.632C0.0582581 236.901 68.4211 305.263 152.69 305.263C221.345 305.263 279.415 259.941 298.596 197.602C299.942 193.275 301.637 186.023 292.923 179.591Z" fill="#231F20"/>
</g>
<defs>
<clipPath id="clip0_3666_7315">
<rect width="300" height="305.263" fill="white"/>
</clipPath>
</defs>
</svg>`,
  width: 300,
  height: 306
}
export default data
