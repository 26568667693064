import { defineStore } from 'pinia'
import ElementReversibleAction from '../models/ElementReversibleAction'
import { uuid } from 'vue-uuid'
import { usePermissionStore } from '@/modules/auth/stores/PermissionStore'

const MAX_HISTORY_STEPS = 200

enum ActionType {
  ACTION,
  UNDO,
  REDO
}

type ElementHistory = {
  undoStack: ElementReversibleAction<any, any>[]
  redoStack: ElementReversibleAction<any, any>[]
  lastAction: ActionType | null
}

type EditorHisotry = {
  state: any
  undo: Function
  redo: Function
}

export type ElementHistoryState = {
  historyPerEntity: {
    [entityId: string]: ElementHistory
  }
  oldActiveEntityIds: string[]
  activeEntityId: string
  actionQueue: string[]
  editorHistories: {
    [editorId: string]: EditorHisotry
  }
}

export const useElementHistoryStore = defineStore('elementHistory', {
  state: (): ElementHistoryState => ({
    historyPerEntity: {},
    oldActiveEntityIds: [],
    activeEntityId: '',
    actionQueue: [],
    editorHistories: {}
  }),
  getters: {
    history() {
      return (entityId: string): ElementHistory => {
        let history = this.historyPerEntity[entityId]
        if (!history) {
          history = {
            undoStack: [],
            redoStack: [],
            lastAction: null
          }
        }
        return history
      }
    },
    activeAction(): boolean {
      return this.actionQueue.length > 0
    }
  },
  actions: {
    keyDownListener(event: KeyboardEvent) {
      if (this.activeEntityId) {
        let undoOrRedoFunction: ((id: string) => void) | undefined = undefined

        if (event.ctrlKey || event.metaKey) {
          if (event.key === 'z') {
            undoOrRedoFunction = event.shiftKey ? this.redo : this.undo
          } else if (event.key === 'y') {
            undoOrRedoFunction = this.redo
          }
        }

        if (undoOrRedoFunction) {
          event.preventDefault()
          undoOrRedoFunction(this.activeEntityId)
        }
      }
    },
    startListen() {
      document.removeEventListener('keydown', this.keyDownListener)
      document.addEventListener('keydown', this.keyDownListener)
    },
    restoreActiveEntity() {
      this.activeEntityId = this.oldActiveEntityIds.pop() || ''
    },
    resetActiveEntity() {
      this.activeEntityId = ''
    },
    stashActiveEntity() {
      this.oldActiveEntityIds.push(this.activeEntityId)
      this.activeEntityId = ''
    },
    setActiveEntity(activeEntityId: string) {
      this.activeEntityId = activeEntityId
      this.resetHistoryFor(activeEntityId)
    },
    resetHistoryFor(entityId: string) {
      if (entityId in this.historyPerEntity) {
        delete this.historyPerEntity[entityId]
      }
    },
    async doAction<T>(entityId: string, reversibleAction: ElementReversibleAction<T, any>): Promise<T> {
      this.recordAction(entityId, reversibleAction)
      reversibleAction.actionResult = await this.waitForAction(reversibleAction.action)
      return reversibleAction.actionResult
    },
    recordAction<T>(entityId: string, reversibleAction: ElementReversibleAction<T, any>) {
      const history = this.history(entityId)

      history.undoStack.push(reversibleAction)
      history.lastAction = ActionType.ACTION

      if (history.undoStack.length > MAX_HISTORY_STEPS) {
        history.undoStack.shift()
      }
      history.redoStack = []

      this.historyPerEntity = { ...this.historyPerEntity, ...{ [entityId]: history } }
    },
    saveEditorState(editorId: string, state: any) {
      const oldEditorHistory = this.editorHistories[editorId] || {}

      oldEditorHistory.state = state

      this.editorHistories = { ...this.editorHistories, ...{ [editorId]: oldEditorHistory } }
    },
    saveEditorUndoRedoActions(editorId: string, undo: Function, redo: Function) {
      const oldEditorHistory = this.editorHistories[editorId] || {}

      oldEditorHistory.undo = undo
      oldEditorHistory.redo = redo

      this.editorHistories = { ...this.editorHistories, ...{ [editorId]: oldEditorHistory } }
    },
    getEditorState(editorId: string): any | undefined {
      const oldEditorHistory = this.editorHistories[editorId] || {}
      return oldEditorHistory.state
    },
    doUndoForEditor(editorId: string) {
      const oldEditorHistory = this.editorHistories[editorId] || {}

      if (oldEditorHistory.undo) {
        oldEditorHistory.undo()
      }
    },
    doRedoForEditor(editorId: string) {
      const oldEditorHistory = this.editorHistories[editorId] || {}

      if (oldEditorHistory.redo) {
        oldEditorHistory.redo()
      }
    },
    async undo(entityId: string) {
      const cannotUseHistory = usePermissionStore().isNotInternalOrPreviewMode()
      if (cannotUseHistory) return
      const history = this.history(entityId)
      if (history.undoStack.length > 0) {
        const reversibleAction = history.undoStack.pop()!
        history.redoStack.push(reversibleAction)
        history.lastAction = ActionType.UNDO
        this.historyPerEntity = { ...this.historyPerEntity, ...{ [entityId]: history } }
        reversibleAction.reverseActionResult = await this.waitForAction(reversibleAction.reverseAction)
      }
    },
    async redo(entityId: string) {
      const cannotUseHistory = usePermissionStore().isNotInternalOrPreviewMode()
      if (cannotUseHistory) return
      const history = this.history(entityId)
      if (history.redoStack.length > 0) {
        const reversibleAction = history.redoStack.pop()!
        history.undoStack.push(reversibleAction)
        history.lastAction = ActionType.REDO

        this.historyPerEntity = { ...this.historyPerEntity, ...{ [entityId]: history } }
        reversibleAction.actionResult = await this.waitForAction(reversibleAction.action)
      }
    },
    async waitForAction<T>(action: () => T): Promise<T> {
      const actionId = uuid.v4()
      this.actionQueue.push(actionId)
      while (this.actionQueue[0] !== actionId) {
        await this.waitFor(20)
      }
      try {
        return await action()
      } finally {
        this.actionQueue.shift()
      }
    },
    async waitFor(timeout: number): Promise<void> {
      return new Promise(resolve => setTimeout(resolve, timeout))
    }
  }
})

export type ElementHistoryStore = ReturnType<typeof useElementHistoryStore>
