
<div v-if="editor" class="overlay" ref="overlay">
  <div class="text-style-element" @click="onClickTextStyle">
    <div class="text-style-text">{{ $t(`textEditor.${activeTextStyle}`) }}</div>
    <n-icon class="info-icon" icon="chevron-down" size="xs" />
  </div>
  <div class="divider"></div>

  <TextStyleDropdown :textStyle="textStyle" @onClick="applyTextStyle" ref="menu" />

  <n-editor-button
    v-if="isBoldShown"
    :active="editor.isActive('bold')"
    @click="editor.chain().focus().toggleBold().run()"
    class="styling-element"
    high
  >
    <b>B</b>
  </n-editor-button>
  <n-editor-button
    v-if="isItalicShown"
    :active="editor.isActive('italic')"
    @click="editor.chain().focus().toggleItalic().run()"
    class="styling-element"
    high
  >
    <i>I</i>
  </n-editor-button>
  <n-editor-button
    v-if="isUnderlinedShown"
    :active="editor.isActive('underline')"
    @click="editor.chain().focus().toggleUnderline().run()"
    class="styling-element"
    high
  >
    <u>U</u>
  </n-editor-button>

  <n-editor-button
    v-if="isStrikeShown"
    :active="editor.isActive('strike')"
    @click="editor.chain().focus().toggleStrike().run()"
    class="styling-element"
    high
  >
    <s>S</s>
  </n-editor-button>

  <div class="divider"></div>
  <n-editor-button
    :active="isTextAlignLeftActive"
    @click="editor.chain().focus().setTextAlign('left').run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="align-left" />
  </n-editor-button>

  <n-editor-button
    :active="isTextAlignCenterActive"
    @click="editor.chain().focus().setTextAlign('center').run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="align-center" />
  </n-editor-button>

  <n-editor-button
    :active="isTextAlignRightActive"
    @click="editor.chain().focus().setTextAlign('right').run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="align-right" />
  </n-editor-button>
  <n-editor-button
    :active="isTextAlignJustifyActive"
    @click="editor.chain().focus().setTextAlign('justify').run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="align-justify" />
  </n-editor-button>

  <div v-if="isLinkShown" class="divider"></div>
  <n-editor-button
    v-if="isOrderedListShown"
    :active="editor.isActive('orderedList')"
    @click="editor.chain().focus().toggleOrderedList().run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="ordered-list" />
  </n-editor-button>
  <n-editor-button
    v-if="isBulletListShown"
    :active="editor.isActive('bulletList')"
    @click="editor.chain().focus().toggleBulletList().run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="bullet-list" />
  </n-editor-button>
  <n-editor-button
    v-if="isTaskListShown"
    :active="editor.isActive('taskList')"
    @click="editor.chain().focus().toggleTaskList().run()"
    class="styling-element"
    high
  >
    <n-icon size="s" icon="checklist" />
  </n-editor-button>
  <div v-if="isLinkShown" class="divider"></div>
  <n-editor-button
    v-if="isLinkShown"
    :active="editor.isActive('link')"
    @click="openLinkPopup()"
    class="styling-element"
    ref="linkbutton"
    high
  >
    <n-icon icon="link" size="s" />
  </n-editor-button>
  <n-editor-button
    v-if="isQuoteShown"
    :active="editor.isActive('blockquote')"
    @click="editor.chain().focus().toggleBlockquote().run()"
    class="styling-element"
    high
  >
    <n-icon icon="quote" size="s" />
  </n-editor-button>
  <LinkInput :link="linkValue" @updateLink="updateLink" @removeLink="removeLink" ref="linkInput" class="linkinput" />
</div>
