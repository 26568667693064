
<n-menu tabindex="0" ref="menu" @keydown="onKeyDown">
  <n-menu-group :subheader="$t('embedLinkMenu.displayAs')">
    <n-list-item
      v-for="(option, index) in options"
      :key="option.name"
      :leadingIcon="option.icon"
      :text="$t(`embedLinkMenu.${option.name}`)"
      :active="selected === index"
      @click="onSelect(option.name)"
      @mouseenter="selected = index"
    />
  </n-menu-group>
</n-menu>
