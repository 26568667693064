import i18n from '@/util/config/i18n'
import Recipient from '@/modules/space/models/Recipient'
import SpaceStatistics from './SpaceStatistics'
import SpaceSettings from './SpaceSettings'

export default class Space {
  id!: string
  name!: string
  members!: string[]
  recipients!: Recipient[]
  ownerId!: string
  createdAt!: string
  createdBy!: string
  integrationLink!: string
  verificationMode!: SpaceVerificationMode
  value!: number
  decisionMakerId!: string | null
  closeDate!: string | null
  championId!: string | null
  decisionCriteria!: boolean | null
  decisionProcess!: boolean | null
  identifyPain!: boolean | null
  spaceStatistics!: SpaceStatistics
  spaceSettings!: SpaceSettings
  spaceListId!: string
  isArchived!: boolean
  status!: SpaceStatus
}

export class UpdateSpaceRequest {
  spaceListId?: string
  name?: string
  value?: number
  ownerId?: string
  closeDate?: string | null
  spaceSettings?: SpaceSettings
  isArchived?: boolean
}

export enum SpaceVerificationMode {
  PUBLIC = 'PUBLIC',
  INVITED_ONLY = 'INVITED_ONLY'
}

export enum SpaceStatus {
  DRAFT = 'DRAFT',
  SHARED = 'SHARED',
  VIEWED = 'VIEWED',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  FULLY_SIGNED = 'FULLY_SIGNED'
}

export enum ChipColor {
  SUCCESS = 'success',
  INFO = 'info',
  ACCENT = 'accent',
  SAND = 'sand',
  GREY = 'grey'
}

export const spaceStatusEntries = () => {
  return [
    { id: SpaceStatus.DRAFT, name: i18n.t('spacelist.spaceStatus.DRAFT'), color: ChipColor.GREY },
    { id: SpaceStatus.SHARED, name: i18n.t('spacelist.spaceStatus.SHARED'), color: ChipColor.INFO },
    { id: SpaceStatus.VIEWED, name: i18n.t('spacelist.spaceStatus.VIEWED'), color: ChipColor.ACCENT },
    {
      id: SpaceStatus.PARTIALLY_SIGNED,
      name: i18n.t('spacelist.spaceStatus.PARTIALLY_SIGNED'),
      color: ChipColor.ACCENT
    },
    { id: SpaceStatus.FULLY_SIGNED, name: i18n.t('spacelist.spaceStatus.FULLY_SIGNED'), color: ChipColor.SUCCESS }
  ]
}

export const spaceStatusMap = () => {
  return Object.fromEntries(new Map(spaceStatusEntries().map(e => [e.id, e])))
}
