import TaskTemplate from '@/modules/task/models/TaskTemplate'
import ApiClient from '@/util/ApiClient'
import { defineStore } from 'pinia'

export type TaskTemplateState = {
  taskTemplates: TaskTemplate[]
}

export const useTaskTemplateStore = defineStore('taskTemplate', {
  state: (): TaskTemplateState => ({
    taskTemplates: []
  }),
  actions: {
    async loadTaskTemplates(elementId: string) {
      const taskTemplates = (await ApiClient.get<TaskTemplate[]>(`/tasktemplatelist/${elementId}/tasks`)).data
      taskTemplates.forEach(taskTemplate => {
        this.setTaskTemplateToState(taskTemplate)
      })
    },
    async duplicateTaskTemplate(taskTemplateId: string, newPosition: number) {
      const duplicatedTaskTemplate = (
        await ApiClient.post<TaskTemplate>(`/task-templates/${taskTemplateId}/duplicate`, newPosition)
      ).data
      this.setTaskTemplateToState(duplicatedTaskTemplate)
      return duplicatedTaskTemplate
    },
    async createTaskTemplate(taskTemplate: TaskTemplate) {
      const taskTemplateResponse = (await ApiClient.post<TaskTemplate>(`/task-templates`, taskTemplate)).data
      this.setTaskTemplateToState(taskTemplateResponse)
      return taskTemplateResponse
    },
    async updateTaskTemplateProperty(taskTemplate: TaskTemplate, property: string, value: any) {
      const data = {
        [property]: value
      }
      const taskTemplateResponse = (await ApiClient.put(`/task-templates/${taskTemplate.id}/${property}`, data)).data
      this.setTaskTemplateToState(taskTemplateResponse)
    },
    async updateTaskTemplate(taskTemplate: TaskTemplate): Promise<TaskTemplate> {
      const taskTemplateResponse = (await ApiClient.put(`/task-templates/${taskTemplate.id}`, taskTemplate)).data
      this.setTaskTemplateToState(taskTemplateResponse)
      return taskTemplateResponse
    },
    async deleteTaskTemplate(taskTemplateId: string) {
      await ApiClient.delete(`/task-templates/${taskTemplateId}`)
      this.taskTemplates = this.taskTemplates.filter(t => t.id !== taskTemplateId)
    },
    async setPositionInMilestone(
      taskTemplate: TaskTemplate,
      position: number,
      parentId?: string
    ): Promise<TaskTemplate> {
      taskTemplate.positionInMilestone = position
      if (parentId) {
        taskTemplate.taskListId = parentId
      }
      const taskTemplateResponse = (
        await ApiClient.post(`/task-templates/${taskTemplate.id}/position-in-milestone`, {
          position: position,
          parentId: parentId
        })
      ).data
      this.setTaskTemplateToState(taskTemplateResponse)
      return taskTemplateResponse
    },
    setTaskTemplateToState(taskTemplate: TaskTemplate) {
      let taskTemplates = this.taskTemplates
      let oldTaskTemplate = taskTemplates.find(c => c.id === taskTemplate.id)
      if (!oldTaskTemplate) {
        const taskTemplatesToSave = [...taskTemplates, taskTemplate].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        )
        this.taskTemplates = taskTemplatesToSave
      } else {
        taskTemplates = taskTemplates.filter(c => c.id !== taskTemplate.id)
        oldTaskTemplate = { ...oldTaskTemplate, ...taskTemplate }
        this.taskTemplates = [...taskTemplates, oldTaskTemplate]
      }
    }
  }
})

export type taskTemplateStore = ReturnType<typeof useTaskTemplateStore>
