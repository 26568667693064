import { datadogLogs } from '@datadog/browser-logs'
import { uuid } from 'vue-uuid'

interface ErrorContext {
  component?: string
  info?: string
  propsData?: Record<string, any>
  method?: string
  lifecycle?: string
  traceId?: string
}

const errorCache: Map<string, number> = new Map()

export const datadog = !import.meta.env.VITE_LOCAL ? datadogLogs : undefined

export function initDatadog() {
  if (datadog) {
    console.log('Initializing Datadog.')
    datadog.init({
      clientToken: import.meta.env.VITE_DATADOG_TOKEN,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100
    })
  }
}

function generateErrorKey(error: Error, context: ErrorContext): string {
  return `${error.message}-${context.component}-${context.info}`
}

export function logError(error: Error, context: ErrorContext = {}) {
  const traceId = localStorage.getItem('traceId')
  context.traceId = traceId || undefined

  const errorKey = generateErrorKey(error, context)
  const currentTime = Date.now()

  if (!errorCache.has(errorKey) || currentTime - (errorCache.get(errorKey) || 0) > 60000) {
    console.error(error, context)

    if (datadog) {
      datadog.logger.error('Error', {
        error: error.message,
        stack: error.stack,
        ...context
      })
    }

    errorCache.set(errorKey, currentTime)
  }
}

const traceId = localStorage.getItem('traceId') || uuid.v4()
localStorage.setItem('traceId', traceId)
