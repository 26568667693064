
<div class="radio-container">
  <span
    class="radio"
    aria-disabled="false"
    :class="{
      'with-label': !!label,
      disabled: disabled,
      checked: isChecked,
      focused: isFocused && !isChecked,
      hovered: hovered && !isChecked && !isFocused
    }"
  >
    <span class="radio-icon">
      <input
        :id="id"
        type="radio"
        :disabled="disabled"
        :value="value"
        v-model="model"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
      />
      <div class="dot">
        <n-icon v-show="isChecked" class="dot-icon" icon="dot" size="m" />
      </div>
    </span>
  </span>
  <label :for="id" class="label">
    {{ label }}
    <n-tooltip v-if="info" :tippyProps="{ content: info }" class="info-icon">
      <n-icon icon="information-circle" />
    </n-tooltip>
  </label>
</div>
