type AnalyticsMethod = (...args: any[]) => void

interface Analytics {
  [key: string]: any
  invoked?: boolean
  methods?: string[]
  factory?: (methodName: string) => AnalyticsMethod
  load?: (key: string, options?: any) => void
  _writeKey?: string
  SNIPPET_VERSION?: string
  _loadOptions?: any
}

export const segmentInit = function (segmentKey: string) {
  const analytics: Analytics = (window.analytics = window.analytics || [])

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.')
    } else {
      analytics.invoked = true
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ]

      analytics.factory = function (methodName: string) {
        return function (...args: any[]) {
          const t = Array.prototype.slice.call(args)
          t.unshift(methodName)
          analytics.push(t)
        }
      }

      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e]
        ;(analytics as any)[key] = analytics.factory(key)
      }

      analytics.load = function (key: string, options?: any) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
        const firstScript = document.getElementsByTagName('script')[0]
        firstScript.parentNode?.insertBefore(script, firstScript)
        analytics._loadOptions = options
      }

      analytics._writeKey = segmentKey
      analytics.SNIPPET_VERSION = '4.15.3'
      analytics.load(segmentKey)
    }
  }

  return analytics
}
