import NConfirm from '@/modules/global/components/core/NConfirmModal/NConfirm.vue'
import { defineStore } from 'pinia'
import { TranslateResult } from 'vue-i18n'
import { uuid } from 'vue-uuid'

export type ConfirmModalConfig = {
  id: string
  onConfirm?: () => void
  onCancel?: () => void
  variant: NConfirm['variant']
  title?: string | TranslateResult
  message?: string | TranslateResult
  confirmButtonText?: string
  confirmButtonIcon?: string
}

export type ConfirmModalState = {
  modals: ConfirmModalConfig[]
}
export type OpenDialogConfig = Omit<ConfirmModalConfig, 'id' | 'variant'>

export const useConfirmModalStore = defineStore('confirmModal', {
  state: (): ConfirmModalState => ({
    modals: []
  }),
  actions: {
    showConfirm(config: OpenDialogConfig) {
      const id = uuid.v4()
      this.modals = [...this.modals, { id, variant: 'confirm', ...config }]
    },
    showAlert(config: OpenDialogConfig) {
      const id = uuid.v4()
      this.modals = [...this.modals, { id, variant: 'alert', ...config }]
    },
    remove(id: string) {
      this.modals = this.modals.filter(m => m.id !== id)
    }
  }
})

export type ConfirmModalStore = ReturnType<typeof useConfirmModalStore>
