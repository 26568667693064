export enum Permission {
  // VIEW PERMISSIONs
  VIEW_NAV_BAR = 'VIEW_NAV_BAR',
  VIEW_GLOBAL_NAV_BAR = 'VIEW_GLOBAL_NAV_BAR',
  VIEW_SPACE_ACTIONS = 'VIEW_SPACE_ACTIONS',
  VIEW_DIRECTORY = 'VIEW_DIRECTORY',
  VIEW_SPACE_LIST = 'VIEW_SPACE_LIST',
  VIEW_UPDATES = 'VIEW_UPDATES',
  VIEW_TASKS = 'VIEW_TASKS',
  VIEW_CONTENT_LIBRARY = 'VIEW_CONTENT_LIBRARY',
  VIEW_SPACE_LIST_DIRECTORY_SETTINGS = 'VIEW_SPACE_LIST_DIRECTORY_SETTINGS',

  // TEAM PERMISSIONS
  MANAGE_SPACE_LIST = 'MANAGE_SPACE_LIST',
  UPDATE_TEAM_SETTINGS = 'UPDATE_TEAM_SETTINGS',
  INVITE_TEAM_MEMBERS = 'INVITE_TEAM_MEMBERS',
  UPDATE_USERS = 'UPDATE_USERS',
  MANAGE_TASK_TEMPLATES = 'MANAGE_TASK_TEMPLATES',
  MANAGE_SPACE_PAGE_TEMPLATES = 'MANAGE_SPACE_PAGE_TEMPLATES',
  MANAGE_GLOBAL_SPACE_PAGE_TEMPLATES = 'MANAGE_GLOBAL_SPACE_PAGE_TEMPLATES',
  MANAGE_ACTION_PLANS = 'MANAGE_ACTION_PLANS',
  MANAGE_TASK_SHARED_STATUS = 'MANAGE_TASK_SHARED_STATUS',
  MANAGE_SUBSCRIPTIONS = 'MANAGE_SUBSCRIPTIONS',

  // SPACE PERMISSIONS
  SEE_SPACE = 'SEE_SPACE',
  MANAGE_SPACE = 'MANAGE_SPACE',
  MANAGE_SPACE_SETTINGS = 'MANAGE_SPACE_SETTINGS',
  MANAGE_ELEMENTS = 'MANAGE_ELEMENTS',
  CREATE_COMMENT = 'CREATE_COMMENT',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  RESOLVE_COMMENT = 'RESOLVE_COMMENT',
  MANAGE_TASK = 'MANAGE_TASK',
  UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS',
  ADD_CLIENT_RESOURCE = 'ADD_CLIENT_RESOURCE',
  DELETE_CLIENT_RESOURCE = 'DELETE_CLIENT_RESOURCE'
}
