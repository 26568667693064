import { extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

export const initValidation = function () {
  extend('required', {
    ...required,
    message: 'This field is required'
  })

  extend('email', {
    ...email,
    message: 'This is not a valid email'
  })

  extend('isValidLinkedinUrl', {
    validate: url => {
      const regex = /https:\/\/www\.linkedin\.com\/in\/([\w-]+|%[0-9A-Fa-f]{2})+/
      return regex.test(url)
    },
    message: 'Enter a valid LinkedIn URL'
  })
  extend('emailplusimpersonation', {
    validate: (value: string | string[], { multiple }: Record<string, any>) => {
      if (typeof (value as any).split === 'function') {
        const parts = (value as string).split(':')
        if (parts.length === 2) {
          return email.validate(parts[0], multiple) && email.validate(parts[1], multiple)
        }
      }
      return email.validate(value, multiple)
    },
    params: email.params,
    message: 'This is not a valid email'
  })

  extend('phone', {
    validate: value => {
      return /^(\+)?[0-9\s]+$/.test(value)
    },
    message: 'This is not a valid phone number'
  })

  extend('url', {
    validate: value => {
      // eslint complains here about unnecessary chars, which are absolutely necessary.
      // eslint-disable-next-line
          return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(value)
    },
    message: 'This is not a valid URL'
  })

  extend('domain', {
    validate: value => {
      return /^(?:\*\.)?(?!-)[A-Za-z0-9-]{1,63}(?<!-)(?:\.[A-Za-z0-9-]{1,63})*\.[A-Za-z]{2,63}$/i.test(value)
    },
    message: 'This is not a valid domain'
  })
}
