import { BillingCycle, SubscriptionPlan } from '@/modules/team/models/Subscription'
import ApiClient from '@/util/ApiClient'
import { defineStore } from 'pinia'

export const useSubscriptionStore = defineStore('subscription', {
  state: (): {} => ({}),
  actions: {
    async getCheckoutLink(
      plan: SubscriptionPlan | null,
      billingCycle: BillingCycle | null,
      successRoute?: string,
      promotekitReferral?: string
    ): Promise<string> {
      const response = await ApiClient({
        method: 'post',
        url: `/subscriptions/plan-change-checkout-link`,
        params: {
          plan,
          billingCycle,
          successRoute,
          promotekitReferral
        }
      })
      return response.data
    }
  }
})

export type SubscriptionStore = ReturnType<typeof useSubscriptionStore>
