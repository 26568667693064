
<div>
  <n-input
    v-model="internalValue"
    @change="$emit('inputChange', internalValue)"
    v-bind="inputProps"
    @keydown.enter.prevent="addValue"
    @keydown="onKeydown"
    @blur="addValue"
  >
    <n-chip v-for="val in value" :key="val" :name="val" :closable="!disabled" @close="removeValue(val)" />
  </n-input>
</div>
