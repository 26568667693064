export class AutosaveManager {
  private timerId: number | null = null
  private readonly interval: number
  private readonly saveCallback: () => void

  constructor(saveCallback: () => void, interval = 500) {
    this.saveCallback = saveCallback
    this.interval = interval
  }

  startTimer(): void {
    this.stopTimer()
    this.timerId = window.setTimeout(() => {
      this.saveCallback()
    }, this.interval)
  }

  resetTimer(): void {
    this.startTimer()
  }

  stopTimer(): void {
    if (this.timerId !== null) {
      clearTimeout(this.timerId)
      this.timerId = null
    }
  }
}
