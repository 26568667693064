
<div class="container">
  <div class="pill-navigation">
    <PillNavigationButton
      :label="$t('planSettings.payMonthly')"
      :active="billingCycleTab === BillingCycle.MONTHLY"
      @click="billingCycleTab = BillingCycle.MONTHLY"
    />
    <PillNavigationButton
      :label="$t('planSettings.payYearly')"
      :active="billingCycleTab === BillingCycle.YEARLY"
      @click="billingCycleTab = BillingCycle.YEARLY"
    />
  </div>
  <div class="plans-container">
    <div class="plan-container">
      <PlanBlock
        :planTitle="$t('planSettings.plans.free.title')"
        :planPrice="$t(`planSettings.plans.free.price.${billingCycleTab}`)"
        :planDuration="$t(`planSettings.month`)"
        :planFeatures="$t('planSettings.plans.free.features')"
        :isActivePlan="isCurrentPlan(SubscriptionPlan.FREE)"
        :buttonLabel="buttonLabelForPlan(SubscriptionPlan.FREE)"
        :onClick="() => changePlanTo(SubscriptionPlan.FREE)"
      />
      <PlanBlock
        :planTitle="$t('planSettings.plans.start.title')"
        :planPrice="$t(`planSettings.plans.start.price.${billingCycleTab}`)"
        :planDuration="$t(`planSettings.month`)"
        :planFeatures="$t('planSettings.plans.start.features')"
        :isActivePlan="isCurrentPlanWithBillingCycle(SubscriptionPlan.START)"
        :buttonLabel="buttonLabelForPlan(SubscriptionPlan.START)"
        :onClick="() => changePlanTo(SubscriptionPlan.START)"
      />
      <PlanBlock
        :planTitle="$t('planSettings.plans.growth.title')"
        :planPrice="$t(`planSettings.plans.growth.price.${billingCycleTab}`)"
        :planDuration="$t(`planSettings.month`)"
        :planFeatures="$t('planSettings.plans.growth.features')"
        :isActivePlan="isCurrentPlanWithBillingCycle(SubscriptionPlan.GROWTH)"
        :buttonLabel="buttonLabelForPlan(SubscriptionPlan.GROWTH)"
        :onClick="() => changePlanTo(SubscriptionPlan.GROWTH)"
      />
    </div>
  </div>
</div>
